import React from 'react';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Divider, Drawer, IconButton, styled, Toolbar } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import innerMateLog from 'src/assets/images/inner_mate_logo.png';

import { ToggleDrawerReturnType } from '../../hooks/useToggleDrawer';
import { SIDEBAR_BACKGROUND_COLOR } from '../../utils/contants';
import { AddChatIconForMobile } from '../../utils/Icons';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginRight: -drawerWidth,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  }),
  /**
   * This is necessary to enable the selection of content. In the DOM, the stacking order is determined
   * by the order of appearance. Following this rule, elements appearing later in the markup will overlay
   * those that appear earlier. Since the Drawer comes after the Main content, this adjustment ensures
   * proper interaction with the underlying content.
   */
  position: 'relative',
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

interface DrawerForMobileProps {
  children: React.ReactNode;
  toggleDrawer: ToggleDrawerReturnType;
  isAiProcessing: boolean;
  onAddNewChatRoom: () => void;
}

const DrawerForMobile = ({
  children,
  toggleDrawer,
  isAiProcessing = false,
  onAddNewChatRoom,
}: DrawerForMobileProps) => {
  const { openDrawer, handleDrawerOpen, handleDrawerClose } = toggleDrawer;

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed" open={openDrawer}>
        <Toolbar>
          <Box sx={{ flexGrow: 1 }}>
            <img src={innerMateLog} alt="innermate_logo" />
          </Box>
          <IconButton
            key={Math.random()}
            color="inherit"
            aria-label="add new chat"
            edge="end"
            disabled={isAiProcessing}
            onClick={onAddNewChatRoom}
            sx={{ ...(openDrawer && { display: 'none' }) }}
          >
            <AddChatIconForMobile />
          </IconButton>
          <IconButton
            color="inherit"
            aria-label="add new chat"
            edge="end"
            onClick={handleDrawerOpen}
            sx={{ ...(openDrawer && { display: 'none' }) }}
          >
            <MoreVertIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Main open={openDrawer}>
        <DrawerHeader />
      </Main>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            backgroundColor: SIDEBAR_BACKGROUND_COLOR,
          },
        }}
        variant="persistent"
        anchor="right"
        open={openDrawer}
      >
        <DrawerHeader
          sx={{
            justifyContent: 'flex-end', // 아이콘 오른쪽 위치
          }}
        >
          <IconButton onClick={handleDrawerClose}>
            <ExitToAppIcon sx={{ color: 'white' }} />
          </IconButton>
        </DrawerHeader>
        <Divider />
        {children}
      </Drawer>
    </Box>
  );
};

export default DrawerForMobile;
