import { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogProps } from '@mui/material';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { Editor } from '@tiptap/react';
import { useSnackbar } from 'notistack';
import { reportApi } from 'src/api';
import { parseContent } from 'src/components/block-editor/useBlockEditor';
import DialogTitleWithCloseButton from 'src/components/dialog/DialogTitleWithCloseButton';
import { useStores } from 'src/models';

import ReportTemplateTable from '../report-template/sections/ReportTemplateTable';

/**
 * ## ApplyTemplateToEditorModal 설명
 * - 보고서 템플릿을 선택하여 에디터에 적용하는 모달
 */
export interface IApplyTemplateToEditorModalProps extends DialogProps {
  editor?: Editor | null;
  onClose?: () => void;
}

function ApplyTemplateToEditorModal({ open, onClose, editor }: IApplyTemplateToEditorModalProps) {
  const title = '보고서 템플릿 선택';
  const { enqueueSnackbar } = useSnackbar();
  const { alertStore } = useStores();
  // selection model
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);

  // 적용하기 버튼 클릭 시
  const handleClickApplyTemplate = () => {
    try {
      // 선택한 프로젝트가 없으면 리턴
      if (selectionModel.length <= 0) {
        enqueueSnackbar('선택한 템플릿이 없습니다.', { variant: 'error' });
        return;
      }

      const selectedTmplSid = selectionModel[0];

      // alert 확인 시 처리 로직 설정
      alertStore.setOnConfirm(() => applyTemplate(selectedTmplSid as number));
      // 기존 내용을 초기화하고 템플릿을 적용할지 alert
      alertStore.setProps({
        open: true,
        category: 'warning',
        title: '템플릿 적용',
        content: '템플릿을 적용하면 기존 내용은 초기화됩니다. 적용하시겠습니까?',
        hasCancelButton: true,
      });
    } catch (error) {
      console.error(error);
    }
  };

  // 에디터에 템플릿 적용
  const applyTemplate = async (tmplSid: number) => {
    try {
      // 템플릿 조회 후 content를 editor에 적용
      const result = await reportApi.getReportTemplate(tmplSid);
      if (result) {
        const content = parseContent(result.data.body);
        editor?.commands.setContent(content);
      }
      onClose && onClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogTitleWithCloseButton title={title} onClose={onClose} />
      {/* Select Table */}
      <DialogContent sx={{ height: 600 }}>
        <ReportTemplateTable
          useYn
          checkboxSelection
          disableMultipleRowSelection
          keepNonExistentRowsSelected
          rowSelectionModel={selectionModel}
          onRowSelectionModelChange={setSelectionModel}
        />
      </DialogContent>
      {/* 적용 Button */}
      <DialogActions>
        <Button variant="contained" onClick={handleClickApplyTemplate}>
          적용하기
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ApplyTemplateToEditorModal;
