import {
  ChatbotSystemSettingsApiResponse,
  RagParam,
  RagParamCode,
} from 'src/@types/apiResponseTypes';

import { chatbotStreamInstance } from '../axios';

const SUB_URL = '/v1/setting';
const COMMON_CODE_URL = '/v1/common';

export const getRagParam = async (param_id: number) => {
  try {
    const url = `${SUB_URL}/param`;
    const response = await chatbotStreamInstance.get<RagParam>(url, {
      params: {
        param_id,
      },
    });

    if (response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateRagParam = async (ragParam: RagParam) => {
  try {
    const url = `${SUB_URL}/param`;
    const response = await chatbotStreamInstance.put<ChatbotSystemSettingsApiResponse>(
      url,
      ragParam,
    );

    if (response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
};

export const getRagParamCode = async () => {
  try {
    const url = `${COMMON_CODE_URL}/200`;
    const response = await chatbotStreamInstance.get<RagParamCode>(url);

    if (response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
};
