import { TEmployeeFormData } from 'src/@types';
import { IEmployee, IPersonnelHistory } from 'src/@types/apiResponseTypes';
import objectToQueryString from 'src/utils/objectToQueryString';

import { TApiResponseData } from '../apiTypes';
import instance from '../axios';

const SUB_URL = '/v1/emp';

/**
 * 직원 목록 조회
 */
export const getEmployees = async (searchFilter?: {
  empPosCd?: string;
  tmntYn?: string;
  empNm?: string;
}) => {
  try {
    const url = SUB_URL + objectToQueryString(searchFilter || {});
    const response = await instance.get<TApiResponseData<IEmployee[]>>(url);

    // 성공 시 데이터 반환
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 직원 정보 단건조회
 * @param userSid 사용자 고유번호
 */
export const findEmployee = async (userSid: number) => {
  const url = `${SUB_URL}/${userSid}`;
  const response = await instance.get<TApiResponseData<IEmployee>>(url);

  // 성공 시 데이터 반환
  if (response.data.resultCode === 'S' && response.data.data) {
    return {
      data: response.data.data,
    };
  }
};

/**
 * 직원 정보 삭제
 * @param userSid 사용자 고유번호
 */
export const deleteEmployee = async (userSid: number) => {
  try {
    const url = `${SUB_URL}/${userSid}`;
    const response = await instance.delete<TApiResponseData<IEmployee>>(url);
    // 성공 시 데이터 반환
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        success: true,
        data: response.data.data,
      };
    }

    // 실패 시 에러처리
    throw new Error(
      response.data.resultCode === 'F' && response.data.errorMessage
        ? response.data.errorMessage
        : '직원정보 삭제에 실패했습니다.',
    );
  } catch (error) {
    // 에러 발생 시 에러메시지 반환
    console.error(error);
    return {
      success: false,
      errorMessage: error.message as string,
    };
  }
};

/**
 * 직원 정보 등록
 * @param empInfo 직원 등록 정보: TEmployeeFormData
 */
export const createEmployee = async (empInfo: TEmployeeFormData) => {
  try {
    const url = SUB_URL;
    const payload = empInfo;
    const response = await instance.post<TApiResponseData<IEmployee>>(url, payload);

    // 성공 시 데이터 반환
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        success: true,
        data: response.data.data,
      };
    }

    throw new Error(
      response.data.resultCode === 'F' && response.data.errorMessage
        ? response.data.errorMessage
        : '직원 정보 등록에 실패했습니다.',
    );
  } catch (error) {
    console.error(error);
    return {
      success: false,
      errorMessage: error.message,
    };
  }
};

/**
 * 직원 정보 수정
 * @param userSid 사용자 고유번호
 * @param empInfo 직원 등록 정보: TEmployeeFormData
 */
export const updateEmployee = async (userSid: number, empInfo: TEmployeeFormData) => {
  try {
    const url = `${SUB_URL}/${userSid}`;
    const payload = empInfo;
    const response = await instance.put<TApiResponseData<IEmployee>>(url, payload);

    // 성공 시 데이터 반환
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        success: true,
        data: response.data.data,
      };
    }

    throw new Error(
      response.data.resultCode === 'F' && response.data.errorMessage
        ? response.data.errorMessage
        : '직원 정보 수정에 실패했습니다.',
    );
  } catch (error) {
    console.error(error);
    return {
      success: false,
      errorMessage: error.message,
    };
  }
};

/**
 * 직원 개인 발령 이력 조회
 * @param userSid 사용자 고유번호
 */
export const getPersonnelHistory = async (userSid: number) => {
  const url = `${SUB_URL}/history/${userSid}`;
  const response = await instance.get<TApiResponseData<IPersonnelHistory[]>>(url);
  if (response.data.resultCode === 'S' && response.data.data) {
    return response.data.data;
  }

  return [];
};

/**
 * @title 프로필 이미지 업로드
 * @param file: profile image file
 * @param userSid: 사용자 고유번호
 * @returns file path
 */
export const uploadProfileImage = async (file: File | null, userSid: number) => {
  try {
    const formData = new FormData();
    formData.append('file', file ?? '');

    const url = `${SUB_URL}/${userSid}/upload/profile`;
    const response = await instance.post<TApiResponseData<string>>(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      // file path 반환
      return response.data.data;
    }
  } catch (error) {
    console.error(error);
  }
};
