import { useState } from 'react';
import { matchPath, Navigate, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'src/models';
import { PATH_AUTH, PATH_PAGE } from 'src/routes/paths';
import Login from 'src/screens/auth/login/Login';

import LoadingScreen from '../components/loading-screen';

type AuthGuardProps = {
  children: React.ReactNode;
};

function AuthGuard({ children }: AuthGuardProps) {
  const { authStore } = useStores();
  const { isInitialized, isAuthenticated, user } = authStore;

  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  // 사용자 메뉴 리스트
  const userMenuList = user?.menuList;

  // 사용자 메뉴 권한 확인
  const checkUserAccessiblePath = () => {
    if (!userMenuList) return false;

    // 일부 예외처리
    // 홈 화면에는 접근 가능
    if (pathname === PATH_AUTH.root) {
      return true;
    }
    // 보고서의 생성 수정 페이지 권한은 주간보고 메뉴, 회의록 메뉴 권한이 있는지 확인한다.
    if (
      matchPath({ path: PATH_PAGE.management.report.create, end: true }, pathname) ||
      matchPath({ path: PATH_PAGE.management.report.edit, end: false }, pathname)
    ) {
      return userMenuList.some(
        (menu) =>
          menu.menuUri === PATH_PAGE.management.report.root ||
          menu.menuUri === PATH_PAGE.management.report.meeting,
      );
    }

    return userMenuList.some((menu) => menu.menuUri === pathname);
  };

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  if (authStore.user && !checkUserAccessiblePath()) {
    return <Navigate to={PATH_PAGE.page403} />;
  }

  return <>{children}</>;
}

export default observer(AuthGuard);
