import { useCallback, useEffect, useRef, useState } from 'react';
import { BoxProps } from '@mui/material';

interface ResizableBoxProps extends BoxProps {
  minWidth: number;
  maxWidth: number;
}
function useResizeContainer({ minWidth, maxWidth }: ResizableBoxProps) {
  const [resizableChatWindowWidth, setResizableWidth] = useState(minWidth);
  const [isResizing, setIsResizing] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const startXRef = useRef(0); // 드래그 시작 지점
  const startWidthRef = useRef(0); // 드래그 시작 시점의 사이드바 너비

  // 마우스 클릭 이벤트 - 너비 조절
  const handleResizeWidth = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      // 마우스 드래그 시작 지점 저장하여 이후의 마우스 이동을 이 시작점과 비교하여 얼마나 이동했는지 계산할 수 있게 함
      startXRef.current = e.clientX;
      // 드래그 시작 시점의 너비 저장하여 이 초기 너비를 기준으로 드래그 중에 너비 변화를 계산할 수 있게 함.
      startWidthRef.current = resizableChatWindowWidth;
      setIsResizing(true);
    },
    [resizableChatWindowWidth],
  );

  const handleMouseUp = useCallback(() => {
    setIsResizing(false);
  }, []);

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (isResizing && containerRef.current) {
        /**
         * startXRef.current: 드래그를 시작한 초기 위치의 X 좌표
         * e.clientX: 현재 마우스 포인터의 X 좌표
         * 양수 dx: 마우스가 왼쪽으로 이동
         * 음수 dx: 마우스가 오른쪽으로 이동
         */
        const dx = startXRef.current - e.clientX;
        /**
         * 초기 너비가 200px이고, 마우스를 오른쪽으로 30px 이동했으면
         * startWidthRef.current = 200
         * dx = -30 (오른쪽으로 이동했으므로 음수)
         * newChatWindowWidth = 200 + (-30) = 170px

         * 반대로, 마우스를 왼쪽으로 50px 이동했다면
         * startWidthRef.current = 200
         * dx = 50 (왼쪽으로 이동했으므로 양수)
         * newChatWindowWidth = 200 + 50 = 250
         */
        const newChatWindowWidth = startWidthRef.current + dx;
        setResizableWidth(Math.min(Math.max(newChatWindowWidth, minWidth), maxWidth));
      }
    },
    [isResizing, minWidth, maxWidth],
  );

  useEffect(() => {
    if (isResizing) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isResizing, handleMouseMove, handleMouseUp]);

  return { containerRef, resizableChatWindowWidth, handleResizeWidth };
}

export default useResizeContainer;
