import { ChatbotSettingSearchParams } from 'src/@types';
import {
  ChatbotSystemSettingsApiResponse,
  LoadStatus,
  Nas,
  NasList,
} from 'src/@types/apiResponseTypes';

import { chatbotStreamInstance } from '../axios';

const SUB_URL = '/v1/setting';

export const getNases = async ({ keyword, page = 1, size = 25 }: ChatbotSettingSearchParams) => {
  try {
    const url = `${SUB_URL}/nases`;
    const response = await chatbotStreamInstance.get<{ nases: NasList[] }>(url, {
      params: {
        keyword,
        page,
        size,
      },
    });

    if (response.status === 200 && response.data) {
      return response.data.nases;
    }
  } catch (error) {
    console.error(error);
  }
};

export const findNas = async (nas_id: number) => {
  try {
    const url = `${SUB_URL}/nas/${nas_id}`;
    const response = await chatbotStreamInstance.get<Nas>(url);

    if (response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
};

export const deleteNas = async (nas_id: number) => {
  try {
    const url = `${SUB_URL}/nas/${nas_id}`;
    const response = await chatbotStreamInstance.delete<ChatbotSystemSettingsApiResponse>(url);

    if (response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
};

export const addNas = async (nas: Nas) => {
  try {
    const url = `${SUB_URL}/nas`;
    const response = await chatbotStreamInstance.post<ChatbotSystemSettingsApiResponse>(url, nas);

    if (response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateNas = async (nas: Nas) => {
  try {
    const url = `${SUB_URL}/nas`;
    const response = await chatbotStreamInstance.put<ChatbotSystemSettingsApiResponse>(url, nas);

    if (response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
};

export const loadNas = async (nas_id: number) => {
  try {
    const url = `${SUB_URL}/nas/load`;
    const response = await chatbotStreamInstance.post<{
      status: LoadStatus;
      message: string;
      detail: string;
    }>(url, null, { params: { nas_id } });

    if (response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
};
