import './locales/i18n';
import 'simplebar/src/simplebar.css';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'src/static/yup/yupExtensions'; // yup 확장 메서드 임포트
import 'src/components/block-editor/styles/globals.css';

import { useEffect, useRef, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
// ----------------------------------------------------------------------
import { BrowserRouter } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LicenseInfo } from '@mui/x-license';

import { AxiosInterceptor } from './api/useAxiosInterceptor';
import { MotionLazyContainer } from './components/animate';
import CAlert from './components/CAlert';
import { StyledChart } from './components/chart';
import LoadingAPI from './components/LoadingAPI';
import ScrollToTop from './components/scroll-to-top';
import { SettingsProvider } from './components/settings';
import SnackbarProvider from './components/snackbar';
import useEventListener from './hooks/useEventListener';
import { AiChatbot } from './screens/common/ai-chatbot';
import { LOCAL_STORAGE_KEY } from './static/constants';
import { handleRNMessageEvent } from './utils/reactNativeMessageHelper';
import ThemeLocalization from './locales';
import { RootStore, RootStoreProvider, setupRootStore } from './models';
import Router from './routes';
import ThemeProvider from './theme';

export default function App() {
  // react native message event listener
  const documentRef = useRef<Document>(document);
  // ios
  useEventListener('message', handleRNMessageEvent);
  // android
  useEventListener('message', handleRNMessageEvent, documentRef);

  const [rootStore, setRootStore] = useState<RootStore | undefined>(undefined);

  useEffect(() => {
    // mui pro license
    LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_PRO_LICENSE_KEY);

    // setup root store
    setupRootStore().then((store) => {
      setRootStore(store);
    });

    // clear storage
    localStorage.removeItem(LOCAL_STORAGE_KEY.REPORT_FORM_VALUES);
    localStorage.removeItem(LOCAL_STORAGE_KEY.COPIED_CONTENTS);
  }, []);

  if (!rootStore) return null;

  return (
    <RootStoreProvider value={rootStore}>
      <ThemeProvider>
        <SettingsProvider>
          <BrowserRouter>
            <SnackbarProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <ScrollToTop />
                <MotionLazyContainer>
                  <ThemeLocalization>
                    <StyledChart />
                    <AiChatbot />
                    <HelmetProvider>
                      <Router />
                      <CAlert />
                    </HelmetProvider>
                    <LoadingAPI />
                  </ThemeLocalization>
                </MotionLazyContainer>
              </LocalizationProvider>
              <AxiosInterceptor />
            </SnackbarProvider>
          </BrowserRouter>
        </SettingsProvider>
      </ThemeProvider>
    </RootStoreProvider>
  );
}
