import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { DialogTitle, IconButton, Stack, Typography } from '@mui/material';

interface DialogTitleWithCloseButtonProps {
  title: string;
  subtitle?: string;
  onClose?: () => void;
}

function DialogTitleWithCloseButton({ title, subtitle, onClose }: DialogTitleWithCloseButtonProps) {
  return (
    <DialogTitle variant="h3" justifyContent="space-between" display="flex" alignItems="center">
      <Stack direction={'row'} alignItems={'baseline'}>
        {title}{' '}
        {subtitle ? (
          <Typography variant={'body2'} color={'GrayText'} sx={{ ml: 1 }}>
            {subtitle}
          </Typography>
        ) : (
          ''
        )}
      </Stack>
      <IconButton onClick={onClose}>
        <CloseRoundedIcon />
      </IconButton>
    </DialogTitle>
  );
}

export default DialogTitleWithCloseButton;
