import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, styled } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import { TChangePasswordFormData } from 'src/@types';
import { useStores } from 'src/models';
import { INIT_CHANGE_PASSWORD_DATA } from 'src/static/constants';
import changePwdFormValidationSchema from 'src/static/yup/changePwdFormValidationSchema';

import { RHFTextField } from '../hook-form';
import FormProvider from '../hook-form/FormProvider';

// 비밀번호 변경 컴포넌트
const ChangePassword = observer(() => {
  const { authStore } = useStores();
  const { enqueueSnackbar } = useSnackbar();
  const resolver = yupResolver(changePwdFormValidationSchema);
  const methods = useForm<TChangePasswordFormData>({
    defaultValues: INIT_CHANGE_PASSWORD_DATA,
    resolver,
  });

  const onSubmit = async (data: TChangePasswordFormData) => {
    const result = await authStore.changePassword(data);
    if (result) {
      // 비밀번호 변경 성공 시
      if (result.data.success) {
        enqueueSnackbar('비밀번호가 변경되었습니다.', { variant: 'success' });
        return;
      }

      // 비밀번호 변경 실패 시
      enqueueSnackbar(result.data.message, { variant: 'error' });
    }
  };

  return (
    <StyledFormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
      <RHFTextField name="currentPwd" label="현재 비밀번호" type="password" />
      <RHFTextField name="pwd" label="변경할 비밀번호" type="password" />
      <RHFTextField name="pwdCorrect" label="변경할 비밀번호 확인" type="password" />
      <SubmitButton variant="contained" type="submit" size="small">
        변경
      </SubmitButton>
    </StyledFormProvider>
  );
});

export default ChangePassword;

const StyledFormProvider = styled(FormProvider)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  alignSelf: 'flex-end',
}));
