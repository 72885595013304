import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import persist from 'mst-persist';
import { reportApi } from 'src/api';

import { withSetPropAction } from '../extensions/withSetPropAction';

const ContentImageStore = types
  .model('ContentImageStore')
  .props({
    paths: types.optional(types.array(types.string), []),
  })
  .actions(withSetPropAction)
  .actions((self) => ({
    // 업로드된 이미지가 있다면 삭제 요청을 하고 초기화
    async clear() {
      if (self.paths.length > 0) {
        await reportApi.deleteContentImage(self.paths);
        self.setProp('paths', []);
      }
    },
  }));

// 인스턴스 생성
export const contentImageStore = ContentImageStore.create();

// persist store
persist('contentImageStore', contentImageStore, {
  whitelist: ['paths'],
});

type TContentImageStore = Instance<typeof ContentImageStore>;
type TContentImageStoreSnapshot = SnapshotOut<typeof ContentImageStore>;
export interface IContentImageStore extends TContentImageStore {}
export type TContentImageStoreKeys = keyof TContentImageStoreSnapshot & string;
export interface IContentImageStoreSnapshot extends TContentImageStoreSnapshot {}
