import { Box, DialogContent, styled } from '@mui/material';
import DialogAnimate, { Props as DialogProps } from 'src/components/animate/DialogAnimate';
import useResponsive from 'src/hooks/useResponsive';

import DialogTitleWithCloseButton from '../dialog/DialogTitleWithCloseButton';

import BasicUserInfo from './BasicUserInfo';
import ChangePassword from './ChangePassword';
import ProfileImageUpload from './ProfileImageUpload';
import UserEquipment from './UserEquipment';

interface IMyInfoModalModalProps extends DialogProps {}
/**
 * ## MyInfoModalModal 설명
 * - 내 정보 모달
 * - 프로필 이미지 업로드, 기본정보, 비밀번호 변경
 */
function MyInfoModalModal({ open, onClose }: IMyInfoModalModalProps) {
  const title = '내 정보';
  const isDesktop = useResponsive('up', 'lg');
  return (
    <DialogAnimate fullWidth maxWidth="md" open={open} onClose={onClose} scroll="paper">
      <DialogTitleWithCloseButton title={title} onClose={onClose} />
      <ContentContainer>
        <ProfileImageUpload />
        <StyledInnerContainer>
          <StyledLabel>기본정보</StyledLabel>
          <BasicUserInfo />
        </StyledInnerContainer>
        <StyledInnerContainer>
          <StyledLabel>비밀번호 변경</StyledLabel>
          <ChangePassword />
        </StyledInnerContainer>
        {isDesktop && (
          <StyledInnerContainer>
            <StyledLabel>사용장비</StyledLabel>
            <UserEquipment />
          </StyledInnerContainer>
        )}
      </ContentContainer>
    </DialogAnimate>
  );
}

export default MyInfoModalModal;

const ContentContainer = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
}));

const StyledInnerContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: `${theme.spacing(4)} ${theme.spacing(2)}`,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const StyledLabel = styled('span')(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 700,
  padding: theme.spacing(0, 1),
  color: theme.palette.text.secondary,
  position: 'absolute',
  top: '-12px',
  left: '20px',
  background: theme.palette.background.paper,
}));
