import { Helmet } from 'react-helmet-async';
import { alpha, Box, Divider, styled, Typography, useTheme } from '@mui/material';

export default function ChangeLog() {
  const theme = useTheme();
  return (
    <>
      <Helmet>
        <title>Works Changelog</title>
      </Helmet>
      <StyledContainer>
        <ContentTop>
          <Typography variant="subtitle2" color={theme.palette.text.secondary}>
            Last updated Sep 6, 2024
          </Typography>
          <Typography variant="h4">Works Changelog</Typography>
          <Typography variant="body1">Works의 최신 업데이트 및 변경사항을 기록합니다.</Typography>
        </ContentTop>
        <ContentsContainer>
          <Content>
            <DateText>
              <Typography variant="h5">6 September 2024</Typography>
              <Divider />
            </DateText>
            <ContentItem type="added">
              <Typography variant="subtitle1">Added</Typography>
              <Details>
                <DetailItem>사내 챗봇(이너메이트) 추가</DetailItem>
              </Details>
            </ContentItem>
          </Content>
          <Content>
            <DateText>
              <Typography variant="h5">28 August 2024</Typography>
              <Divider />
            </DateText>
            <ContentItem type="updated">
              <Typography variant="subtitle1">Updated</Typography>
              <Details>
                <DetailItem>최근에 달린 댓글 알림 추가</DetailItem>
              </Details>
            </ContentItem>
          </Content>
          <Content>
            <DateText>
              <Typography variant="h5">23 August 2024</Typography>
              <Divider />
            </DateText>
            <ContentItem type="fixed">
              <Typography variant="subtitle1">Fixed</Typography>
              <Details>
                <DetailItem>Tailwind css 스타일 적용되지 않는 문제 처리</DetailItem>
                <DetailItem>보고서 내용 복사 후 적용 시 발생하는 에러 처리</DetailItem>
              </Details>
            </ContentItem>
          </Content>
          <Content>
            <DateText>
              <Typography variant="h5">29 July 2024</Typography>
              <Divider />
            </DateText>
            <ContentItem type="added">
              <Typography variant="subtitle1">Added</Typography>
              <Details>
                <DetailItem>보고서 내용 복사하여 등록 시 적용되도록 기능 추가</DetailItem>
              </Details>
            </ContentItem>
            <ContentItem type="updated">
              <Typography variant="subtitle1">Updated</Typography>
              <Details>
                <DetailItem>보고서 목록 화면 UI 수정</DetailItem>
              </Details>
            </ContentItem>
            <ContentItem type="fixed">
              <Typography variant="subtitle1">Fixed</Typography>
              <Details>
                <DetailItem>
                  editor 리스트 형식 줄여주어 화면에서 폭 좁게 보이는 부분 처리
                </DetailItem>
                <DetailItem>월이 넘어가는 사이에 있는 주차 올바르게 반영되도록 처리</DetailItem>
                <DetailItem>보고서 full-screen 시 menu popper 안보이는 이슈 처리</DetailItem>
              </Details>
            </ContentItem>
          </Content>
          <Content>
            <DateText>
              <Typography variant="h5">18 July 2024</Typography>
              <Divider />
            </DateText>
            <ContentItem type="added">
              <Typography variant="subtitle1">Added</Typography>
              <Details>
                <DetailItem>
                  업무 보고서 등록/수정 시 보고 유형에 따른 보고일 입력 항목 추가
                </DetailItem>
                <DetailItem>보고서 목록 조회 시 full screen view 기능 추가</DetailItem>
                <DetailItem>본부 업무 현황, 팀 업무 현황, 팀원 업무 현황 메뉴 추가</DetailItem>
              </Details>
            </ContentItem>
            <ContentItem type="updated">
              <Typography variant="subtitle1">Updated</Typography>
              <Details>
                <DetailItem>
                  Utils link 우측 상단에 다이얼 버튼으로 floating & 고정 가능하도록 수정
                </DetailItem>
                <DetailItem>본부별 업무보고, 팀별 업무보고 메뉴 제거</DetailItem>
                <DetailItem>프로젝트 PM 다중 선택 가능하도록 수정</DetailItem>
                <DetailItem>프로젝트 키워드 검색 수정</DetailItem>
              </Details>
            </ContentItem>
            <ContentItem type="fixed">
              <Typography variant="subtitle1">Fixed</Typography>
              <Details>
                <DetailItem>첨부파일 관리 권한 수정</DetailItem>
              </Details>
            </ContentItem>
          </Content>
          <Content>
            <DateText>
              <Typography variant="h5">10 July 2024</Typography>
              <Divider />
            </DateText>
            <ContentItem type="added">
              <Typography variant="subtitle1">Added</Typography>
              <Details>
                <DetailItem>회의록, 업무보고 댓글기능 추가</DetailItem>
              </Details>
            </ContentItem>
          </Content>
          <Content>
            <DateText>
              <Typography variant="h5">8 July 2024</Typography>
              <Divider />
            </DateText>
            <ContentItem type="added">
              <Typography variant="subtitle1">Added</Typography>
              <Details>
                <DetailItem>보고서 엑셀 다운로드 기능 추가</DetailItem>
                <DetailItem>보고서 등록, 수정 시 프로젝트별 보고내용 순서 적용기능 추가</DetailItem>
              </Details>
            </ContentItem>
            <ContentItem type="fixed">
              <Typography variant="subtitle1">Fixed</Typography>
              <Details>
                <DetailItem>
                  loading state 변경사항이 업데이트 되지 않아 화면 클릭 안되는 이슈 처리
                </DetailItem>
              </Details>
            </ContentItem>
          </Content>
          <Content>
            <DateText>
              <Typography variant="h5">5 July 2024</Typography>
              <Divider />
            </DateText>
            <ContentItem type="added">
              <Typography variant="subtitle1">Added</Typography>
              <Details>
                <DetailItem>보고서 작성 시 기존 보고서 병합 기능 추가</DetailItem>
                <DetailItem>메뉴바 하단 OpenAI 검색 기능 추가</DetailItem>
                <DetailItem>메뉴바 하단 Utils Link 아이콘 추가</DetailItem>
              </Details>
            </ContentItem>
            <ContentItem type="fixed">
              <Typography variant="subtitle1">Fixed</Typography>
              <Details>
                <DetailItem>업무보고 전체 조회 시 모든 문서유형이 조회되는 문제 처리</DetailItem>
                <DetailItem>본부별, 팀별 보고서에서 제출용 활성화 되지 않는 문제 처리</DetailItem>
              </Details>
            </ContentItem>
          </Content>
          <Content>
            <DateText>
              <Typography variant="h5">4 July 2024</Typography>
              <Divider />
            </DateText>
            <ContentItem type="added">
              <Typography variant="subtitle1">Added</Typography>
              <Details>
                <DetailItem>내정보, 직원정보 모달에 사용 장비 내용 추가</DetailItem>
                <DetailItem>회의록에 외부 참여자 입력 항목 추가</DetailItem>
                <DetailItem>회의록에 구분 입력 항목 추가</DetailItem>
              </Details>
            </ContentItem>
            <ContentItem type="updated">
              <Typography variant="subtitle1">Updated</Typography>
              <Details>
                <DetailItem>메뉴바 상단 프로필 UI 수정</DetailItem>
                <DetailItem>대시보드 업무보고 현황 직책에 따라 다르게 노출되도록 수정</DetailItem>
              </Details>
            </ContentItem>
          </Content>
          <Content>
            <DateText>
              <Typography variant="h5">3 July 2024</Typography>
              <Divider />
            </DateText>
            <ContentItem type="added">
              <Typography variant="subtitle1">Added</Typography>
              <Details>
                <DetailItem>
                  업무보고 등록, 수정 시 작성중인 보고서 저장하기 기능 추가 [새로고침 전까지 데이터
                  유지]
                </DetailItem>
                <DetailItem>
                  본인이 작성한 게시글인 경우 제출 전인지 확인할 수 있는 표시 추가
                </DetailItem>
              </Details>
            </ContentItem>
            <ContentItem type="updated">
              <Typography variant="subtitle1">Updated</Typography>
              <Details>
                <DetailItem>주간보고에서 업무보고로 메뉴 수정</DetailItem>
                <DetailItem>
                  팀별 / 본부별 / 업무 보고서 등록, 수정 시 해당 카테고리의 메뉴가 활성화되도록 수정
                </DetailItem>
                <DetailItem>
                  팀별 업무보고, 본부별 업무보고 메뉴에서 권한에 따라 다르게 목록이 조회되도록 수정
                </DetailItem>
                <DetailItem>
                  템플릿 적용하기 버튼에서 tooltip + 아이콘 버튼으로 스타일 수정
                </DetailItem>
                <DetailItem>
                  Form Modal에서 esc click, background click으로 닫히지 않도록 수정
                </DetailItem>
                <DetailItem>
                  기존 열람자가 삭제할 수 있었던 첨부파일을 파일 등록자만 삭제 가능하도록 수정
                </DetailItem>
                <DetailItem>프로젝트 조회 시 프로젝트 진행일 최신순으로 정렬되도록 수정</DetailItem>
              </Details>
            </ContentItem>
            <ContentItem type="fixed">
              <Typography variant="subtitle1">Fixed</Typography>
              <Details>
                <DetailItem>로그인 상태가 유지되지 않는 이슈 처리</DetailItem>
                <DetailItem>조직도 직원 리스트 정렬 이슈 처리</DetailItem>
              </Details>
            </ContentItem>
          </Content>
          <Content>
            <DateText>
              <Typography variant="h5">28 June 2024</Typography>
              <Divider />
            </DateText>
            <ContentItem type="added">
              <Typography variant="subtitle1">Added</Typography>
              <Details>
                <DetailItem>Works changelog 화면 추가</DetailItem>
                <DetailItem>프로젝트별 WBS 관리 기능 추가</DetailItem>
                <DetailItem>업무보고 수정, 등록 시 제출 여부 업데이트 하는 switch 추가</DetailItem>
                <DetailItem>사내 장비 관리, 서버관리, 가상머신 관리 메뉴 추가</DetailItem>
                <DetailItem>팀별 업무보고, 본부별 업무보고 메뉴 추가</DetailItem>
              </Details>
            </ContentItem>
            <ContentItem type="updated">
              <Typography variant="subtitle1">Updated</Typography>
              <Details>
                <DetailItem>첨부파일 삭제 시 confirm창 띄우도록 수정</DetailItem>
                <DetailItem>
                  보고서 작성 시 문서유형 선택한 값을 유지하도록 수정 [DEFAULT: 주간보고]
                </DetailItem>
                <DetailItem>api 호출 시, 노출되는 로딩화면으로 인한 깜빡임 현상 개선</DetailItem>
                <DetailItem>테이블 스크롤바 넓게 스타일 수정</DetailItem>
              </Details>
            </ContentItem>
            <ContentItem type="fixed">
              <Typography variant="subtitle1">Fixed</Typography>
              <Details>
                <DetailItem>
                  업무 보고 등록 시 문서 유형 선택하지 않고 등록하여 에러나는 문제 처리
                </DetailItem>
                <DetailItem>
                  보고서 템플릿 적용 시 사용여부가 false인 경우에도 템플릿이 적용되는 문제 처리
                </DetailItem>
              </Details>
            </ContentItem>
          </Content>
        </ContentsContainer>
      </StyledContainer>
    </>
  );
}

const StyledContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(6),
}));

const ContentTop = styled('div')(({ theme }) => ({}));

const ContentsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
}));

const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const ContentItem = styled('div', { shouldForwardProp: (props) => props !== 'type' })<{
  type: 'fixed' | 'added' | 'updated';
}>(({ theme, type }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  ...(type === 'added' && {
    color: theme.palette.info.main,
    backgroundColor: alpha(theme.palette.info.main, 0.08),
    borderColor: theme.palette.info.main,
  }),

  ...(type === 'updated' && {
    color: theme.palette.warning.main,
    backgroundColor: alpha(theme.palette.warning.main, 0.08),
    borderColor: theme.palette.warning.main,
  }),

  ...(type === 'fixed' && {
    color: theme.palette.error.main,
    backgroundColor: alpha(theme.palette.error.main, 0.08),
    borderColor: theme.palette.error.main,
  }),
}));

const DateText = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

const Details = styled('ul')(({ theme }) => ({
  marginTop: theme.spacing(1),
  color: theme.palette.text.primary,
  fontSize: theme.typography.body2.fontSize,
  lineHeight: '1.5rem',
  listStyle: 'inside',
}));

const DetailItem = styled('li')(({ theme }) => ({}));
