import {
  TChangePasswordFormData,
  TClientFormData,
  TDeptFormData,
  TEmployeeFormData,
  TEquipmentFormData,
  TGroupFormData,
  TMeetingReportFormData,
  TPagination,
  TPersonnelFormData,
  TProjectFormData,
  TReportTemplateFormData,
  TServerFormData,
  TVmFormData,
  TWbsFormData,
  TWbsItemFormData,
  TWbsTaskFormData,
  TWorkReportFormData,
} from 'src/@types';
import { IClient } from 'src/@types/apiResponseTypes';

// 디포트 페이지 사이즈
export const DEFAULT_PAGE_SIZE = 25;

// 페이지 사이즈 옵션
export const ROWS_PER_PAGE_OPTIONS = [
  { value: 25, label: '25 / page' },
  { value: 50, label: '50 / page' },
  { value: 100, label: '100 / page' },
  { value: 500, label: '500 / page' },
];

// layout header value
export const HEADER = {
  H_MOBILE: 56,
  H_MAIN_DESKTOP: 88,
  // dashboard layout 상단 헤더 높이
  H_DASHBOARD_TOP_HEADER_DESKTOP: 24,
  H_DASHBOARD_TOP_HEADER_MOBILE: 64,
  // dashboard layout 상당 헤더 z-index
  Z_DASHBOARD_TOP_HEADER: 1200,
  // dashboard layout 스크린 헤더 높이
  H_DASHBOARD_SCREEN_HEADER_DESKTOP: 40,
  H_DASHBOARD_SCREEN_HEADER_MOBILE: 30,
  // dashboard layout 스크린 헤더 z-index
  Z_DASHBOARD_SCREEN_HEADER: 1100,
  H_DASHBOARD_DESKTOP: 92,
  H_DASHBOARD_DESKTOP_OFFSET: 92 - 32,
};

// nav value
export const NAV = {
  W_BASE: 280,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,
  H_DASHBOARD_ITEM: 36,
  H_DASHBOARD_ITEM_SUB: 36,
  H_DASHBOARD_ITEM_HORIZONTAL: 32,
};

// nav icon value
export const ICON = {
  NAV_ITEM: 24,
  NAV_ITEM_HORIZONTAL: 22,
  NAV_ITEM_MINI: 22,
};

// API result code
export const API_RESULT_CODE = {
  SUCCESS: 'S',
  FAIL: 'F',
} as const;

// 보고서 유형 코드
export const DOCU_TYPE_CODE = {
  MEETING: 400401,
  DAILY: 400402,
  WEEKLY: 400403,
  MONTHLY: 400404,
  PROJECT: 400405,
} as const;

export const ALL_WORK_REPORT_DOCU_TYPE_CODE = [
  DOCU_TYPE_CODE.DAILY,
  DOCU_TYPE_CODE.WEEKLY,
  DOCU_TYPE_CODE.MONTHLY,
];

// 보고서 유형 목록
export const REPORT_TYPES = {
  MEETING: 'meeting',
  WORK: 'work',
  TEAM: 'team',
  HEADQUARTER: 'headquarter',
} as const;

// 메뉴 type 코드별 영문명
export const MENU_TYPE_CODE_TO_ENG = {
  10402: 'Organization',
  10403: 'Report',
  10404: 'Project',
  10405: 'Management',
  10406: 'Asset',
  10407: 'chat-bot',
} as const;

export const INIT_PAGINATION: TPagination = {
  page: 1,
  size: DEFAULT_PAGE_SIZE,
  offset: 0,
  numberOfElements: 0,
  totalElements: 0,
  totalPages: 1,
  first: true,
  last: true,
};

export const INIT_EMPLOYEE_FORM_DATA: TEmployeeFormData = {
  formType: 'CREATE',
  user: {
    loginId: '',
    userGroupSidList: [],
    pwd: '',
    pwdCorrect: '',
  },
  empNm: '',
  deptSid: 0,
  empPosCd: 200102, // 사원
  empJobrespCd: 200201, // 팀원
  genderTypeCd: 10500, // 남자
  birth: Date.now(),
  hireDay: Date.now(),
  tmntDay: Date.now(),
  tmntYn: false,
  leaveYn: false,
  ntisRegNo: '',
  careerTotYears: 0,
  careerSmlrYears: 0,
  phone: '',
};

export const INIT_DEPT_FORM_DATA: TDeptFormData = {
  formType: 'CREATE',
  deptNm: '',
  deptPsid: 0,
  ordr: 0,
  deptDescr: '',
};

export const INIT_PERSONNEL_FORM_DATA: TPersonnelFormData = {
  formType: 'CREATE',
  psnlApmntConts: '',
  regUser: {
    userNm: '',
    userSid: 0,
    loginId: '',
  },
};

export const INIT_GROUP_FORM_DATA: TGroupFormData = {
  formType: 'CREATE',
  userGroupNm: '',
  dscrpt: '',
  roleIdList: ['ADMIN'],
};

export const INIT_CHANGE_PASSWORD_DATA: TChangePasswordFormData = {
  currentPwd: '',
  pwd: '',
  pwdCorrect: '',
};

// 회의록 폼 초기 데이터
export const INIT_MEETING_REPORT_FORM_DATA: TMeetingReportFormData = {
  docuTypeCd: DOCU_TYPE_CODE.MEETING,
  title: '',
  content: '',
  files: [],
  tagList: [],
  meeting: {
    meetingTime: [new Date(), new Date()],
    members: [],
    extrnList: [],
  },
};

// 업무보고 폼 초기 데이터
export const INIT_WORK_REPORT_FORM_DATA: TWorkReportFormData = {
  submitYn: false,
  docuTypeCd: DOCU_TYPE_CODE.WEEKLY,
  title: '',
  contents: [],
  files: [],
  year: null,
  month: null,
  week: null,
  day: null,
};

// 보고서 템플릿 폼 초기 데이터
export const INIT_REPORT_TEMPLATE_FORM_DATA: TReportTemplateFormData = {
  docuTypeCd: DOCU_TYPE_CODE.WEEKLY,
  tmplNm: '',
  descr: '',
  title: '',
  body: '',
  useYn: true,
};

export const INIT_CLIENT_FORM_DATA: TClientFormData = {
  company: '',
  divisionCd: '',
  gradeCd: '',
  progressCd: '',
  sales: 0,
  empCnt: 0,
  businessNum: '',
  telNo: '',
  faxNo: '',
  webSite: '',
  addr: '',
  addrDetail: '',
  compMemo: '',
};

export const INIT_PROJECT_FORM_DATA: TProjectFormData = {
  severityCd: 200204,
  govtYn: false,
  progYn: false,
  bo: '',
  pmList: [],
  deprtCd: '',
  projectTypeCd: '',
  completeYn: false,
  projectNm: '',
  customerCmpy: {} as IClient,
  contractCmpy: {} as IClient,
  startDay: Date.now(),
  endDay: Date.now(),
  mngList: [],
  projectMngId: '',
};

export const INIT_WBS_FORM_DATA: TWbsFormData = {
  projectSid: null,
  title: '',
  startDt: Date.now(),
  endDt: Date.now(),
};

export const INIT_WBS_TASK_FORM_DATA: TWbsTaskFormData = {
  wbsSid: 0,
  taskPsid: null,
  taskGroup: '',
  title: '',
  subTitle: '',
  memo: '',
  seq: 0,
  progress: 0,
  startDt: Date.now(),
  endDt: new Date().setHours(new Date().getHours() + 120),
  taskOrder: null,
  emp: null,
  empList: null,

  fontSize: 12,
  fontColor: '#000000',
  bgColor: '#FFFFFF',
  indent: 0,
  boldYn: false,
};

export const INIT_WBS_ITEM_FORM_DATA: TWbsItemFormData = {
  color: '#E74C3C',
  title: '',
  memo: '',
  progress: 0,
  startDt: Date.now(),
  endDt: Date.now(),
  priority: 0,
  taskSid: 0,
  itemLinkList: null,
  emp: null,
  empList: null,
  userSid: null,
};

// 직원 직급 코드
export const EMP_POS_CD = {
  INTERN: 200101, // 인턴
  EMPLOYEE: 200102, // 사원
  JUNIOR: 200103, // 주임
  ASSOCIATE: 200104, // 대리
  MANAGER: 200105, // 과장
  SENIOR_MANAGER: 200106, // 차장
  DIRECTOR: 200107, // 부장
  AUDITOR: 200108, // 감사
  EXECUTIVE_DIRECTOR: 200109, // 이사
  EXECUTIVE_VICE_PRESIDENT: 200110, // 상무
  SENIOR_EXECUTIVE_VICE_PRESIDENT: 200111, // 전무
  VICE_PRESIDENT: 200112, // 부사장
  CEO: 200113, // 대표이사
  RESEARCHER: 200114, // 연구원
  SENIOR_RESEARCHER: 200115, // 선임연구원
  PRINCIPAL_RESEARCHER: 200116, // 책임연구원
  LEAD_RESEARCHER: 200117, // 주임연구원
  SENIOR_LEAD_RESEARCHER: 200118, // 선임주임연구원
  RESEARCH_DIRECTOR: 200119, // 연구소장
} as const;

// 직원 직책 코드
export const EMP_JOP_RESP_CD = {
  TEAM_MEMBER: 200201, // 팀원
  TEAM_LEADER: 200202, // 팀장
  DEPARTMENT_HEAD: 200203, // 본부장
  BUSINESS_HEAD: 200204, // 사업부장
  RESEARCH_HEAD: 200205, // 연구소장
  CEO: 200206, // 대표이사
} as const;

// 로컬 스토리지에 저장되는 키
export const LOCAL_STORAGE_KEY = {
  REMEMBER_ME: 'remember-me',
  DOCU_TYPE_CODE: 'docu-type-code',
  REPORT_FORM_VALUES: 'report-form-value',
  WORK_REPORT_VIEW_TYPE: 'work-report-view-type',
  WORK_REPORT_TABLE_MINIMIZE: 'work-report-table-minimize',
  HEADQUARTER_WORK_REPORT_DATA_TYPE: 'headquarter-work-report-data-type',
  COPIED_CONTENTS: 'copied-contents',
} as const;

export const INIT_EQUIPMENT_FORM_DATA: TEquipmentFormData = {
  equipmentSid: 0,
  inventoryId: '',
  invenTypeCd: 3010,
  manufacturerCd: 3033,
  modelName: '',
  modelSn: '',
  purchaseTypeCd: 600102,
  useStatusCd: 3046,
  purchaseDt: Date.now(),
  warrantyDt: Date.now(),
  delYn: false,
  useDeptSid: null,
  useEmp: undefined,
  userSid: 0,
  useStartDt: Date.now(),
  useUser: null,
  memo: '',
  reason: '',
  histories: null,
  files: [],
};

export const INIT_VM_FORM_DATA: TVmFormData = {
  vmSid: 0,
  serverSid: 0,
  vmName: '',
  vmIp: '',
  useStatusCd: 3046,
  memo: '',
  accId: '',
  accPw: '',
  url: '',
  mngDeptSid: 0,
};

export const INIT_SERVER_FORM_DATA: TServerFormData = {
  serverSid: 0,
  name: '',
  loc: '',
  purpose: '',
  hostName: '',
  localIp: '',
  inventoryNo: '',
  modelName: '',
  spec: '',
  manufacturerCd: 3060,
  serialNo: '',
  useStatusCd: 3046,
  installDate: Date.now(),
  installFloor: '',
  surfacePos: '',
  lowerU: 0,
  upperU: 0,
  mainternenceMng: null,
  serviceMng: null,
  purposeCd: 3051,
  inventoryTypeCd: 3050,
  router: '',
  forwardIp: '',
  port1Ip: '',
  port2Ip: '',
  port3Ip: '',
  port4Ip: '',
  memo: '',
  accId: '',
  accPw: '',
  mngDeptSid: null,
  delYn: false,
  files: [],
};

export const JOB_RES_HEAD_LEVEL = [
  EMP_JOP_RESP_CD.DEPARTMENT_HEAD,
  EMP_JOP_RESP_CD.BUSINESS_HEAD,
  EMP_JOP_RESP_CD.RESEARCH_HEAD,
] as number[];

// 팀이 미정인 경우 보내는 값
export const UNDEFINED_TEAM = 999;
