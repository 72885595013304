import { common, createLowlight } from 'lowlight';
import { reportApi } from 'src/api';

import {
  BlockquoteFigure,
  CharacterCount,
  CodeBlockLowlight,
  Color,
  Column,
  Columns,
  Details,
  DetailsContent,
  DetailsSummary,
  Document,
  Dropcursor,
  Emoji,
  emojiSuggestion,
  Figcaption,
  FileHandler,
  Focus,
  FontFamily,
  FontSize,
  Heading,
  Highlight,
  History,
  HorizontalRule,
  ImageBlock,
  ImageUpload,
  Link,
  Placeholder,
  Selection,
  SlashCommand,
  StarterKit,
  Subscript,
  Superscript,
  Table,
  TableCell,
  TableHeader,
  TableOfContents,
  TableRow,
  TaskItem,
  TaskList,
  TextAlign,
  TextStyle,
  TrailingNode,
  Typography,
  Underline,
  UniqueID,
} from '.';

const lowlight = createLowlight(common);

export const ExtensionKit = () => [
  Document,
  Columns,
  Column,
  TaskList,
  TaskItem.configure({
    nested: true,
  }),
  Selection,
  Heading.configure({
    levels: [1, 2, 3, 4, 5, 6],
  }),
  HorizontalRule,
  StarterKit.configure({
    document: false,
    dropcursor: false,
    heading: false,
    horizontalRule: false,
    blockquote: false,
    history: false,
    codeBlock: false,
  }),
  CodeBlockLowlight.configure({
    lowlight,
    defaultLanguage: null,
  }),
  TableOfContents,
  TextStyle,
  FontSize,
  FontFamily,
  Color,
  TrailingNode,
  Link.configure({
    openOnClick: false,
  }),
  Highlight.configure({ multicolor: true }),
  Underline,
  CharacterCount.configure({ limit: 50000 }),
  ImageUpload,
  ImageBlock,
  FileHandler.configure({
    allowedMimeTypes: ['image/png', 'image/jpeg', 'image/gif', 'image/webp'],
    onDrop: async (currentEditor, files, pos) => {
      try {
        const paths = await reportApi.uploadContentImage(files);

        // paths를 result로 받아서 처리
        if (paths) {
          paths.forEach((path) => {
            currentEditor
              .chain()
              .insertContentAt(pos, {
                type: 'imageBlock',
                attrs: {
                  src: `${process.env.REACT_APP_API_URL}/upload${path}`,
                },
              })
              .focus()
              .run();
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    onPaste: async (currentEditor, files, htmlContent) => {
      if (htmlContent) {
        // if there is htmlContent, stop manual insertion & let other extensions handle insertion via inputRule
        // you could extract the pasted file from this url string and upload it to a server for example
        console.log(htmlContent); // eslint-disable-line no-console
        return false;
      }
      try {
        const paths = await reportApi.uploadContentImage(files);

        // paths를 result로 받아서 처리
        if (paths) {
          paths.forEach((path) => {
            currentEditor
              .chain()
              .insertContentAt(currentEditor.state.selection.anchor, {
                type: 'imageBlock',
                attrs: {
                  src: `${process.env.REACT_APP_API_URL}/upload${path}`,
                },
              })
              .focus()
              .run();
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
  }),
  Emoji.configure({
    enableEmoticons: true,
    suggestion: emojiSuggestion,
  }),
  TextAlign.extend({
    addKeyboardShortcuts() {
      return {};
    },
  }).configure({
    types: ['heading', 'paragraph'],
  }),
  Subscript,
  Superscript,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  Typography,
  Details.configure({
    persist: true,
    HTMLAttributes: {
      class: 'details',
    },
  }),
  DetailsSummary,
  DetailsContent,
  Placeholder.configure({
    includeChildren: true,
    showOnlyCurrent: false,
    placeholder: ({ node }) => {
      return '';
    },
  }),
  SlashCommand,
  Focus,
  Figcaption,
  BlockquoteFigure,
  Dropcursor.configure({
    width: 2,
    class: 'ProseMirror-dropcursor border-black',
  }),
  UniqueID.configure({
    types: ['imageBlock'],
  }),
  History,
];

export default ExtensionKit;
