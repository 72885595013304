import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  styled,
  Typography,
} from '@mui/material';
import { TreeViewBaseItem } from '@mui/x-tree-view/models';
import { IEmployee, IMember, IOrganizationChart } from 'src/@types/apiResponseTypes';
import { commonApi } from 'src/api';
import DialogTitleWithCloseButton from 'src/components/dialog/DialogTitleWithCloseButton';
import { RHFAutocomplete } from 'src/components/hook-form';

import { CheckboxTree } from './custom-tree-view';

interface IEmployeeWithDepartment extends IEmployee {
  deptLabel: string;
}

interface RHFEmployeeSelectProps {
  name: string;
  label: string;
  disabled?: boolean;
  returnType?: string;
}

/**
 * ## RHFEmployeeSelect 설명
 * - 직원 조회하여 선택된 userSid를 form에 적용하는 컴포넌트
 */
function RHFEmployeeSelect({
  name,
  label,
  disabled,
  returnType = 'object',
}: RHFEmployeeSelectProps) {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const { setValue, getValues } = useFormContext();
  // 부서연결 레이블이 추가된 직원 리스트 state
  const [employees, setEmployees] = useState<IEmployeeWithDepartment[]>([]);
  //  선택된 직원 리스트 state
  const [selectedEmployees, setSelectedEmployees] = useState<IEmployeeWithDepartment[]>([]);
  // 선택된 직원 아이디 리스트 state
  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([]);
  // 직원 조회 모달 오픈 state
  const [openEmployeeModal, setOpenEmployees] = useState(false);
  // 직원 treeview 아이템 리스트 state
  const [employeeTreeItems, setEmployeeTreeItems] = useState<TreeViewBaseItem[]>([]);
  // 초기화 처리를 위한 state
  const [initialized, setInitialized] = useState(false);

  // 직원, 직원트리 데이터 가져오기
  useEffect(() => {
    getOrganizationData();
  }, []);

  // 초기에 이미 선택된 직원이 있다면 적용하기
  useEffect(() => {
    const selectedMembers = getValues(name) as IMember[];
    if (selectedMembers && selectedMembers.length > 0 && employees.length > 0) {
      const selectedEmployees = employees.filter((emp) =>
        selectedMembers.some((selected) => selected.userSid === emp.userSid),
      );
      setSelectedEmployees(selectedEmployees);
    }
    setInitialized(true);
  }, [employees]);

  // 직원  변경될 때 form에 members 정보 업데이트
  useEffect(() => {
    if (initialized) {
      setValue(
        name,
        selectedEmployees.map((member) =>
          returnType === 'object' ? { userSid: member.userSid } : member.userSid,
        ),
      );
    }
  }, [selectedEmployees]);

  // 조직도 데이터 가져오기
  const getOrganizationData = async () => {
    try {
      const result = await commonApi.getOrganizationData();
      if (result) {
        const employees = convertToEmployeeList(result);
        setEmployees(employees);
        // 조직도 데이터를 treeview데이터로 변환
        const treeItems = convertToEmployeeTreeItem(result);
        setEmployeeTreeItems(treeItems);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // IOrganizationChart 타입의 데이터를 IEmployeeWithDepartment 타입으로 변환
  const convertToEmployeeList = (
    orgChart: IOrganizationChart,
    deptPath: string[] = [],
  ): IEmployeeWithDepartment[] => {
    let employees: IEmployeeWithDepartment[] = [];

    // 현재 부서의 이름을 경로에 추가
    // 부서가 CEO인 경우는 제외
    const currentDeptPath = [...deptPath, ...(orgChart.deptNm !== 'CEO' ? [orgChart.deptNm] : [])];

    // 현재 부서의 직원이 있으면 각 직원에게 deptLabel 추가
    if (orgChart.empList) {
      orgChart.empList.forEach((emp) => {
        employees.push({
          ...emp,
          // 중복 이름이 있을 경우 숫자를 붙여줌
          deptLabel: currentDeptPath.join(' > '), // 부서 계층을 브래드 크럼 형태로 연결
        });
      });
    }

    // 하위 부서가 있으면 재귀적으로 처리
    if (orgChart.childDept) {
      orgChart.childDept.forEach((childDept) => {
        employees = employees.concat(convertToEmployeeList(childDept, currentDeptPath));
      });
    }

    return employees;
  };

  const convertToEmployeeTreeItem = (orgChart: IOrganizationChart): TreeViewBaseItem[] => {
    const treeItems: TreeViewBaseItem[] = [];

    // empList가 있는 부서만 필터링
    function filterOrgCharts(orgCharts: IOrganizationChart[]): IOrganizationChart[] {
      return orgCharts.reduce<IOrganizationChart[]>((acc, orgChart) => {
        // childDept가 있는 경우, 재귀적으로 필터링
        const filteredChildDepts = orgChart.childDept ? filterOrgCharts(orgChart.childDept) : null;

        // 현재 부서의 empList가 null이 아니거나, 필터링된 childDept가 비어있지 않은 경우
        if (orgChart.empList !== null || (filteredChildDepts && filteredChildDepts.length > 0)) {
          acc.push({
            ...orgChart,
            // 필터링된 childDept를 현재 orgChart에 할당
            childDept: filteredChildDepts,
          });
        }

        return acc;
      }, []);
    }
    // 부서에 대한 트리 아이템 생성
    const createTree = (dept: IOrganizationChart): TreeViewBaseItem => {
      const item: TreeViewBaseItem = {
        id: `dept-${dept.deptSid}`,
        label: dept.deptNm,
        children: [],
      };

      // 부서에 속한 직원이 있으면 직원을 추가
      if (dept.empList) {
        dept.empList.forEach((emp) => {
          item.children?.push({
            id: emp.userSid.toString(),
            label: emp.empNm,
          });
        });
      }

      // 하위 부서가 있으면 재귀적으로 처리
      if (dept.childDept) {
        dept.childDept.forEach((dept) => {
          item.children?.push(createTree(dept));
        });
      }

      return item;
    };

    // 조직도 데이터 필터링
    const filteredOrgCharts = filterOrgCharts([orgChart]);
    // 최상위 부서의 트리 아이템 생성
    treeItems.push(createTree(filteredOrgCharts[0]));

    return treeItems;
  };

  // 조회 모달에서 적용하기 클릭 시 처리 로직
  const handleApply = (selectedUserSids: number[]) => {
    const selectedEmployees = employees.filter((emp) => selectedUserSids.includes(emp.userSid));
    setSelectedEmployees(selectedEmployees);
    setOpenEmployees(false);
  };

  // 직원 조회 모달 닫기
  const onClose = () => {
    setOpenEmployees(false);
  };

  return (
    <Container>
      {/* 직원 Auto select */}
      <RHFAutocomplete
        name={name}
        label={label}
        options={employees}
        multiple
        disableCloseOnSelect
        value={selectedEmployees}
        onChange={(_, newValue) => {
          setSelectedEmployees(newValue);
        }}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip label={option.empNm} {...getTagProps({ index })} key={index} />
          ))
        }
        getOptionLabel={(option) => option.empNm}
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option.userSid}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            <StyledStrongText>{option.empNm}</StyledStrongText>
            &nbsp;
            <StyledText>{option.deptLabel}</StyledText>
          </li>
        )}
        sx={{ flexGrow: 1 }}
        disabled={disabled}
      />
      <StyledButton variant="outlined" onClick={() => setOpenEmployees(true)} disabled={disabled}>
        조회
      </StyledButton>
      {/* 직원 조회 모달 */}
      <Dialog fullWidth maxWidth="sm" open={openEmployeeModal} onClose={onClose}>
        <DialogTitleWithCloseButton title={'직원 조회'} onClose={onClose} />
        <DialogContent>
          <CheckboxTree
            items={employeeTreeItems}
            selectedItemIds={selectedItemIds}
            setSelectedItemIds={setSelectedItemIds}
            defaultExpandedItems={employeeTreeItems.length > 0 ? [employeeTreeItems[0].id] : []}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>취소</Button>
          <Button
            variant="contained"
            onClick={() => handleApply(selectedItemIds.map((id) => Number(id)))}
          >
            적용하기
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default RHFEmployeeSelect;

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: theme.spacing(2),
  width: '100%',
}));

const StyledText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 12,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  height: 56,
}));

const StyledStrongText = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
}));
