import * as Yup from 'yup';

const meetingReportFormValidationSchema = () => {
  return Yup.object().shape({
    title: Yup.string().required('제목을 입력해주세요'),
    meeting: Yup.object().shape({
      members: Yup.array().min(1, '회의 참여자를 선택해주세요'),
    }),
  });
};

export default meetingReportFormValidationSchema;
