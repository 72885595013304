// form
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import { FormHelperText } from '@mui/material';

//
import { TUploadFile, Upload, UploadAvatar, UploadBox, UploadProps } from '../upload';

// ----------------------------------------------------------------------

interface Props extends Omit<UploadProps, 'file'> {
  name: string;
  multiple?: boolean;
  expansion?: boolean;
}

// ----------------------------------------------------------------------

export function RHFUploadAvatar({ name, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div>
          <UploadAvatar
            accept={{
              'image/*': [],
            }}
            error={!!error}
            file={field.value}
            {...other}
          />

          {!!error && (
            <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
              {error.message}
            </FormHelperText>
          )}
        </div>
      )}
    />
  );
}

// ----------------------------------------------------------------------

export function RHFUploadBox({ name, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <UploadBox files={field.value} error={!!error} {...other} />
      )}
    />
  );
}

// ----------------------------------------------------------------------

export function RHFUpload({ name, expansion, helperText, ...other }: Props) {
  const { control, setValue, getValues } = useFormContext();

  useEffect(() => {
    // 드롭 이벤트 핸들러
    const onDrop = (event: DragEvent) => {
      event.preventDefault();
      if (event.dataTransfer) {
        handleDrop(Array.from(event.dataTransfer.files));
      }
    };

    // 드래그 오버 이벤트를 막음
    const onDragOver = (event: DragEvent) => {
      event.preventDefault();
    };

    // 이벤트 리스너를 document에 추가
    document.addEventListener('drop', onDrop);
    document.addEventListener('dragover', onDragOver);

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      document.removeEventListener('drop', onDrop);
      document.removeEventListener('dragover', onDragOver);
    };
  }, []);

  const handleDrop = (acceptedFiles: File[]) => {
    const newValue = acceptedFiles;
    setValue(name, newValue);
  };

  const handleRemove = (file: TUploadFile) => {
    const files = getValues(name);
    const newValue = files.filter((item: File) => item !== file);
    setValue(name, newValue);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <Upload
            accept={{ 'image/*': [] }}
            files={field.value}
            onDrop={handleDrop}
            onRemove={handleRemove}
            error={!!error}
            helperText={
              (!!error || helperText) && (
                <FormHelperText error={!!error} sx={{ px: 2 }}>
                  {error ? error?.message : helperText}
                </FormHelperText>
              )
            }
            {...other}
          />
        );
      }}
    />
  );
}
