import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  Badge,
  Box,
  Divider,
  List,
  ListItemAvatar,
  ListItemButton,
  ListSubheader,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import { Stack } from '@mui/system';
import { INotification, IReport } from 'src/@types/apiResponseTypes';
import { commonApi } from 'src/api';
import { IconButtonAnimate } from 'src/components/animate';
import Iconify from 'src/components/iconify';
import MenuPopover from 'src/components/menu-popover';
import Scrollbar from 'src/components/scrollbar';
import { PATH_PAGE } from 'src/routes/paths';
import MeetingReportViewModal, {
  IMeetingReportViewModalProps,
} from 'src/screens/management/report/meeting-management/sections/MeetingReportViewModal';
import WorkReportViewModal, {
  IWorkReportViewModalProps,
} from 'src/screens/management/report/sections/WorkReportViewModal';
import { DOCU_TYPE_CODE, REPORT_TYPES } from 'src/static/constants';
import { fToNow } from 'src/utils/formatTime';

const POLLING_INTERVAL = 300000; // Poll every 5 minutes

export default function NotificationsPopover() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
  const [notifications, setNotifications] = useState<INotification | null>(null);
  const [meetingReportViewModalProps, setMeetingReportViewModalProps] =
    useState<IMeetingReportViewModalProps>({ open: false });
  const [workReportViewModalProps, setWorkReportViewModalProps] =
    useState<IWorkReportViewModalProps>({ open: false });
  const getNotifications = async () => {
    try {
      const notifications = await commonApi.getNotifications();
      notifications && setNotifications(notifications);
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpenPopover = async (event: React.MouseEvent<HTMLElement>) => {
    try {
      // 알림 팝오버 열기
      setOpenPopover(event.currentTarget);

      // 알림 확인 처리
      await commonApi.checkNotificationRead();
    } catch (error) {
      console.error(error);
    }
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  useEffect(() => {
    getNotifications();
    const intervalId = setInterval(() => {
      getNotifications();
    }, POLLING_INTERVAL);

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, []);

  // 제출 전 보고서 클릭 시
  const handleClickReport = (reportSid: number) => {
    // 해당 보고서의 수정 페이지로 이동
    navigate(`${PATH_PAGE.management.report.edit}/${reportSid}?reportType=${REPORT_TYPES.WORK}`);
    handleClosePopover();
  };

  // 최신 댓글 리스트 아이템 클릭 시
  const handleClickComment = (report: IReport) => {
    // 회의록인 경우 회의록 상세보기 모달 오픈
    if (report.docuTypeCd.code === DOCU_TYPE_CODE.MEETING) {
      // 회의록 상세보기 모달 오픈
      setMeetingReportViewModalProps({
        open: true,
        report,
      });
      return;
    }

    // 업무 보고서 보기 모달 오픈
    setWorkReportViewModalProps({
      open: true,
      report,
    });
  };

  return (
    <>
      {/* Notification Button */}
      <IconButtonAnimate
        color={openPopover ? 'primary' : 'default'}
        onClick={handleOpenPopover}
        sx={{ width: 40, height: 40 }}
      >
        <Badge color="error" variant="dot" invisible={!notifications?.isLightOn}>
          <Iconify icon="eva:bell-fill" />
        </Badge>
      </IconButtonAnimate>
      {/* Notification Popover */}
      <MenuPopover
        arrow={'top-left'}
        open={openPopover}
        onClose={handleClosePopover}
        sx={{ width: 360, p: 0 }}
      >
        {/* Title */}
        <TitleContainer>
          <Typography variant="button" color={theme.palette.primary.main}>
            NOTIFICATIONS
          </Typography>
          {(notifications?.comments.length || 0) +
            (notifications?.unSubmittedReports.length || 0) ===
            0 && (
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              알림 사항이 없습니다.
            </Typography>
          )}
        </TitleContainer>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <Scrollbar sx={{ maxHeight: 340, minHeight: 100 }}>
          {/* 제출 전 보고서 알림용 리스트 */}
          {notifications && notifications?.unSubmittedReports.length > 0 && (
            <List
              disablePadding
              subheader={<StyledLisSubheader>제출 전 업무보고</StyledLisSubheader>}
            >
              {notifications?.unSubmittedReports.map((report, index) => (
                <StyledListButton
                  key={`un-submitted-report-${index}`}
                  onClick={() => handleClickReport(report.reportSid)}
                >
                  <Stack flexDirection="row">
                    <ListItemAvatar>
                      <Iconify color={theme.palette.grey[600]} icon="eva:file-text-outline" />
                    </ListItemAvatar>
                  </Stack>
                  <Stack width={290} gap={0.5}>
                    <Typography flexGrow={1} variant="subtitle2" noWrap={true}>
                      {report.title}
                    </Typography>
                    <Typography
                      variant="caption"
                      display="flex"
                      alignItems="center"
                      color={theme.palette.text.secondary}
                    >
                      <Iconify icon="eva:clock-fill" width={16} sx={{ mr: 0.5 }} />
                      {fToNow(report.regDt)}
                    </Typography>
                  </Stack>
                </StyledListButton>
              ))}
              <Divider sx={{ borderStyle: 'dashed' }} />
            </List>
          )}
          {/* 최근 달린 댓글 리스트 */}
          {notifications && notifications?.comments.length > 0 && (
            <List disablePadding subheader={<StyledLisSubheader>최근 댓글</StyledLisSubheader>}>
              {notifications?.comments.map((reportComment) => (
                <StyledListButton
                  key={`report-comment-${reportComment.commentSid}`}
                  onClick={() => handleClickComment(reportComment.report)}
                >
                  <ListItemAvatar>
                    <Iconify color={theme.palette.grey[600]} icon="mdi:comment-text-outline" />
                  </ListItemAvatar>

                  <Stack width={290} gap={0.5}>
                    <Typography flexGrow={1} variant="subtitle2" noWrap={true}>
                      {`${reportComment.report.title} 보고서에 댓글이 달렸습니다.`}
                    </Typography>
                    <Stack
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                      gap={1}
                    >
                      <Typography
                        noWrap
                        variant="caption"
                        display="flex"
                        alignItems="center"
                        color={theme.palette.text.secondary}
                        fontWeight={600}
                      >
                        {`"${reportComment.comment}"`}
                      </Typography>
                      <Typography
                        variant="caption"
                        display="flex"
                        alignItems="center"
                        color={theme.palette.text.secondary}
                        flexShrink={0}
                      >
                        <Iconify icon="eva:clock-fill" width={16} sx={{ mr: 0.5 }} />
                        {fToNow(reportComment.regDt)}
                      </Typography>
                    </Stack>
                  </Stack>
                </StyledListButton>
              ))}
              <Divider sx={{ borderStyle: 'dashed' }} />
            </List>
          )}
        </Scrollbar>
      </MenuPopover>
      <MeetingReportViewModal
        {...meetingReportViewModalProps}
        onClose={() => setMeetingReportViewModalProps({ open: false })}
      />
      <WorkReportViewModal
        {...workReportViewModalProps}
        onClose={() => setWorkReportViewModalProps({ open: false })}
      />
    </>
  );
}

const TitleContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.5, 2),
}));

const StyledListButton = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(1.5, 2),
}));

const StyledLisSubheader = styled(ListSubheader)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  color: theme.palette.primary.main,
  ...theme.typography.overline,
}));
