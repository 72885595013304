function path(root: string, sublink: string) {
  return `${root}/${sublink}`.replace('///', '/').replace('//', '/');
}
const createAdminPath = (...segments: string[]) =>
  segments.reduce((acc, segment) => path(acc, segment), PATH_SUB.adminManagement);

const ROOT = '/';
const ROOT_PATH = path(ROOT, '');

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOT_PATH,
  login: path(ROOT_PATH, 'login'),
  verify: path(ROOT_PATH, 'verify'),
  register: path(ROOT_PATH, 'register'),
  findId: path(ROOT_PATH, 'find-user'),
  newPassword: path(ROOT_PATH, 'new-password'),
  resetPassword: path(ROOT_PATH, 'reset-password'),
  loginUnprotected: path(ROOT_PATH, 'login-unprotected'),
  registerUnprotected: path(ROOT_PATH, 'register-unprotected'),
};

export const PATH_SUB = {
  root: ROOT_PATH,
  home: ROOT_PATH,
  management: path(ROOT_PATH, 'management'),
  adminManagement: path(ROOT_PATH, 'admin-management'),
};

export const PATH_PAGE = {
  page403: path(ROOT_PATH, '403'),
  page404: path(ROOT_PATH, '404'),
  page500: path(ROOT_PATH, '500'),
  comingSoon: path(ROOT_PATH, 'coming-soon'),
  maintenance: path(ROOT_PATH, 'maintenance'),

  management: {
    organization: {
      root: path(PATH_SUB.management, 'organization'),
      employee: path(path(PATH_SUB.management, 'organization'), 'employee'),
      layout: path(path(PATH_SUB.management, 'organization'), 'layout'),
    },
    report: {
      root: path(PATH_SUB.management, 'report'),
      team: path(path(PATH_SUB.management, 'report'), 'team'),
      teamMember: path(path(PATH_SUB.management, 'report'), 'team-member'),
      headquarter: path(path(PATH_SUB.management, 'report'), 'headquarter'),
      meeting: path(path(PATH_SUB.management, 'report'), 'meeting'),
      template: path(path(PATH_SUB.management, 'report'), 'template'),
      attachment: path(path(PATH_SUB.management, 'report'), 'attachment'),
      create: path(path(PATH_SUB.management, 'report'), 'create'),
      edit: path(path(PATH_SUB.management, 'report'), 'edit'),
    },
    inventory: {
      equipment: path(PATH_SUB.management, 'equipment'),
      equipmentStatistics: path(PATH_SUB.management, 'equipment-statistics'),
      server: path(PATH_SUB.management, 'server'),
      vm: path(PATH_SUB.management, 'vm'),
    },
  },
  adminManagement: {
    menu: path(PATH_SUB.adminManagement, 'menu'),
    group: path(PATH_SUB.adminManagement, 'group'),
    employee: path(PATH_SUB.adminManagement, 'employee'),
    dept: path(PATH_SUB.adminManagement, 'dept'),
    user: path(PATH_SUB.adminManagement, 'user'),
    client: path(PATH_SUB.adminManagement, 'client'),
    project: path(PATH_SUB.adminManagement, 'project'),
    projectStatistics: path(PATH_SUB.adminManagement, 'project-statistics'),
    chatbot: {
      root: path(PATH_SUB.adminManagement, 'chat-bot'),
      faq: path(path(PATH_SUB.adminManagement, 'chat-bot'), 'faq'),
      feedback: path(path(PATH_SUB.adminManagement, 'chat-bot'), 'feedback'),
      systemSettings: {
        root: createAdminPath('chat-bot', 'system-settings'),
        vectorDbSync: createAdminPath('chat-bot', 'system-settings', 'vector-db-sync'),
        nasServerSync: createAdminPath('chat-bot', 'system-settings', 'nas-server-sync'),
        databaseSync: createAdminPath('chat-bot', 'system-settings', 'database-sync'),
        webCrawling: createAdminPath('chat-bot', 'system-settings', 'web-crawling-sync'),
        parameterManagement: createAdminPath('chat-bot', 'system-settings', 'parameter-management'),
      },
    },
  },
};
