type TMessage = {
  type: string;
  payload: any;
};

// React Native WebView로 메시지를 보내는 함수
export const sendMessageToRN = (obj: TMessage) => {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(JSON.stringify(obj));
  }
};

// React Native에서 메시지를 받아 처리하는 함수
export const handleRNMessageEvent = (event: MessageEvent) => {
  try {
    // 메시지가 문자열이 아니면 리턴
    if (typeof event.data !== 'string') return;
    const message: TMessage = JSON.parse(event.data);
    // 메시지의 타입에 따라 분기 처리
    switch (message.type) {
      default:
        break;
    }
  } catch {
    // do nothing
  }
};
