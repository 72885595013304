import { IOrgLayout } from 'src/@types/apiResponseTypes';

import { TApiResponseData } from '../apiTypes';
import instance from '../axios';

const SUB_URL = '/v1/office';

/**
 * 자리 배치 목록 조회
 */

/**
 * 사무실 + 자리배치도 등록
 * @param officeName 사무실명
 * @param seatChart 자리배치도 좌표 & 내용
 */
export const createOrgLayout = async (officeName: string, seatChart?: string) => {
  try {
    const url = SUB_URL;
    const payload = { officeName, seatChart };
    const response = await instance.post<TApiResponseData<IOrgLayout>>(url, payload);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 사무실 + 자리배치도 수정
 * @param officeSid 사무실 sid
 * @param officeName 사무실명
 * @param seatChart 자리배치도 좌표 & 내용
 */
export const updateOrgLayout = async (officeSid: number, officeName: string, seatChart: string) => {
  try {
    const url = `${SUB_URL}/${officeSid}`;
    const payload = { officeName, seatChart };
    const response = await instance.put<TApiResponseData<IOrgLayout>>(url, payload);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 사무실 목록 조회
 */
export const getsOrgLayout = async () => {
  try {
    const url = `${SUB_URL}`;

    const response = await instance.get<TApiResponseData<IOrgLayout>>(url);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 사무실 + 자리배치도 조회
 */
export const getOrgLayout = async (officeSid: number) => {
  try {
    const url = `${SUB_URL}/${officeSid}`;

    const response = await instance.get<TApiResponseData<IOrgLayout>>(url);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};
