import { MutableRefObject } from 'react';
import { Button, styled, useTheme } from '@mui/material';
import {
  gridPageSelector,
  gridPageSizeSelector,
  gridRowCountSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro';

import Iconify from '../iconify';

// ------------------------------------------------------------------------------
const PaginationContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
}));

const PageControlButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

/**
 * # CPagination 설명
 * - DataGridPro 컴포넌트의 커스텀 페이징 컴포넌트
 */

export default function CPagination() {
  const apiRef = useGridApiContext();
  const theme = useTheme();
  const page = useGridSelector(apiRef as MutableRefObject<any>, gridPageSelector);
  const pageSize = useGridSelector(apiRef as MutableRefObject<any>, gridPageSizeSelector);
  const rowCount = useGridSelector(apiRef as MutableRefObject<any>, gridRowCountSelector);
  const lastPage = Math.ceil(rowCount / pageSize) - 1;

  // DataGridProApiRef가 없을 경우, 빈 컴포넌트 반환
  if (!apiRef.current) return <></>;

  const isPrevDisabled = page === 0;
  const isNextDisabled = page === lastPage;

  const getIconColor = (isDisabled: boolean) => {
    if (isDisabled) return theme.palette.text.disabled;
    return theme.palette.primary.main;
  };

  const handlePrev = () => {
    apiRef.current.setPage(page - 1);
  };

  const handleNext = () => {
    apiRef.current.setPage(page + 1);
  };

  return (
    <PaginationContainer>
      <PageControlButton disabled={isPrevDisabled} variant="text" onClick={handlePrev}>
        <Iconify icon="grommet-icons:form-previous" color={getIconColor(isPrevDisabled)} />
        Previous
      </PageControlButton>
      <PageControlButton disabled={isNextDisabled} variant="text" onClick={handleNext}>
        Next
        <Iconify icon="grommet-icons:form-next" color={getIconColor(isNextDisabled)} />
      </PageControlButton>
    </PaginationContainer>
  );
}
