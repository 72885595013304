import { IWbsSearchParams, TWbsFormData, TWbsItemFormData, TWbsTaskFormData } from 'src/@types';
import { IWbs, IWbsItem, IWbsItemLink, IWbsTask } from 'src/@types/apiResponseTypes';

import { TApiResponseData } from '../apiTypes';
import instance from '../axios';

const SUB_URL = '/v1/wbs';

/**
 * WBS 목록 조회
 * @param params 검색 조건
 */
export const gets = async (params?: IWbsSearchParams) => {
  try {
    const url = SUB_URL;
    const response = await instance.get<TApiResponseData<IWbs[]>>(url, {
      params,
    });

    // 성공 시 데이터 반환
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
        pagination: response.data.pagination,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * WBS 단건 조회
 * @param wbsSid WBS 고유번호
 */
export const get = async (wbsSid: number) => {
  try {
    const url = `${SUB_URL}/${wbsSid}`;
    const response = await instance.get<TApiResponseData<IWbs>>(url);

    // 성공 시 데이터 반환
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * WBS 등록
 * @param wbsInfo WBS 등록 정보: TWbsFormData
 */
export const create = async (wbsInfo: TWbsFormData) => {
  try {
    const url = SUB_URL;
    const response = await instance.post<TApiResponseData<IWbs>>(url, wbsInfo);

    // 성공 시 데이터 반환
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * WBS 정보 수정
 * @param wbsSid WBS 고유번호
 * @param wbsInfo WBS 등록 정보: TWbsFormData
 */
export const update = async (wbs: IWbs, wbsInfo: TWbsFormData) => {
  try {
    const url = `${SUB_URL}/${wbs.wbsSid}`;
    const payload = wbsInfo;
    const response = await instance.put<TApiResponseData<IWbs>>(url, payload);

    // 성공 시 데이터 반환
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * WBS 삭제
 * @param wbsSid WBS 고유번호
 */
export const remove = async (wbsSid: number) => {
  try {
    const url = `${SUB_URL}/${wbsSid}`;
    const response = await instance.delete<TApiResponseData<boolean>>(url);

    // 성공 시 데이터 반환
    if (response.data.resultCode === 'S' && response.data.data) {
      // 삭제 성공 시 true 반환
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

export const getTask = async (task: IWbsTask) => {
  try {
    const url = `${SUB_URL}/${task.wbsSid}/task/${task.taskSid}`;
    const response = await instance.get<TApiResponseData<IWbsTask>>(url);

    // 성공 시 데이터 반환
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

export const createTask = async (task: IWbsTask, info: TWbsTaskFormData) => {
  try {
    const url = `${SUB_URL}/${task.wbsSid}/task`;
    const response = await instance.post<TApiResponseData<IWbsTask>>(url, info);

    // 성공 시 데이터 반환
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateTask = async (task: IWbsTask, info: TWbsTaskFormData) => {
  try {
    const url = `${SUB_URL}/${task.wbsSid}/task/${task.taskSid}`;
    const payload = info;
    const response = await instance.put<TApiResponseData<IWbsTask>>(url, payload);

    // 성공 시 데이터 반환
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

export const removeTask = async (task: IWbsTask) => {
  try {
    const url = `${SUB_URL}/${task.wbsSid}/task/${task.taskSid}`;
    const response = await instance.delete<TApiResponseData<boolean>>(url);

    // 성공 시 데이터 반환
    if (response.data.resultCode === 'S' && response.data.data) {
      // 삭제 성공 시 true 반환
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

export const getItem = async (item: IWbsItem) => {
  try {
    const url = `${SUB_URL}/${item.wbsSid}/task/${item.taskSid}/item/${item.itemSid}`;
    const response = await instance.get<TApiResponseData<IWbsItem>>(url);

    // 성공 시 데이터 반환
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

export const createItem = async (item: IWbsItem, info: TWbsItemFormData) => {
  try {
    const url = `${SUB_URL}/${item.wbsSid}/task/${item.taskSid}/item`;
    const response = await instance.post<TApiResponseData<IWbsItem>>(url, info);

    // 성공 시 데이터 반환
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateItem = async (item: IWbsItem, info: TWbsItemFormData) => {
  try {
    const url = `${SUB_URL}/${item.wbsSid}/task/${item.taskSid}/item/${item.itemSid}`;
    const payload = info;
    const response = await instance.put<TApiResponseData<IWbsItem>>(url, payload);

    // 성공 시 데이터 반환
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

export const removeItem = async (item: IWbsItem) => {
  try {
    const url = `${SUB_URL}/${item.wbsSid}/task/${item.taskSid}/item/${item.itemSid}`;
    const response = await instance.delete<TApiResponseData<boolean>>(url);

    // 성공 시 데이터 반환
    if (response.data.resultCode === 'S' && response.data.data) {
      // 삭제 성공 시 true 반환
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

export const dependantItem = async (from: IWbsItem, to: IWbsItem) => {
  try {
    const url = `${SUB_URL}/${from.wbsSid}/task/${from.taskSid}/item/${from.itemSid}/link`;
    const payload = {
      linkTo: [to.itemSid],
    };
    const response = await instance.post<TApiResponseData<IWbsItem>>(url, payload);

    // 성공 시 데이터 반환
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

export const removeLink = async (from: IWbsItem, link: IWbsItemLink) => {
  try {
    const url = `${SUB_URL}/${from.wbsSid}/task/${from.taskSid}/item/${from.itemSid}/link/${link.itemLinkSid}`;
    const response = await instance.delete<TApiResponseData<boolean>>(url);

    // 성공 시 데이터 반환
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};
