import * as Yup from 'yup';

const changePwdFormValidationSchema = Yup.object().shape({
  currentPwd: Yup.string().required('현재 비밀번호를 입력하세요'),
  pwd: Yup.string()
    .required('변경할 비밀번호를 입력하세요')
    .passwordComplexity('비밀번호는 8자 이상, 영문, 숫자, 특수문자를 포함해야 합니다.'),
  pwdCorrect: Yup.string()
    .required('변경할 비밀번호 확인을 입력하세요')
    .oneOf([Yup.ref('pwd')], '비밀번호가 서로 다릅니다.'),
});

export default changePwdFormValidationSchema;
