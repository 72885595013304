// @mui
import { Stack } from '@mui/material';
import LoginBannerImageSrc from 'src/assets/illustrations/login_banner.png';
import Image from 'src/components/image/Image';

import { StyledContent, StyledRoot, StyledSection } from './styles';

// ----------------------------------------------------------------------

type Props = {
  title?: string;
  illustration?: string;
  children: React.ReactNode;
};

export default function LoginLayout({ children, illustration, title }: Props) {
  return (
    <StyledRoot>
      <StyledSection>
        <Image
          alt="auth"
          disabledEffect
          visibleByDefault
          src={illustration || LoginBannerImageSrc}
          sx={{ width: '100%', height: '100%' }}
        />
      </StyledSection>
      <StyledContent>
        <Stack sx={{ width: 1 }}> {children} </Stack>
      </StyledContent>
    </StyledRoot>
  );
}
