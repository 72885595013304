import { useCallback, useEffect, useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box, IconButton, styled, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import { employeeApi } from 'src/api';
import { useStores } from 'src/models';
import getProfileImg from 'src/utils/getProfileImg';

import { UploadAvatar } from '../upload';

// 프로필 이미지 업로드 컴포넌트
const ProfileImageUpload = observer(() => {
  const { enqueueSnackbar } = useSnackbar();
  const { authStore } = useStores();
  const { user } = authStore;

  const [avatarUrl, setAvatarUrl] = useState<File | string | null>(null);

  useEffect(() => {
    setAvatarUrl(getProfileImg(authStore.user?.profileImgUrl));
  }, [authStore.user?.profileImgUrl]);

  // 프로필 이미지 업로드
  const handleDropProfile = useCallback(async (acceptedFiles: File[]) => {
    try {
      const file = acceptedFiles[0];
      const userSid = user?.userSid;

      if (file && userSid) {
        const result = await employeeApi.uploadProfileImage(file, userSid);
        if (result) {
          enqueueSnackbar('프로필이 업로드 되었습니다.', { variant: 'success' });
          setAvatarUrl(getProfileImg(result));
          // user store에 프로필 이미지 업데이트
          authStore.setProp('user', {
            ...user,
            profileImgUrl: result,
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  // 프로필 이미지 삭제
  const handleDeleteProfile = async () => {
    try {
      const userSid = user?.userSid;

      if (userSid) {
        await employeeApi.uploadProfileImage(null, userSid);
        setAvatarUrl(null);
        enqueueSnackbar('프로필이 삭제 되었습니다.', { variant: 'success' });
        // user store에 프로필 이미지 삭제
        authStore.setProp('user', {
          ...user,
          profileImgUrl: null,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <AvatarContainer>
      <UploadAvatar
        file={avatarUrl}
        onDrop={handleDropProfile}
        accept={{
          'image/*': ['.jpeg', '.jpg', '.png', '.gif'],
        }}
        helperText={
          <Typography
            variant="caption"
            sx={{
              mt: 2,
              mx: 'auto',
              display: 'block',
              textAlign: 'center',
              color: 'text.secondary',
            }}
          >
            Allowed *.jpeg, *.jpg, *.png, *.gif
          </Typography>
        }
      />
      {authStore.user?.profileImgUrl && (
        <DeleteProfileButton color="primary" onClick={handleDeleteProfile}>
          <CancelIcon />
        </DeleteProfileButton>
      )}
    </AvatarContainer>
  );
});

export default ProfileImageUpload;

const AvatarContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  alignSelf: 'center',
}));

const DeleteProfileButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  zIndex: 9999,
  top: theme.spacing(0.8),
  right: theme.spacing(1),
}));
