import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { AppBar, Breadcrumbs, styled, Toolbar, Typography } from '@mui/material';
import { find } from 'lodash';
import useNavData from 'src/hooks/useNavData';
import useResponsive from 'src/hooks/useResponsive';
import { HEADER } from 'src/static/constants';
import { bgBlur } from 'src/utils/cssStyles';

export type HeaderInfo = {
  heading: string;
  links: { name: string }[];
};

const INIT_CUSTOM_HEADER: HeaderInfo = {
  heading: '',
  links: [],
};

interface MainHeaderProps {
  customHeader?: HeaderInfo;
}

export default function MainHeader({ customHeader }: MainHeaderProps) {
  const isDesktop = useResponsive('up', 'lg');
  const { pathname } = useLocation();
  const navData = useNavData();
  const [headerInfo, setHeaderInfo] = useState<HeaderInfo>(INIT_CUSTOM_HEADER);

  const updateHeaderInfo = (heading: string, links: string[]) => {
    setHeaderInfo({ heading, links: links.map((link) => ({ name: link })) });
  };

  // path가 변경되면 headerInfo를 업데이트 한다
  useEffect(() => {
    // set heading and links
    navData.forEach((nav) => {
      const title1 = nav.subheader;
      nav.items.forEach((item) => {
        const title2 = item.title;
        if (item.path === pathname && !item.children) {
          updateHeaderInfo(title2, [title1, title2]);
        } else {
          const path = find(item.children || [], (item) => item.path === pathname);
          if (path) {
            const title3 = path.title;
            updateHeaderInfo(title3, [title1, title2, title3]);
          }
        }
      });
    });
  }, [pathname, navData]);

  useEffect(() => {
    if (customHeader) {
      setHeaderInfo(customHeader);
    }
  }, [customHeader]);

  return (
    <StyledHeader position="absolute">
      <StyledToolbar>
        {/* HEADING */}
        <Typography variant="h4" color={'text.primary'} fontWeight={600}>
          {headerInfo.heading}
        </Typography>
        {/* BREADCRUMBS */}
        {isDesktop && (
          <Breadcrumbs separator={<Separator />}>
            {headerInfo.links.map((link: { name: string }, index: number) => {
              return (
                <Typography key={index} variant="caption" color={'text.secondary'}>
                  {link.name}
                </Typography>
              );
            })}
          </Breadcrumbs>
        )}
      </StyledToolbar>
    </StyledHeader>
  );
}

const StyledHeader = styled(AppBar)(({ theme }) => ({
  height: HEADER.H_DASHBOARD_SCREEN_HEADER_MOBILE,
  zIndex: HEADER.Z_DASHBOARD_SCREEN_HEADER,
  boxShadow: theme.customShadows.z1,
  transition: theme.transitions.create(['height'], {
    duration: theme.transitions.duration.shorter,
  }),
  ...bgBlur({
    color: theme.palette.background.default,
  }),

  // desktop style
  [theme.breakpoints.up('lg')]: {
    height: HEADER.H_DASHBOARD_SCREEN_HEADER_DESKTOP,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  padding: theme.spacing(0, 1),
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'baseline',
  gap: theme.spacing(1),
}));

const Separator = styled('span')(({ theme }) => ({
  width: 4,
  height: 4,
  borderRadius: '50%',
  backgroundColor: theme.palette.text.disabled,
}));
