// @mui
import { AppBar, IconButton, Toolbar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { HEADER, NAV } from 'src/static/constants';

import Iconify from '../../../components/iconify';
// components
import Logo from '../../../components/logo';
import { useSettingsContext } from '../../../components/settings';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// utils
import { bgBlur } from '../../../utils/cssStyles';

//
import AccountPopover from './AccountPopover';

// ----------------------------------------------------------------------

type Props = {
  onOpenNav?: VoidFunction;
  onOpenMyInfoModal?: VoidFunction;
};

export default function Header({ onOpenNav, onOpenMyInfoModal }: Props) {
  const theme = useTheme();

  const { themeLayout } = useSettingsContext();

  const isNavHorizontal = themeLayout === 'horizontal';

  const isNavMini = themeLayout === 'mini';

  const isDesktop = useResponsive('up', 'lg');

  const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal;

  const renderContent = () => {
    // mobile UI
    if (!isDesktop) {
      return (
        <>
          <IconButton onClick={onOpenNav} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButton>
          <Logo />
          <AccountPopover onOpenMyInfoModal={onOpenMyInfoModal} />
        </>
      );
    }

    if (isDesktop && isNavHorizontal) {
      return <Logo sx={{ mr: 2.5 }} />;
    }

    return <></>;
  };

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        height: HEADER.H_DASHBOARD_TOP_HEADER_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(isDesktop && {
          width: `calc(100% - ${NAV.W_DASHBOARD + 1}px)`,
          height: HEADER.H_DASHBOARD_TOP_HEADER_DESKTOP,
          ...(isOffset && {
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '100%',
          padding: theme.spacing(0, 1),
        }}
      >
        {renderContent()}
      </Toolbar>
    </AppBar>
  );
}
