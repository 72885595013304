// form
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import {
  Autocomplete,
  AutocompleteProps,
  ChipTypeMap,
  TextField,
  TextFieldProps,
} from '@mui/material';

// ----------------------------------------------------------------------

interface Props<
  Value,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
> extends AutocompleteProps<Value, Multiple, DisableClearable, FreeSolo, ChipComponent> {
  name: string;
  label: string;
  inputProps?: TextFieldProps;
  helperText?: string;
}

export default function RHFAutocomplete<
  Value,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
>({
  name,
  label,
  helperText,
  inputProps,
  ...other
}: Omit<Props<Value, Multiple, DisableClearable, FreeSolo, ChipComponent>, 'renderInput'>) {
  const { control, setValue } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          value={field.value || []}
          onChange={(event, newValue) => {
            setValue(name, newValue, { shouldValidate: true });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={!!error}
              onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
              helperText={error ? error?.message : helperText}
              {...inputProps}
            />
          )}
          {...other}
        />
      )}
    />
  );
}
