import { styled, Theme, useTheme } from '@mui/material';
import { alpha } from '@mui/system';
import { DataGridPro, DataGridProProps } from '@mui/x-data-grid-pro';
import { DEFAULT_PAGE_SIZE } from 'src/static/constants';

/*
 * << 컴포넌트 속성 예제 >>
 * 컴포넌트의 상태별 색상
 */
type TCDataGridProColor =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'info'
  | 'success'
  | 'warning'
  | 'error';

/*
 * << 컴포넌트 속성 예제 >>
 * 컴포넌트의 외곽선의 종류
 */
type TCDataGridProVariant = 'filled' | 'outlined' | 'solid' | 'dotted' | 'dashed' | 'ghost';

/*
 * 컴포넌트의 속성
 */
interface ICDataGridProProps extends DataGridProProps {
  color?: TCDataGridProColor;
  variant?: TCDataGridProVariant;
}

// DataGridPro 컴포넌트 커스텀 스타일
const StyledDataGridPro = styled(DataGridPro)(
  ({
    theme,
    ownerState,
  }: {
    theme: Theme;
    ownerState: {
      color: TCDataGridProColor;
      variant: TCDataGridProVariant;
    };
  }) => {
    const { color, variant } = ownerState;

    return {
      ...(color === 'primary' && {
        backgroundColor: theme.palette.primary[theme.palette.mode],
      }),
      ...(color === 'secondary' && {
        backgroundColor: theme.palette.secondary[theme.palette.mode],
      }),
      ...(variant === 'solid' && {
        border: '1px solid darkblue',
      }),
      ...(variant === 'dashed' && {
        border: '3px dashed darkred',
      }),

      color: theme.palette.grey[600],
      fontSize: 14,
      userSelect: 'none',

      '& .MuiDataGrid-cell:hover': {
        color: theme.palette.text.primary,
      },

      '& .MuiDataGrid-iconSeparator': {
        visibility: 'hidden',
      },

      '& .MuiDataGrid-columnHeaders': {
        backgroundColor: theme.palette.background.paper,
      },

      '.MuiDataGrid-container--top [role=row]': {
        backgroundColor: alpha(theme.palette.grey[500], 0.2),
      },

      '& .MuiDataGrid-columnHeader': {
        borderRight: `1px solid ${alpha(theme.palette.text.disabled, 0.4)}`,
        '&.MuiDataGrid-columnHeader--last': { borderRight: 'none' },
      },

      '& .MuiDataGrid-cell:not(:last-child)': {
        borderBottom: `1px solid ${alpha(theme.palette.text.disabled, 0.4)} `,
        borderRight: `1px solid ${alpha(theme.palette.text.disabled, 0.4)}`,
      },

      '& .MuiPaginationItem-root': {
        borderRadius: 0,
      },

      // scrollbar
      '& .MuiDataGrid-scrollbar': {
        opacity: 1,
      },
      '& .MuiDataGrid-scrollbar--vertical::-webkit-scrollbar': {
        width: 10,
      },
      '& .MuiDataGrid-scrollbar--horizontal::-webkit-scrollbar': {
        height: 10,
      },
      '& .MuiDataGrid-scrollbar::-webkit-scrollbar-track': {
        background: 'transparent',
      },
      '& .MuiDataGrid-scrollbar::-webkit-scrollbar-thumb': {
        backgroundColor: alpha(theme.palette.grey[600], 0.48),
      },
      '& .MuiDataGrid-scrollbar::-webkit-scrollbar-thumb:hover': {
        backgroundColor: alpha(theme.palette.grey[600], 0.48),
      },
    };
  },
);

const CDataGridPro = ({
  color = 'default',
  variant = 'ghost',
  sx,
  rows,
  columns,
  ...props
}: ICDataGridProProps) => {
  const theme = useTheme();

  return (
    <StyledDataGridPro
      // 스타일 관련 props
      theme={theme}
      ownerState={{ color, variant }}
      sx={sx}
      // 그리드 관련 props 필수 값: columns, rows
      columns={columns}
      rows={rows}
      checkboxSelection
      paginationMode="server"
      pagination
      columnHeaderHeight={48}
      rowHeight={54}
      initialState={{
        pagination: {
          paginationModel: {
            page: 0,
            pageSize: DEFAULT_PAGE_SIZE,
          },
        },
        columns: {
          columnVisibilityModel: {
            id: false,
          },
        },
      }}
      {...props}
    ></StyledDataGridPro>
  );
};

export default CDataGridPro;
