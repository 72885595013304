import React, { useState } from 'react';
import { Button, InputAdornment, styled, TextField, TextFieldProps } from '@mui/material';

interface SearchFilterProps {
  inputProps?: TextFieldProps;
  onSearch: (searchText: string) => void;
}

function SearchFilter({ inputProps, onSearch }: SearchFilterProps) {
  const [searchText, setSearchText] = useState<string>('');
  return (
    <StyledTextField
      value={searchText}
      onChange={(e) => setSearchText(e.target.value)}
      placeholder="검색어를 입력하세요"
      size="small"
      onKeyUp={(e) => {
        if (e.key === 'Enter') {
          onSearch(searchText);
        }
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchButton
              variant="outlined"
              onClick={(e) => {
                e.preventDefault();
                onSearch(searchText);
              }}
            >
              조회
            </SearchButton>
          </InputAdornment>
        ),
      }}
      {...inputProps}
    />
  );
}

export default SearchFilter;

const StyledTextField = styled(TextField)(({ theme }) => ({
  minWidth: 250,

  '& .MuiInputBase-root': {
    padding: 0,
  },

  '& .MuiInputBase-root:hover fieldset, & .MuiInputBase-root.Mui-focused fieldset': {
    borderColor: theme.palette.grey[600],
  },
}));

const SearchButton = styled(Button)({
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  height: 40,
});
