import { getDate, getYear } from 'date-fns';
import {
  DBData,
  RagParameterFormData,
  TClientFormData,
  TDeptFormData,
  TEmployeeFormData,
  TEquipmentFormData,
  TGroupFormData,
  TMeetingReportFormData,
  TPersonnelFormData,
  TProjectFormData,
  TReportTemplateFormData,
  TServerFormData,
  TVmFormData,
  TWbsFormData,
  TWbsItemFormData,
  TWbsTaskFormData,
  TWorkReportFormData,
} from 'src/@types';
import {
  IClient,
  IDepartment,
  IEmployee,
  IEquipment,
  IPersonnelHistory,
  IProject,
  IReport,
  IReportTemplate,
  IServer,
  IUserGroup,
  IVm,
  IWbs,
  IWbsItem,
  IWbsTask,
  Nas,
  RagParam,
} from 'src/@types/apiResponseTypes';
import { DEFAULT_WORK_DOCU_CODE } from 'src/screens/management/report/hooks/useDocuType';
import {
  DOCU_TYPE_CODE,
  INIT_CLIENT_FORM_DATA,
  INIT_DEPT_FORM_DATA,
  INIT_EMPLOYEE_FORM_DATA,
  INIT_EQUIPMENT_FORM_DATA,
  INIT_GROUP_FORM_DATA,
  INIT_MEETING_REPORT_FORM_DATA,
  INIT_PERSONNEL_FORM_DATA,
  INIT_PROJECT_FORM_DATA,
  INIT_REPORT_TEMPLATE_FORM_DATA,
  INIT_SERVER_FORM_DATA,
  INIT_VM_FORM_DATA,
  INIT_WBS_FORM_DATA,
  INIT_WBS_ITEM_FORM_DATA,
  INIT_WBS_TASK_FORM_DATA,
  INIT_WORK_REPORT_FORM_DATA,
} from 'src/static/constants';
import { getWeek } from 'src/utils/dateHelper';

// employee form에 초기값 생성하는 합수
export const getEmployeeFormDefaultValues = (employee?: IEmployee): TEmployeeFormData => {
  return employee
    ? {
        formType: 'UPDATE',
        user: {
          loginId: employee.user?.loginId || INIT_EMPLOYEE_FORM_DATA.user.loginId,
          userGroupSidList:
            employee.user?.userGroupSidList || INIT_EMPLOYEE_FORM_DATA.user.userGroupSidList,
          pwd: '',
          pwdCorrect: '',
        },
        empNm: employee.empNm || INIT_EMPLOYEE_FORM_DATA.empNm,
        deptSid: employee.deptSid || INIT_EMPLOYEE_FORM_DATA.deptSid,
        empPosCd: employee.empPosCd?.code || INIT_EMPLOYEE_FORM_DATA.empPosCd,
        empJobrespCd: employee.empJobrespCd?.code || INIT_EMPLOYEE_FORM_DATA.empJobrespCd,
        genderTypeCd: employee.genderTypeCd?.code || INIT_EMPLOYEE_FORM_DATA.genderTypeCd,
        birth: employee.birth || INIT_EMPLOYEE_FORM_DATA.birth,
        hireDay: employee.hireDay || INIT_EMPLOYEE_FORM_DATA.hireDay,
        tmntDay: employee.tmntDay || INIT_EMPLOYEE_FORM_DATA.tmntDay,
        tmntYn: employee.tmntYn || INIT_EMPLOYEE_FORM_DATA.tmntYn,
        leaveYn: employee.leaveYn || INIT_EMPLOYEE_FORM_DATA.leaveYn,
        ntisRegNo: employee.ntisRegNo || INIT_EMPLOYEE_FORM_DATA.ntisRegNo,
        careerTotYears: employee.careerTotYears || INIT_EMPLOYEE_FORM_DATA.careerTotYears,
        careerSmlrYears: employee.careerSmlrYears || INIT_EMPLOYEE_FORM_DATA.careerSmlrYears,
        phone: employee.phone || INIT_EMPLOYEE_FORM_DATA.phone,
      }
    : INIT_EMPLOYEE_FORM_DATA;
};

export const getDeptFormDefaultValues = (dept?: IDepartment): TDeptFormData => {
  return dept
    ? {
        formType: 'UPDATE',
        deptNm: dept.deptNm || INIT_DEPT_FORM_DATA.deptNm,
        deptPsid: dept.deptPsid || INIT_DEPT_FORM_DATA.deptPsid,
        deptDescr: dept.deptDescr || INIT_DEPT_FORM_DATA.deptDescr,
        ordr: dept.ordr || INIT_DEPT_FORM_DATA.ordr,
      }
    : INIT_DEPT_FORM_DATA;
};

export const getPersonnelFormDefaultValues = (
  personnel?: IPersonnelHistory,
): TPersonnelFormData => {
  return personnel
    ? {
        formType: 'UPDATE',
        psnlApmntConts: personnel.psnlApmntConts || INIT_PERSONNEL_FORM_DATA.psnlApmntConts,
        regUser: personnel.regUser || INIT_PERSONNEL_FORM_DATA.regUser,
      }
    : INIT_PERSONNEL_FORM_DATA;
};

export const getGroupFormDefaultValues = (group?: IUserGroup): TGroupFormData => {
  return group
    ? {
        formType: 'UPDATE',
        userGroupNm: group.userGroupNm || INIT_GROUP_FORM_DATA.userGroupNm,
        dscrpt: group.dscrpt || INIT_GROUP_FORM_DATA.dscrpt,
        roleIdList: group.roleIdList || INIT_GROUP_FORM_DATA.roleIdList,
      }
    : INIT_GROUP_FORM_DATA;
};

export const getMeetingReportFormDefaultValues = (report?: IReport): TMeetingReportFormData => {
  return report
    ? {
        docuTypeCd: report.docuTypeCd.code,
        title: report.title || INIT_MEETING_REPORT_FORM_DATA.title,
        content: report.content || INIT_MEETING_REPORT_FORM_DATA.content,
        files: [] || INIT_MEETING_REPORT_FORM_DATA.files,
        tagList:
          report.tagList?.map((tag) => tag.tagContent) || INIT_MEETING_REPORT_FORM_DATA.tagList,
        meeting: {
          meetingTime: [
            report.meeting?.metStartDt
              ? new Date(report.meeting.metStartDt)
              : INIT_MEETING_REPORT_FORM_DATA.meeting.meetingTime[0],
            report.meeting?.metEndDt
              ? new Date(report.meeting.metEndDt)
              : INIT_MEETING_REPORT_FORM_DATA.meeting.meetingTime[1],
          ],
          members: report.meeting?.members
            ? report.meeting.members.map((member) => ({
                userSid: member.userSid,
                empNm: member.empNm,
              }))
            : [],
          extrnList: report.meeting?.extrnList || [],
        },
      }
    : {
        ...INIT_MEETING_REPORT_FORM_DATA,
        docuTypeCd: DOCU_TYPE_CODE.MEETING,
      };
};

export const getWorkReportFormDefaultValues = (report?: IReport): TWorkReportFormData => {
  return report
    ? {
        docuTypeCd: report.docuTypeCd.code,
        title: report.title || INIT_WORK_REPORT_FORM_DATA.title,
        files: [] || INIT_WORK_REPORT_FORM_DATA.files,
        submitYn: report.submitYn || false,
        year: report.reportDt ? getYear(new Date(report.reportDt)) : null,
        month: report.reportDt ? getWeek(new Date(report.reportDt)).month : null,
        week: report.reportDt ? getWeek(report.reportDt).week : null,
        day: report.reportDt ? getDate(new Date(report.reportDt)) : null,
        contents: report.contents
          ? report.contents.map((content) => {
              if (content.projectSid === null) {
                content.project = {
                  projectNm: '기타',
                } as IProject;
                content.projectSid = 0;
              }
              if (content.issueContent === null) {
                content.issueContent = '';
              }
              return {
                project: content.project,
                projectSid: content.projectSid,
                content: content.content,
                content2: content.content2,
                issueContent: content.issueContent,
              };
            })
          : [],
      }
    : {
        ...INIT_WORK_REPORT_FORM_DATA,
        docuTypeCd: DEFAULT_WORK_DOCU_CODE,
      };
};

export const getReportTemplateFormDefaultValues = (
  reportTemplate?: IReportTemplate,
): TReportTemplateFormData => {
  return reportTemplate
    ? {
        docuTypeCd: reportTemplate.docuTypeCd.code,
        tmplNm: reportTemplate.tmplNm || INIT_REPORT_TEMPLATE_FORM_DATA.tmplNm,
        descr: reportTemplate.descr || INIT_REPORT_TEMPLATE_FORM_DATA.descr,
        title: reportTemplate.title || INIT_REPORT_TEMPLATE_FORM_DATA.title,
        body: reportTemplate.body || INIT_REPORT_TEMPLATE_FORM_DATA.body,
        useYn: reportTemplate.useYn,
      }
    : INIT_REPORT_TEMPLATE_FORM_DATA;
};

export const getClientFormDefaultValues = (client?: IClient): TClientFormData => {
  return client
    ? {
        company: client.company || INIT_CLIENT_FORM_DATA.company,
        divisionCd: client.divisionCd?.code || INIT_CLIENT_FORM_DATA.divisionCd,
        gradeCd: client.gradeCd?.code || INIT_CLIENT_FORM_DATA.gradeCd,
        progressCd: client.progressCd?.code || INIT_CLIENT_FORM_DATA.progressCd,
        sales: client.sales || INIT_CLIENT_FORM_DATA.sales,
        empCnt: client.empCnt || INIT_CLIENT_FORM_DATA.empCnt,
        businessNum: client.businessNum || INIT_CLIENT_FORM_DATA.businessNum,
        telNo: client.telNo || INIT_CLIENT_FORM_DATA.telNo,
        faxNo: client.faxNo || INIT_CLIENT_FORM_DATA.faxNo,
        webSite: client.webSite || INIT_CLIENT_FORM_DATA.webSite,
        addr: client.addr || INIT_CLIENT_FORM_DATA.addr,
        addrDetail: client.addrDetail || INIT_CLIENT_FORM_DATA.addrDetail,
        compMemo: client.compMemo || INIT_CLIENT_FORM_DATA.compMemo,
      }
    : INIT_CLIENT_FORM_DATA;
};

export const getProjectFormDefaultValues = (project?: IProject): TProjectFormData => {
  return project
    ? {
        severityCd: project.severityCd || INIT_PROJECT_FORM_DATA.severityCd,
        govtYn: project.govtYn || INIT_PROJECT_FORM_DATA.govtYn,
        progYn: project.progYn || INIT_PROJECT_FORM_DATA.progYn,
        completeYn: project.completeYn || INIT_PROJECT_FORM_DATA.completeYn,
        bo: project.bo || INIT_PROJECT_FORM_DATA.bo,
        pmList: project.pmList || INIT_PROJECT_FORM_DATA.pmList,
        deprtCd: project.deprtCd || INIT_PROJECT_FORM_DATA.deprtCd,
        projectTypeCd: project.projectTypeCd?.code || INIT_PROJECT_FORM_DATA.projectTypeCd,
        projectNm: project.projectNm || INIT_PROJECT_FORM_DATA.projectNm,
        customerCmpy: project.customerCmpy || INIT_PROJECT_FORM_DATA.customerCmpy,
        contractCmpy: project.contractCmpy || INIT_PROJECT_FORM_DATA.contractCmpy,
        startDay: project.startDay || INIT_PROJECT_FORM_DATA.startDay,
        endDay: project.endDay || INIT_PROJECT_FORM_DATA.endDay,
        mngList: project.mngList || INIT_PROJECT_FORM_DATA.mngList,
        projectMngId: project.projectMngId || INIT_PROJECT_FORM_DATA.projectMngId,
      }
    : INIT_PROJECT_FORM_DATA;
};

export const getWbsFormDefaultValues = (wbs?: IWbs): TWbsFormData => {
  return wbs
    ? {
        projectSid: wbs.projectSid || INIT_WBS_FORM_DATA.projectSid,
        title: wbs.title || INIT_WBS_FORM_DATA.title,
        startDt: wbs.startDt || INIT_WBS_FORM_DATA.startDt,
        endDt: wbs.endDt || INIT_WBS_FORM_DATA.endDt,
      }
    : INIT_WBS_FORM_DATA;
};
export const getWbsTaskFormDefaultValues = (task?: IWbsTask): TWbsTaskFormData => {
  return task
    ? {
        wbsSid: task.wbsSid || INIT_WBS_TASK_FORM_DATA.wbsSid,
        taskPsid: task.taskPsid || INIT_WBS_TASK_FORM_DATA.taskPsid,
        taskGroup: task.taskGroup || INIT_WBS_TASK_FORM_DATA.taskGroup,
        title: task.title || INIT_WBS_TASK_FORM_DATA.title,
        subTitle: task.subTitle || INIT_WBS_TASK_FORM_DATA.subTitle,
        memo: task.memo || INIT_WBS_TASK_FORM_DATA.memo,
        seq: task.seq || INIT_WBS_TASK_FORM_DATA.seq,
        progress: task.progress || INIT_WBS_TASK_FORM_DATA.progress,
        startDt: task.startDt || INIT_WBS_TASK_FORM_DATA.startDt,
        endDt: task.endDt || INIT_WBS_TASK_FORM_DATA.endDt,
        taskOrder: task.taskOrder || INIT_WBS_TASK_FORM_DATA.taskOrder,
        emp: task.emp || INIT_WBS_TASK_FORM_DATA.emp,
        empList: task.empList || INIT_WBS_TASK_FORM_DATA.empList,

        fontSize: task.fontSize || INIT_WBS_TASK_FORM_DATA.fontSize,
        fontColor: task.fontColor || INIT_WBS_TASK_FORM_DATA.fontColor,
        bgColor: task.bgColor || INIT_WBS_TASK_FORM_DATA.bgColor,
        indent: task.indent || INIT_WBS_TASK_FORM_DATA.indent,
        boldYn: task.boldYn || INIT_WBS_TASK_FORM_DATA.boldYn,
      }
    : INIT_WBS_TASK_FORM_DATA;
};
export const getWbsItemFormDefaultValues = (item?: IWbsItem): TWbsItemFormData => {
  return item
    ? {
        taskSid: item.taskSid || INIT_WBS_ITEM_FORM_DATA.taskSid,
        color: item.color || INIT_WBS_ITEM_FORM_DATA.color,
        title: item.title || INIT_WBS_ITEM_FORM_DATA.title,
        memo: item.memo || INIT_WBS_ITEM_FORM_DATA.memo,
        priority: item.priority || INIT_WBS_ITEM_FORM_DATA.priority,
        progress: item.progress || INIT_WBS_ITEM_FORM_DATA.progress,
        startDt: item.startDt || INIT_WBS_ITEM_FORM_DATA.startDt,
        endDt: item.endDt || INIT_WBS_ITEM_FORM_DATA.endDt,
        emp: item.emp || INIT_WBS_ITEM_FORM_DATA.emp,
        empList: item.empList || INIT_WBS_ITEM_FORM_DATA.empList,
        userSid: item.userSid || INIT_WBS_ITEM_FORM_DATA.userSid,
        itemLinkList: item.itemLinkList || INIT_WBS_ITEM_FORM_DATA.itemLinkList,
      }
    : INIT_WBS_ITEM_FORM_DATA;
};
export const getEquipmentFormDefaultValues = (item?: IEquipment): TEquipmentFormData => {
  return item
    ? {
        equipmentSid: item.equipmentSid || INIT_EQUIPMENT_FORM_DATA.equipmentSid,
        inventoryId: item.inventoryId || INIT_EQUIPMENT_FORM_DATA.inventoryId,
        invenTypeCd: item.invenTypeCd || INIT_EQUIPMENT_FORM_DATA.invenTypeCd,
        manufacturerCd: item.manufacturerCd || INIT_EQUIPMENT_FORM_DATA.manufacturerCd,
        modelName: item.modelName || INIT_EQUIPMENT_FORM_DATA.modelName,
        modelSn: item.modelSn || INIT_EQUIPMENT_FORM_DATA.modelSn,
        purchaseTypeCd: item.purchaseTypeCd || INIT_EQUIPMENT_FORM_DATA.purchaseTypeCd,
        useStatusCd: item.useStatusCd || INIT_EQUIPMENT_FORM_DATA.useStatusCd,
        purchaseDt: item.purchaseDt || INIT_EQUIPMENT_FORM_DATA.purchaseDt,
        warrantyDt: item.warrantyDt || INIT_EQUIPMENT_FORM_DATA.warrantyDt,
        delYn: item.delYn || INIT_EQUIPMENT_FORM_DATA.delYn,
        useDeptSid: item.useDeptSid || INIT_EQUIPMENT_FORM_DATA.useDeptSid,
        userSid: item.userSid || INIT_EQUIPMENT_FORM_DATA.userSid,
        useStartDt: item.useStartDt || INIT_EQUIPMENT_FORM_DATA.useStartDt,
        useUser: item.useUser || INIT_EQUIPMENT_FORM_DATA.useUser,
        memo: item.memo || INIT_EQUIPMENT_FORM_DATA.memo,
        reason: item.reason || INIT_EQUIPMENT_FORM_DATA.reason,
        histories: item.histories || INIT_EQUIPMENT_FORM_DATA.histories,
        files: item.files || INIT_EQUIPMENT_FORM_DATA.files,
        useEmp: item.useUser?.userSid || INIT_EQUIPMENT_FORM_DATA.useUser?.userSid,
      }
    : INIT_EQUIPMENT_FORM_DATA;
};

export const getVmFormDefaultValues = (item?: IVm): TVmFormData => {
  return item
    ? {
        vmSid: item.vmSid || INIT_VM_FORM_DATA.vmSid,
        serverSid: item.serverSid || INIT_VM_FORM_DATA.serverSid,
        vmName: item.vmName || INIT_VM_FORM_DATA.vmName,
        vmIp: item.vmIp || INIT_VM_FORM_DATA.vmIp,
        useStatusCd: item.useStatusCd || INIT_VM_FORM_DATA.useStatusCd,
        memo: item.memo || INIT_VM_FORM_DATA.memo,
        accId: item.accId || INIT_VM_FORM_DATA.accId,
        accPw: item.accPw || INIT_VM_FORM_DATA.accPw,
        url: item.url || INIT_VM_FORM_DATA.url,
        mngDeptSid: item.mngDeptSid || INIT_VM_FORM_DATA.mngDeptSid,
      }
    : INIT_VM_FORM_DATA;
};

export const getServerFormDefaultValues = (item?: IServer): TServerFormData => {
  return item
    ? {
        serverSid: item.serverSid || INIT_SERVER_FORM_DATA.serverSid,
        files: item.files || INIT_SERVER_FORM_DATA.files,
        name: item.name || INIT_SERVER_FORM_DATA.name,
        loc: item.loc || INIT_SERVER_FORM_DATA.loc,
        purpose: item.purpose || INIT_SERVER_FORM_DATA.purpose,
        hostName: item.hostName || INIT_SERVER_FORM_DATA.hostName,
        localIp: item.localIp || INIT_SERVER_FORM_DATA.localIp,
        inventoryNo: item.inventoryNo || INIT_SERVER_FORM_DATA.inventoryNo,
        modelName: item.modelName || INIT_SERVER_FORM_DATA.modelName,
        spec: item.spec || INIT_SERVER_FORM_DATA.spec,
        manufacturerCd: item.manufacturerCd || INIT_SERVER_FORM_DATA.manufacturerCd,
        serialNo: item.serialNo || INIT_SERVER_FORM_DATA.serialNo,
        useStatusCd: item.useStatusCd || INIT_SERVER_FORM_DATA.useStatusCd,
        installDate: item.installDate || INIT_SERVER_FORM_DATA.installDate,
        installFloor: item.installFloor || INIT_SERVER_FORM_DATA.installFloor,
        surfacePos: item.surfacePos || INIT_SERVER_FORM_DATA.surfacePos,
        lowerU: item.lowerU || INIT_SERVER_FORM_DATA.lowerU,
        upperU: item.upperU || INIT_SERVER_FORM_DATA.upperU,
        mainternenceMng: item.mainternenceMng || INIT_SERVER_FORM_DATA.mainternenceMng,
        serviceMng: item.serviceMng || INIT_SERVER_FORM_DATA.serviceMng,
        purposeCd: item.purposeCd || INIT_SERVER_FORM_DATA.purposeCd,
        inventoryTypeCd: item.inventoryTypeCd || INIT_SERVER_FORM_DATA.inventoryTypeCd,
        router: item.router || INIT_SERVER_FORM_DATA.router,
        forwardIp: item.forwardIp || INIT_SERVER_FORM_DATA.forwardIp,
        port1Ip: item.port1Ip || INIT_SERVER_FORM_DATA.port1Ip,
        port2Ip: item.port2Ip || INIT_SERVER_FORM_DATA.port2Ip,
        port3Ip: item.port3Ip || INIT_SERVER_FORM_DATA.port3Ip,
        port4Ip: item.port4Ip || INIT_SERVER_FORM_DATA.port4Ip,
        memo: item.memo || INIT_SERVER_FORM_DATA.memo,
        accId: item.accId || INIT_SERVER_FORM_DATA.accId,
        accPw: item.accPw || INIT_SERVER_FORM_DATA.accPw,
        mngDeptSid: item.mngDeptSid || INIT_SERVER_FORM_DATA.mngDeptSid,
        delYn: item.delYn || INIT_SERVER_FORM_DATA.delYn,
      }
    : INIT_SERVER_FORM_DATA;
};

export const getDbDataDefaultValues = (collectionName: string): DBData => {
  return {
    id: '',
    pw: '',
    auth_lv: 'All',
    // 연동 주기
    sync_frequency: 'Monthly',
    // 연동 일/요일, 연동 시간
    sync_timing: '1,00:00',
    // 연동 시작 일시, 연동 시작 시간
    sync_start_time: new Date().toString(),
    vdb: collectionName,
  };
};

export const getNasServerFormDefaultValues = (collectionName: string): Nas => {
  return {
    nas_id: 0,
    nas_ip: '',
    nas_port: '',
    nas_share_list: [],
    expt_dir_list: [],
    ...getDbDataDefaultValues(collectionName),
  };
};

export const getRagParameterFormInitValues = (): RagParam => {
  return {
    chat: {
      llm_model: '',
      top_p: 0,
      temperature: 0,
      frequency_penalty: 0,
      maximum_tokens: 0,
    },
    rag: {
      embedding_model: '',
      similarity_algorithm: '',
      threshold: 0,
      chunk_size: 0,
      maximum_tokens: 0,
    },
  };
};

export const getRagParameterFormDefaultValues = (ragParam: RagParam): RagParameterFormData => {
  const { chat, rag } = ragParam;
  return {
    llm_model: chat.llm_model || '',
    top_p: chat.top_p || 0,
    temperature: chat.temperature || 0,
    frequency_penalty: chat.frequency_penalty || 0,
    chat_maximum_tokens: chat.maximum_tokens || 0,
    embedding_model: rag.embedding_model || '',
    similarity_algorithm: rag.similarity_algorithm || '',
    threshold: rag.threshold || 0,
    chunk_size: rag.chunk_size || 0,
    rag_maximum_tokens: rag.maximum_tokens || 0,
  };
};
