import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import CompactLayout from 'src/layouts/compact';
import DashboardLayout from 'src/layouts/dashboard/DashboardLayout';
import OrganizationLayoutImage from 'src/screens/management/organization/organization-layout-image/OrganizationLayoutImage';

// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';

// screens
import {
  ChatbotFAQManagement,
  ChatbotFeedbackManagement,
  ClientManagement,
  CreateReport,
  DbSync,
  DeptManagement,
  EditReport,
  EmployeeManagement,
  EquipmentManagement,
  EquipmentStatistics,
  GroupManagement,
  HeadquarterReportManagement,
  Login,
  MeetingManagement,
  MenuManagement,
  NasServerSync,
  OrganizationChart,
  Page403,
  Page404,
  ParameterManagement,
  ProjectManagement,
  ProjectStatistics,
  ReportAttachment,
  ReportManagement,
  ReportTemplate,
  ResetPassword,
  ServerManagement,
  TeamMemberReportManagement,
  TeamReportManagement,
  VectorDBSync,
  VmManagement,
  WebCrawlingSync,
} from './elements';
import { PATH_AUTH, PATH_PAGE } from './paths';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: PATH_AUTH.root,
      element: <Navigate to={PATH_PAGE.management.report.root} />,
    },
    {
      path: PATH_AUTH.login,
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    {
      element: <CompactLayout />,
      children: [
        {
          path: PATH_AUTH.resetPassword,
          element: <ResetPassword />,
        },
        {
          path: PATH_PAGE.page404,
          element: <Page404 />,
        },
        {
          path: PATH_PAGE.page403,
          element: <Page403 />,
        },
      ],
    },
    {
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: PATH_PAGE.adminManagement.menu,
          element: <MenuManagement />,
        },
        {
          path: PATH_PAGE.adminManagement.group,
          element: <GroupManagement />,
        },
        {
          path: PATH_PAGE.adminManagement.employee,
          element: <EmployeeManagement />,
        },
        {
          path: PATH_PAGE.adminManagement.dept,
          element: <DeptManagement />,
        },
        {
          path: PATH_PAGE.adminManagement.client,
          element: <ClientManagement />,
        },
        {
          path: PATH_PAGE.adminManagement.project,
          element: <ProjectManagement />,
        },
        {
          path: PATH_PAGE.adminManagement.projectStatistics,
          element: <ProjectStatistics />,
        },
        {
          path: PATH_PAGE.adminManagement.chatbot.faq,
          element: <ChatbotFAQManagement />,
        },
        {
          path: PATH_PAGE.adminManagement.chatbot.feedback,
          element: <ChatbotFeedbackManagement />,
        },
        {
          path: PATH_PAGE.adminManagement.chatbot.systemSettings.vectorDbSync,
          element: <VectorDBSync />,
        },
        {
          path: PATH_PAGE.adminManagement.chatbot.systemSettings.nasServerSync,
          element: <NasServerSync />,
        },
        {
          path: PATH_PAGE.adminManagement.chatbot.systemSettings.databaseSync,
          element: <DbSync />,
        },
        {
          path: PATH_PAGE.adminManagement.chatbot.systemSettings.webCrawling,
          element: <WebCrawlingSync />,
        },
        {
          path: PATH_PAGE.adminManagement.chatbot.systemSettings.parameterManagement,
          element: <ParameterManagement />,
        },
        {
          path: PATH_PAGE.management.organization.root,
          element: <OrganizationChart />,
        },
        {
          path: PATH_PAGE.management.organization.layout,
          element: <OrganizationLayoutImage />,
        },
        {
          path: PATH_PAGE.management.report.root,
          element: <ReportManagement />,
        },
        {
          path: PATH_PAGE.management.report.team,
          element: <TeamReportManagement />,
        },
        {
          path: PATH_PAGE.management.report.teamMember,
          element: <TeamMemberReportManagement />,
        },
        {
          path: PATH_PAGE.management.report.headquarter,
          element: <HeadquarterReportManagement />,
        },
        {
          path: PATH_PAGE.management.report.meeting,
          element: <MeetingManagement />,
        },
        {
          path: PATH_PAGE.management.report.template,
          element: <ReportTemplate />,
        },
        {
          path: PATH_PAGE.management.report.attachment,
          element: <ReportAttachment />,
        },
        {
          path: PATH_PAGE.management.report.create,
          element: <CreateReport />,
        },
        {
          path: `${PATH_PAGE.management.report.edit}/:reportSid`,
          element: <EditReport />,
        },
        {
          path: PATH_PAGE.management.inventory.equipment,
          element: <EquipmentManagement />,
        },
        {
          path: PATH_PAGE.management.inventory.equipmentStatistics,
          element: <EquipmentStatistics />,
        },
        {
          path: PATH_PAGE.management.inventory.server,
          element: <ServerManagement />,
        },
        {
          path: PATH_PAGE.management.inventory.vm,
          element: <VmManagement />,
        },
      ],
    },
    { path: '*', element: <Navigate to={PATH_PAGE.page404} replace /> },
  ]);
}
