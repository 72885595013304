import { Button, DialogActions, DialogContent } from '@mui/material';
import { IReport } from 'src/@types/apiResponseTypes';
import DialogAnimate, { Props as DialogProps } from 'src/components/animate/DialogAnimate';
import DialogTitleWithCloseButton from 'src/components/dialog/DialogTitleWithCloseButton';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { LOCAL_STORAGE_KEY } from 'src/static/constants';

import PostTypeWorkReportItem from './PostTypeWorkReportItem';
import TableTypeWorkReportItem from './TableTypeWorkReportItem';
/**
 * ## WorkReportViewModal 설명
 * - 업무 보고서 상세보기 모달
 */

export interface IWorkReportViewModalProps extends DialogProps {
  report?: IReport;
}

function WorkReportViewModal({ report, open, onClose }: IWorkReportViewModalProps) {
  const [viewType] = useLocalStorage(LOCAL_STORAGE_KEY.WORK_REPORT_VIEW_TYPE, 'post');
  if (!report) return null;

  return (
    <DialogAnimate fullWidth maxWidth="xl" open={open} onClose={onClose} scroll="paper">
      <DialogTitleWithCloseButton title={report?.title || ''} onClose={onClose} />
      {/* Form */}
      <DialogContent dividers sx={{ padding: 3 }}>
        {viewType === 'post' ? (
          <PostTypeWorkReportItem report={report} />
        ) : (
          <TableTypeWorkReportItem report={report} isMinimizeColumn />
        )}
      </DialogContent>
      {/* Submit Button */}
      <DialogActions>
        <Button color="secondary" variant="contained" onClick={onClose}>
          {'닫기'}
        </Button>
      </DialogActions>
    </DialogAnimate>
  );
}

export default WorkReportViewModal;
