import { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { Stack } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { departmentApi } from 'src/api';
import { useStores } from 'src/models';

// 사용자 기본 정보 컴포넌트
const BasicUserInfo = observer(() => {
  const { authStore } = useStores();
  const { user } = authStore;
  const [dept, setDept] = useState('');

  useEffect(() => {
    (async () => {
      const result = await departmentApi.getDepartments();
      if (result) {
        const res = result.data.find((dept) => dept.deptSid === user?.deptSid);
        setDept(res?.deptNm || '');
      }
    })();
  }, []);

  return (
    <>
      <TextField defaultValue={user?.loginId || ''} label="아이디" disabled />
      <Stack
        direction={'row'}
        spacing={2}
        sx={{
          '& > *': {
            flex: 1,
          },
        }}
      >
        <TextField defaultValue={user?.userNm || ''} label="이름" disabled />
        <TextField defaultValue={dept} label="부서" disabled />
        <TextField defaultValue={user?.empJobrespCd?.value || ''} label="직책" disabled />
        <TextField defaultValue={user?.empPosCd?.value || ''} label="직급" disabled />
      </Stack>
    </>
  );
});

export default BasicUserInfo;
