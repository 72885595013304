const SmileMessageIcon = () => (
  <svg width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.125 6.75H7.1325M10.875 6.75H10.8825M13.5 3C14.0967 3 14.669 3.23705 15.091 3.65901C15.5129 4.08097 15.75 4.65326 15.75 5.25V11.25C15.75 11.8467 15.5129 12.419 15.091 12.841C14.669 13.2629 14.0967 13.5 13.5 13.5H9.75L6 15.75V13.5H4.5C3.90326 13.5 3.33097 13.2629 2.90901 12.841C2.48705 12.419 2.25 11.8467 2.25 11.25V5.25C2.25 4.65326 2.48705 4.08097 2.90901 3.65901C3.33097 3.23705 3.90326 3 4.5 3H13.5Z"
      stroke="#3280CE"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.125 9.75C7.36941 9.99945 7.66114 10.1976 7.9831 10.3329C8.30505 10.4682 8.65077 10.5379 9 10.5379C9.34923 10.5379 9.69495 10.4682 10.0169 10.3329C10.3389 10.1976 10.6306 9.99945 10.875 9.75"
      stroke="#3280CE"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const AddChatIconForMobile = () => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.7812 7.53125C13.7812 7.32405 13.6989 7.12534 13.5524 6.97882C13.4059 6.83231 13.2072 6.75 13 6.75C12.7928 6.75 12.5941 6.83231 12.4476 6.97882C12.3011 7.12534 12.2188 7.32405 12.2188 7.53125V12.2188H7.53125C7.32405 12.2188 7.12534 12.3011 6.97882 12.4476C6.83231 12.5941 6.75 12.7928 6.75 13C6.75 13.2072 6.83231 13.4059 6.97882 13.5524C7.12534 13.6989 7.32405 13.7812 7.53125 13.7812H12.2188V18.4688C12.2188 18.676 12.3011 18.8747 12.4476 19.0212C12.5941 19.1677 12.7928 19.25 13 19.25C13.2072 19.25 13.4059 19.1677 13.5524 19.0212C13.6989 18.8747 13.7812 18.676 13.7812 18.4688V13.7812H18.4688C18.676 13.7812 18.8747 13.6989 19.0212 13.5524C19.1677 13.4059 19.25 13.2072 19.25 13C19.25 12.7928 19.1677 12.5941 19.0212 12.4476C18.8747 12.3011 18.676 12.2188 18.4688 12.2188H13.7812V7.53125ZM13 0.5C6.09625 0.5 0.5 6.09625 0.5 13C0.5 15.155 1.04562 17.1844 2.00687 18.955L0.54375 24.1037C0.309375 24.9275 1.07062 25.6894 1.895 25.455L7.0425 23.9912C8.87086 24.9846 10.9192 25.5033 13 25.5C19.9037 25.5 25.5 19.9037 25.5 13C25.5 6.09625 19.9037 0.5 13 0.5ZM2.0625 13C2.0625 6.95937 6.95937 2.0625 13 2.0625C19.0406 2.0625 23.9375 6.95937 23.9375 13C23.9375 19.0406 19.0406 23.9375 13 23.9375C11.0063 23.9375 9.13937 23.405 7.53125 22.475C7.44063 22.4225 7.34037 22.3887 7.23643 22.3757C7.1325 22.3627 7.02701 22.3708 6.92625 22.3994L2.27625 23.7219L3.59875 19.07C3.62735 18.9694 3.63545 18.864 3.62257 18.7602C3.60968 18.6564 3.57608 18.5562 3.52375 18.4656C2.56299 16.8046 2.05885 14.9189 2.0625 13Z"
      fill="white"
    />
  </svg>
);

export { AddChatIconForMobile, SmileMessageIcon };
