import { List, Stack, useTheme } from '@mui/material';

import { useLocales } from '../../../locales';
import { NavSectionProps } from '../types';

import NavList from './NavList';
import { StyledSubheader } from './styles';
// ----------------------------------------------------------------------

export default function NavSectionVertical({ data, sx, ...other }: NavSectionProps) {
  const { translate } = useLocales();

  const theme = useTheme();

  return (
    <Stack sx={sx} {...other}>
      {data.map((group) => {
        const key = group.subheader || group.items[0].title;

        return (
          <List
            key={key}
            disablePadding
            sx={{ px: 2, ml: 1, mr: 1, borderBottom: '1px solid ' + theme.palette.divider }}
          >
            {group.subheader && (
              <StyledSubheader disableSticky>{`${translate(group.subheader)}`}</StyledSubheader>
            )}

            {group.items.map((list) => (
              <NavList
                key={list.title + list.path}
                data={list}
                depth={1}
                hasChild={!!list.children}
              />
            ))}
          </List>
        );
      })}
    </Stack>
  );
}
