import { CreateAxiosDefaults } from 'axios';

/**
 * Axios 인스턴스 기본 설정
 */
export const DEFAULT_AXIOS_CONFIG: CreateAxiosDefaults = {
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  timeout: 30000,
};

/**
 * Stream 방식으로 오는 챗봇 메시지에 대한 Axios 인스턴스 기본 설정
 */
export const CHATBOT_AXIOS_CONFIG: CreateAxiosDefaults = {
  baseURL: process.env.REACT_APP_CHATBOT_URL,
  // TODO: withCredentials: true/false 정하기
  // withCredentials: true,
  timeout: 30000,
  headers: {},
};
