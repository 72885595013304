import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Divider, MenuItem, Typography } from '@mui/material';
// @mui
import { alpha } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';
import { IconButtonAnimate } from 'src/components/animate';
// components
import { CustomAvatar } from 'src/components/custom-avatar';
import MenuPopover from 'src/components/menu-popover';
import { useSnackbar } from 'src/components/snackbar';
import { useStores } from 'src/models';
// routes
import { PATH_AUTH } from 'src/routes/paths';
import getProfileImg from 'src/utils/getProfileImg';

const OPTIONS = [
  {
    label: 'Home',
    linkTo: '/',
  },
];

function AccountPopover({ onOpenMyInfoModal }: { onOpenMyInfoModal?: VoidFunction }) {
  const navigate = useNavigate();
  const rootStore = useStores();
  const { authStore } = rootStore;
  const { user, logout } = authStore;
  const { enqueueSnackbar } = useSnackbar();
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  // 팝오버 열기
  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  // 팝오버 닫기
  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  // 로그아웃 선택 시
  const handleLogout = async () => {
    try {
      logout();
      navigate(PATH_AUTH.login, { replace: true });
      handleClosePopover();
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  // 네비게이션 메뉴 선택 시
  const handleClickItem = (path: string) => {
    handleClosePopover();
    navigate(path);
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          p: 0,
          ...(openPopover && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <CustomAvatar
          src={getProfileImg(user?.profileImgUrl)}
          alt={user?.userNm}
          name={user?.userNm}
        />
      </IconButtonAnimate>
      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 200, p: 0 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.userNm}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.loginId}
          </Typography>
        </Box>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <Divider sx={{ borderStyle: 'dashed' }} />
        <MenuItem onClick={onOpenMyInfoModal} sx={{ m: 1 }}>
          내 정보
        </MenuItem>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          로그아웃
        </MenuItem>
      </MenuPopover>
    </>
  );
}

export default observer(AccountPopover);
