import { IMenu } from 'src/@types/apiResponseTypes';

import { TApiResponseData } from '../apiTypes';
import instance from '../axios';

/**
 * 메뉴 목록 조회
 */
export const getMenus = async () => {
  try {
    const url = '/v1/user/userGroupMenu';
    const response = await instance.get<TApiResponseData<IMenu[]>>(url);

    // 요청 성공 시 데이터 반환
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 권한 그룹별 메뉴 목록 조회
 * @param userGroupSid 권한 그룹 ID
 */
export const getMenusByUserGroupSid = async (userGroupSid: number) => {
  try {
    const url = `/v1/userGroupMenuRel/userGroup/${userGroupSid}`;
    const response = await instance.get<TApiResponseData<IMenu[]>>(url);

    // 요청 성공 시 데이터 반환
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 권한 그룹별 메뉴 목록 수정
 * @param userGroupSid 권한 그룹 ID
 * @param selectedMenuSids 선택한 메뉴 고유번호 목록
 */
export const updateMenusByUserGroupSid = async (
  userGroupSid: number,
  selectedMenuSids: number[],
) => {
  try {
    const url = `/v1/user/userGroupMenu/${userGroupSid}`;
    const payload = selectedMenuSids;
    const response = await instance.put<TApiResponseData<any>>(url, payload);
    if (response.data.resultCode === 'S') {
      return {
        data: true,
      };
    }
  } catch (error) {
    console.error(error);
  }
};
