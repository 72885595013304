import { TChangePasswordFormData } from 'src/@types';
import { IUserSnapshot } from 'src/models/user/User';

import { TApiResponseData } from '../apiTypes';
import instance from '../axios';

type TLoginResponseData = {
  loginResult: 'SUCCESS' | 'FAIL';
  errorMessage: null | string;
};

/**
 * 로그인
 */
export const login = async (userId: string, userPwd: string, rememberMe: boolean) => {
  try {
    const url = '/loginProcess';
    const payload = {
      userId,
      userPwd,
      'innerwave-remember-me': rememberMe,
    };
    const response = await instance.post<TApiResponseData<TLoginResponseData>>(url, payload, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    // api 호출 성공 시
    if (response.data.resultCode === 'S') {
      // 로그인 성공 시
      if (response.data.data?.loginResult === 'SUCCESS') {
        return {
          data: {
            success: true,
            message: '로그인 성공',
          },
        };
      }

      throw new Error(response.data.data?.errorMessage || '로그인 실패');
    }
    // api 호출 실패 시
    throw new Error('로그인 실패');
  } catch (error) {
    return {
      data: {
        success: false,
        message: error.message,
      },
    };
  }
};

/**
 * 로그아웃
 */
export const logout = async () => {
  try {
    const url = '/logout';

    const response = await instance.get<TApiResponseData<any>>(url);

    // 성공 시 로그인
    if (response.data.resultCode === 'S') {
      return {
        data: {
          success: true,
          message: '로그아웃 성공',
        },
      };
    }

    // api 호출 실패 시
    throw new Error('로그아웃 실패');
  } catch (error) {
    console.error(error);
    return {
      data: {
        success: false,
        message: '로그아웃 실패',
      },
    };
  }
};

/**
 * 사용자 정보 조회
 */
export const getUserInfo = async () => {
  try {
    const url = '/v1/myInfo';
    const response = await instance.get<TApiResponseData<IUserSnapshot>>(url);

    // 로그인
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        userInfo: response.data.data,
      };
    }

    // api 호출 실패 시
    throw new Error('사용자 정보 조회 실패');
  } catch (error) {
    console.error(error.message);
  }
};

/**
 * 비밀번호 변경
 * @param loginId 로그인 아이디
 * @param changePwdInfo 변경할 비밀번호 정보
 */
export const changePassword = async (loginId: string, changePwdInfo: TChangePasswordFormData) => {
  try {
    const url = '/v1/myInfo';
    const payload = {
      loginId,
      ...changePwdInfo,
    };
    const response = await instance.put<TApiResponseData<any>>(url, payload);

    // 비밀번호 변경 성공 시
    if (response.data.resultCode === 'S') {
      return {
        data: {
          success: true,
          message: '비밀번호 변경 성공',
        },
      };
    }

    // 비밀번호 변경 실패 시
    throw new Error(
      response.data.resultCode === 'F' ? response.data.errorMessage : '비밀번호 변경 실패',
    );
  } catch (error) {
    console.error(error.message);
    return {
      data: {
        success: false,
        message: error.message,
      },
    };
  }
};
