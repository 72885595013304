import { IEquipmentSearchParams, TEquipmentFormData } from 'src/@types';
import { IAttachment, IEquipment, IEquipmentDashboard } from 'src/@types/apiResponseTypes';

import { TApiResponseData } from '../apiTypes';
import instance from '../axios';

const SUB_URL = '/v1/equipment';

/**
 * Equipment 목록 조회
 * @param params 검색 조건
 */
export const gets = async (params?: IEquipmentSearchParams) => {
  try {
    const url = SUB_URL;
    const response = await instance.get<TApiResponseData<IEquipment[]>>(url, {
      params,
    });

    // 성공 시 데이터 반환
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
        pagination: response.data.pagination,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * Equipment 단건 조회
 * @param equipmentSid Equipment 고유번호
 */
export const get = async (equipmentSid: number) => {
  try {
    const url = `${SUB_URL}/${equipmentSid}`;
    const response = await instance.get<TApiResponseData<IEquipment>>(url);

    // 성공 시 데이터 반환
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * Equipment 등록
 * @param info Equipment 등록 정보: TEquipmentFormData
 */
export const create = async (info: TEquipmentFormData) => {
  try {
    const url = SUB_URL;

    const form: any = new FormData();

    // 파일 추가
    info.files?.forEach((file) => {
      form.append('files', file);
    });

    const copied: any = { ...info };
    const excludes = ['equipmentSid', 'useDeptSid', 'useUser', 'useEmp', 'histories'];
    Object.keys(info).forEach((key) => {
      if (!excludes.includes(key)) {
        return form.append(key, copied[key]);
      }
    });

    const response = await instance.post<TApiResponseData<IEquipment>>(url, form, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    // 성공 시 데이터 반환
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * Equipment 정보 수정
 * @param equipmentSid Equipment 고유번호
 * @param info Equipment 등록 정보: TEquipmentFormData
 */
export const update = async (equipment: IEquipment, info: TEquipmentFormData) => {
  try {
    const url = `${SUB_URL}/${equipment.equipmentSid}`;

    const form: any = new FormData();

    // 파일 추가
    info.files?.forEach((file) => {
      form.append('files', file);
    });

    const copied: any = { ...info };
    const excludes = [
      'equipmentSid',
      // 'useDeptSid',
      'useUser',
      // 'inventoryId',
      'useEmp',
      'histories',
      'equipmentFiles',
      'regDt',
      'udpDt',
    ];
    Object.keys(info).forEach((key) => {
      if (!excludes.includes(key)) {
        return form.append(key, copied[key]);
      }
    });

    const response = await instance.put<TApiResponseData<IEquipment>>(url, form, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    // 성공 시 데이터 반환
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * Equipment 삭제
 * @param equipmentSid Equipment 고유번호
 */
export const remove = async (equipmentSid: number) => {
  try {
    const url = `${SUB_URL}/${equipmentSid}`;
    const response = await instance.delete<TApiResponseData<boolean>>(url);

    // 성공 시 데이터 반환
    if (response.data.resultCode === 'S' && response.data.data) {
      // 삭제 성공 시 true 반환
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 보고서 첨부파일 삭제
 * attachment: IAttachment
 */
export const deleteFile = async (attachment: IAttachment) => {
  try {
    const url = `${SUB_URL}/${attachment.equipmentSid}/file/${attachment.fileSid}`;
    const response = await instance.delete<TApiResponseData<any>>(url);

    if (response.data.resultCode === 'S') {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 보고서 첨부파일 다운로드
 * attachment: IAttachment
 */
export const downloadFile = async (attachment: IAttachment) => {
  try {
    const url = `${SUB_URL}/${attachment.equipmentSid}/file/${attachment.fileSid}/download`;

    const response = await instance.get(url, {
      headers: { 'Content-Type': 'application/octet-stream' },
      responseType: 'blob',
    });

    if (response.data) {
      // Blob 데이터를 이용하여 클라이언트에서 파일 생성
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', attachment.fileNm); // 다운로드할 파일명 지정
      document.body.appendChild(link);
      link.click();

      // 정리
      window.URL.revokeObjectURL(url); // 사용이 끝난 후 URL 해제
      link.remove(); // 생성한 링크 요소 제거
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 장비 현황 조회
 */
export const getEquipmentDashboard = async () => {
  try {
    const url = `${SUB_URL}/dashboard`;

    const response = await instance.get<TApiResponseData<IEquipmentDashboard>>(url);

    // 성공 시 데이터 반환
    if (response.data.resultCode === 'S') {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};
