import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { BoxProps, Link } from '@mui/material';
// @mui
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/styles';
import LogoImageSrc from 'src/assets/images/innerwave_logo.png';
import SmallLogoImageSrc from 'src/assets/images/innerwave_logo_small.png';
import LogoWhiteImageSrc from 'src/assets/images/innerwave_logo_white.png';
import { useSettings } from 'src/hooks/useSettings';

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const theme = useTheme();
    const { themeLayout } = useSettings();
    const isMini = themeLayout === 'mini';
    const isDarkMode = theme.palette.mode === 'dark';

    const renderLogoImage = () => {
      if (isMini) {
        return <SmallLogoImage src={SmallLogoImageSrc} alt="logo" />;
      }

      return <LogoImage src={isDarkMode ? LogoWhiteImageSrc : LogoImageSrc} alt="logo" />;
    };

    if (disabledLink) {
      return renderLogoImage();
    }

    return (
      <Link component={RouterLink} to="/" sx={{ display: 'contents' }}>
        {renderLogoImage()}
      </Link>
    );
  },
);

export default Logo;

const LogoImage = styled('img')(({ theme }) => ({
  margin: '0 auto',
  width: 140,
  height: 'auto',
}));

const SmallLogoImage = styled('img')(({ theme }) => ({
  margin: '0 auto',
  width: 40,
  height: 'auto',
}));
