import SendIcon from '@mui/icons-material/Send';
import { Box, Button, Typography } from '@mui/material';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/FormProvider';

import { useMessageInput } from '../../hooks/useMessageInput';

interface MessageInputAreaProps {
  isAiProcessing: boolean;
  onSendMessageToAI: (response: string) => void;
}

function MessageInputArea(props: MessageInputAreaProps) {
  const { methods, inputMessage, MAX_CHARS, setIsComposing, onHandleSubmit, handleEnterKeyPress } =
    useMessageInput(props);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '12px 12px 16px',
        gap: 8,
      }}
    >
      <FormProvider
        methods={methods}
        onSubmit={methods.handleSubmit(onHandleSubmit)}
        sx={{
          border: '2px solid #0F85FB',
          borderRadius: '8px',
          padding: '20px',
        }}
      >
        <RHFTextField
          multiline // Shift + Enter 키 누르면 줄바꿈을 하게 해줌
          name="response"
          placeholder="궁금한 사항을 구체적으로 작성해주세요."
          variant="standard"
          inputProps={{ maxLength: MAX_CHARS }}
          inputRef={(textFieldInput) => textFieldInput && textFieldInput.focus()} // <TextField /> 가 렌더링 되었을 때 자동으로 텍스트 필드에 포커싱하기
          sx={{
            '& .MuiInputBase-root': {
              border: 'none',
              borderRadius: '8px',
              height: '180px', // 높이를 180px로 설정
              overflow: 'auto',
            },
            '& .MuiInputBase-input': {
              height: '100% !important', // 입력 영역이 전체 높이를 차지하도록 설정
              overflowY: 'auto !important', // 세로 스크롤 활성화
            },
            // --------------------------------------------------------------------------
            /** TextField의 기본적으로 있는 검은색 밑줄 제거 */
            '& .MuiInput-underline:before': {
              borderBottom: 'none',
            },
            '& .MuiInput-underline:after': {
              borderBottom: 'none',
            },
            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
              borderBottom: 'none',
            },
            // --------------------------------------------------------------------------
          }}
          onKeyDown={(event) => handleEnterKeyPress(event, methods.handleSubmit)}
          onCompositionStart={() => setIsComposing(true)}
          onCompositionEnd={() => setIsComposing(false)}
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
          <Typography variant="caption" sx={{ mt: 1, display: 'block' }}>
            {inputMessage.length} / {MAX_CHARS}
          </Typography>
          <Button
            variant="outlined"
            sx={{ borderRadius: '100px' }}
            startIcon={<SendIcon sx={{ fontSize: 13 }} />}
            disabled={inputMessage.length === 0 || inputMessage.length > MAX_CHARS}
            onClick={() => {
              onHandleSubmit({ response: methods.getValues().response });
            }}
          >
            보내기
          </Button>
        </Box>
      </FormProvider>
    </Box>
  );
}

export default MessageInputArea;
