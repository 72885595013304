import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import { CodeModel } from '../common-code/CommonCode';

/**
 * # SimpleUserModel
 * - 사용자 기본 정보 모델
 * - [userSid]: 직원 계정 아이디
 * - [loginId]: 로그인 아이디
 * - [userNm]: 사용자 이름
 */
const SimpleUserModelProps = {
  userSid: types.number,
  loginId: types.string,
  userNm: types.string,
};
export const SimpleUserModel = types.model('User').props(SimpleUserModelProps);

type TSimpleUserType = Instance<typeof SimpleUserModel>;
type TSimpleUserSnapshotType = SnapshotOut<typeof SimpleUserModel>;

export interface ISimpleUser extends TSimpleUserType {}
export type TSimpleUserTypeKey = keyof TSimpleUserSnapshotType & string;
export interface ISimpleUserSnapshot extends TSimpleUserSnapshotType {}

/**
 * # Menu
 * - 메뉴 정보 모델
 * - IMenu type과 동일
 */
const MenuModel = types.model('Menu').props({
  menuSid: types.number,
  menuNm: types.string,
  menuUri: types.string,
  menuTypeCd: types.maybeNull(CodeModel),
  menuPsid: types.maybeNull(types.number),
  ordr: types.number,
});

type TMenuType = Instance<typeof MenuModel>;
type TMenuSnapshotType = SnapshotOut<typeof MenuModel>;

export interface IMenu extends TMenuType {}
export type TMenuTypeKey = keyof TMenuSnapshotType & string;
export interface IMenuSnapshot extends TMenuSnapshotType {}

/**
 * # SimpleUserModel
 * - 사용자 정보 모델
 * - [userSid]: 사용자 고유번호
 * - [loginId]: 로그인 아이디
 * - [userNm]: 사용자 이름
 */
export const createSimpleUser = () =>
  types.optional(SimpleUserModel, {
    userSid: 0,
    loginId: '',
    userNm: '',
  } as TSimpleUserType);

/**
 * # UserModel
 * - 사용자 정보 모델
 * - [userSid]: 사용자 고유번호
 * - [loginId]: 로그인 아이디
 * - [userNm]: 사용자 이름
 * - [deptSid]: 부서 고유번호
 * - [profileImgUrl]: 프로필 이미지 URL
 * - [empPosCd]: 직급 코드
 * - [empJobrespCd]: 직책 코드
 * - [menuList]: 사용자별 nav 메뉴 리스트
 */
export const UserModel = types.model('User').props({
  ...SimpleUserModelProps,
  deptSid: types.number,
  empPosCd: types.maybeNull(CodeModel),
  empJobrespCd: types.maybeNull(CodeModel),
  profileImgUrl: types.maybeNull(types.string),
  menuList: types.array(MenuModel),
});

type TUserType = Instance<typeof UserModel>;
type TUserSnapshotType = SnapshotOut<typeof UserModel>;

export interface IUser extends TUserType {}
export type TUserTypeKey = keyof TUserSnapshotType & string;
export interface IUserSnapshot extends TUserSnapshotType {}

export const createUser = () =>
  types.optional(UserModel, {
    userSid: 0,
    loginId: '',
    userNm: '',
    deptSid: 0,
  } as TUserType);
