import { IUserGroupSearchParams, TGroupFormData } from 'src/@types';
import { IUserGroup } from 'src/@types/apiResponseTypes';

import { TApiResponseData } from '../apiTypes';
import instance from '../axios';

const SUB_URL = '/v1/userGroup';

/**
 * 권한그룹 목록 조회
 * @param userGroupNm 권한그룹명
 */
export const getUserGroups = async (params?: IUserGroupSearchParams) => {
  try {
    const url = SUB_URL;
    const response = await instance.get<TApiResponseData<IUserGroup[]>>(url, {
      params,
    });

    // 성공 시 데이터 반환
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
        pagination: response.data.pagination,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 권한그룹 정보 등록
 * @param userGroupInfo 권한그룹 등록 정보: TGroupFormData
 */
export const createUserGroup = async (userGroupInfo: TGroupFormData) => {
  const url = SUB_URL;
  const payload = userGroupInfo;
  const response = await instance.post<TApiResponseData<IUserGroup>>(url, payload);

  // 성공 시 데이터 반환
  if (response.data.resultCode === 'S') {
    return {
      data: true,
    };
  }
};

/**
 * 권한그룹 정보 수정
 * @param userGroupSid 권한그룹 고유번호
 * @param userGroupInfo 권한그룹 등록 정보: TGroupFormData
 */
export const updateUserGroup = async (userGroupSid: number, userGroupInfo: TGroupFormData) => {
  const url = `${SUB_URL}/${userGroupSid}`;
  const payload = userGroupInfo;
  const response = await instance.put(url, payload);

  // 성공 시 데이터 반환
  if (response.data.resultCode === 'S') {
    return {
      data: true,
    };
  }
};

/**
 * 권한그룹 정보 삭제
 * @param userGroupSid 권한그룹 고유번호
 */
export const deleteUserGroup = async (userGroupSid: number) => {
  const url = `${SUB_URL}/${userGroupSid}`;
  const response = await instance.delete(url);

  // 성공 시 데이터 반환
  if (response.data.resultCode === 'S') {
    return {
      success: true,
    };
  }

  return {
    success: false,
    errorMessage: response.data.errorMessage,
  };
};

/**
 * 권한그룹명 중복 확인
 * @param userGroupNm 권한그룹명
 */
export const checkDuplicateUserGroupNm = async (userGroupNm: string) => {
  const url = `${SUB_URL}/userGroupNm/check?userGroupNm=${userGroupNm}`;
  const response = await instance.get<TApiResponseData<boolean>>(url);

  // 성공 시 데이터 반환
  if (response.data.resultCode === 'S') {
    return {
      data: response.data.data,
    };
  }
};
