import { Button, DialogActions, DialogContent } from '@mui/material';
import DialogAnimate, { Props as DialogProps } from 'src/components/animate/DialogAnimate';
import DialogTitleWithCloseButton from 'src/components/dialog';
import { useStores } from 'src/models';

import ChatbotFeedbackForm, { ChatbotFeedbackFormProps } from './ChatbotFeedbackForm';

export interface ChatbotFeedbackFormModalProps extends DialogProps, ChatbotFeedbackFormProps {
  onDelete?: (feedback_id: number) => Promise<void>;
}

function ChatbotFeedbackFormModal({
  open,
  formId,
  formType,
  feedback,
  onClose,
  onComplete,
  onDelete,
}: ChatbotFeedbackFormModalProps) {
  const { alertStore } = useStores();

  const formTypeText = () => {
    switch (formType) {
      case 'CREATE':
        return '의견 보내기';
      case 'UPDATE':
        return '피드백 수정';
      case 'READONLY':
        return '상세보기';
      default:
        return '';
    }
  };

  const handleDelete = async () => {
    if (!onDelete || !feedback?.feedback_id) return;

    alertStore.setOnConfirm(() => {
      onDelete(feedback.feedback_id);
    });

    // on confirm alert
    alertStore.setOnConfirm(() => {
      onDelete(feedback.feedback_id);
    });
    // open alert
    alertStore.setProps({
      open: true,
      title: '피드백 삭제',
      content: '해당 피드백을 삭제하시겠습니까?',
      category: 'error',
      hasCancelButton: true,
    });
  };
  return (
    <DialogAnimate fullWidth maxWidth="lg" open={open} scroll="paper" sx={{ zIndex: 9999 }}>
      <DialogTitleWithCloseButton title={formTypeText()} onClose={onClose} />
      {/* Form */}
      <DialogContent dividers>
        <ChatbotFeedbackForm
          formId={formId}
          formType={formType}
          feedback={feedback}
          readonly={formType === 'READONLY'}
          onComplete={onComplete}
        />
      </DialogContent>
      {formType !== 'READONLY' && (
        <DialogActions>
          {/* Delete Button */}
          {formType === 'UPDATE' && (
            <Button onClick={handleDelete} variant="contained" color="error">
              삭제
            </Button>
          )}
          {/* Submit Button */}
          <Button type="submit" variant="contained" form={formId}>
            {formType === 'CREATE' ? '등록' : '수정'}
          </Button>
        </DialogActions>
      )}
    </DialogAnimate>
  );
}

export default ChatbotFeedbackFormModal;
