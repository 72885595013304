import React, { useCallback, useEffect, useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  Paper,
  PaperProps,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { IReportComment } from 'src/@types/apiResponseTypes';
import { reportApi } from 'src/api';
import { useStores } from 'src/models';
import { fDateToNow } from 'src/utils/formatTime';

interface ReportCommentsProps extends PaperProps {
  reportSid: number;
  initComments?: IReportComment[];
}

const ReportComments = ({ reportSid, initComments, ...props }: ReportCommentsProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { alertStore, authStore } = useStores();
  const [comments, setComments] = useState<IReportComment[]>(initComments || []);
  const [text, setText] = useState<string>('');
  const [childText, setChildText] = useState<string>('');
  const [editText, setEditText] = useState<string>('');
  const [parentCommentId, setParentCommentId] = useState<number | null>(null);
  const [editingCommentId, setEditingCommentId] = useState<number | null>(null);
  // 중복 submit 방지를 위한 상태
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const getComments = useCallback(async () => {
    try {
      const result = await reportApi.getReportComments(reportSid);
      if (result) {
        setComments(result);
      }
    } catch (error) {
      console.error(error);
    }
  }, [reportSid]);

  useEffect(() => {
    if (initComments) return;
    // 댓글 목록 조회
    getComments();
  }, [initComments, getComments]);

  // 댓글 추가 함수
  const addComment = (
    comments: IReportComment[],
    newComment: IReportComment,
    parentCommentSid?: number,
  ): IReportComment[] => {
    return comments.map((c) => {
      if (c.commentSid === parentCommentSid) {
        return { ...c, reportComments: [...(c.reportComments || []), newComment] };
      }
      if (c.reportComments) {
        return {
          ...c,
          reportComments: addComment(c.reportComments || [], newComment, parentCommentSid),
        };
      }
      return c;
    });
  };

  // 댓글 등록
  const handleAddComment = async (comment: string, parentCommentSid?: number) => {
    if (isSubmitting) return;
    try {
      if (!comment.trim()) {
        enqueueSnackbar('댓글을 입력해주세요.', { variant: 'error' });
        return;
      }
      const result = await reportApi.createReportComment(reportSid, comment, parentCommentSid);
      if (result) {
        parentCommentSid
          ? setComments((prevComments) => addComment(prevComments, result, parentCommentSid))
          : setComments([...comments, result]);

        setText('');
        setChildText('');
        setParentCommentId(null);
        return;
      }

      enqueueSnackbar('댓글 등록에 실패했습니다.', { variant: 'error' });
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // 댓글 수정버튼 클릭 시
  const handleEditComment = (comment: IReportComment) => {
    setEditingCommentId(comment.commentSid);
    setEditText(comment.comment);
  };

  // 댓글 수정 함수
  const updateComment = (
    comments: IReportComment[],
    targetCommentSid: number,
    updatedComment: IReportComment,
  ): IReportComment[] => {
    return comments.map((c) => {
      if (c.commentSid === targetCommentSid) c = updatedComment;
      if (c.reportComments)
        c.reportComments = updateComment(c.reportComments, targetCommentSid, updatedComment);
      return c;
    });
  };

  // 댓글 수정
  const handleSaveEdit = async (targetComment: IReportComment) => {
    if (isSubmitting) return;
    try {
      if (!editText.trim()) {
        enqueueSnackbar('댓글을 입력해주세요.', { variant: 'error' });
        return;
      }
      const result = await reportApi.updateReportComment(
        reportSid,
        targetComment.commentSid,
        editText,
      );
      if (result) {
        setComments((prevComments) =>
          updateComment(prevComments, targetComment.commentSid, result),
        );
        setEditingCommentId(null);
        return;
      }

      enqueueSnackbar('댓글 수정에 실패했습니다.', { variant: 'error' });
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // 댓글 삭제 클릭 시
  const handleDeleteComment = (commentSid: number) => {
    setEditingCommentId(null);
    setEditText('');
    alertStore.setOnConfirm(() => deleteComment(commentSid));
    alertStore.setProps({
      category: 'error',
      open: true,
      title: '댓글 삭제',
      content: '댓글을 삭제하시겠습니까?',
      hasCancelButton: true,
    });
  };

  // 댓글 api 요청 후 댓글 삭제
  const deleteComment = async (commentSid: number) => {
    try {
      const result = await reportApi.deleteReportComment(reportSid, commentSid);
      if (result) {
        setComments((prevComments) => updateComment(prevComments, commentSid, result));
        return;
      }

      enqueueSnackbar('댓글 삭제에 실패했습니다.', { variant: 'error' });
    } catch (error) {
      console.error(error);
    }
  };

  // 댓글 목록 렌더링
  const renderComments = (comments: IReportComment[]) => {
    return comments.map((comment) => (
      <CommentList key={comment.commentSid} sx={{ paddingLeft: comment.commentDepth * 4 }}>
        <StyledListItem>
          <Typography variant="body2">
            {`${comment.regUser.userNm} - ${fDateToNow(comment.updDt)}`}
          </Typography>
          {editingCommentId === comment.commentSid ? (
            <FlexContainer>
              <TextField
                label="댓글 수정"
                variant="standard"
                value={editText}
                onChange={(e) => setEditText(e.target.value)}
                fullWidth
                onKeyUp={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    setIsSubmitting(true);
                    handleSaveEdit(comment);
                  }
                }}
              />
              <IconButton onClick={() => setEditingCommentId(null)}>
                <CancelIcon />
              </IconButton>
            </FlexContainer>
          ) : (
            <Typography
              variant="subtitle1"
              sx={{
                color: comment.delYn ? 'text.disabled' : 'text.primary',
              }}
            >
              {comment.comment}
            </Typography>
          )}
          <BottomContainer>
            {comment.regUser.userSid === authStore.user?.userSid && !comment.delYn && (
              <>
                <StyledButton size="small" onClick={() => handleEditComment(comment)}>
                  수정
                </StyledButton>
                <Divider orientation="vertical" variant="middle" flexItem />
                <StyledButton size="small" onClick={() => handleDeleteComment(comment.commentSid)}>
                  삭제
                </StyledButton>
                {comment.commentDepth < 1 && (
                  <Divider orientation="vertical" variant="middle" flexItem />
                )}
              </>
            )}
            {/* 1 depth까지만 답글을 달 수 있도록 한다. */}
            {comment.commentDepth < 1 && (
              <StyledButton size="small" onClick={() => setParentCommentId(comment.commentSid)}>
                답글
              </StyledButton>
            )}
          </BottomContainer>
          {
            // 답글 추가
            parentCommentId === comment.commentSid && (
              <FlexContainer>
                <TextField
                  label="답글"
                  variant="standard"
                  placeholder="답글 추가..."
                  value={childText}
                  onChange={(e) => setChildText(e.target.value)}
                  fullWidth
                  onKeyUp={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      e.preventDefault();
                      setIsSubmitting(true);
                      handleAddComment(childText, parentCommentId);
                    }
                  }}
                />
                <IconButton
                  onClick={() => {
                    setParentCommentId(null);
                    setChildText('');
                  }}
                >
                  <CancelIcon />
                </IconButton>
              </FlexContainer>
            )
          }
        </StyledListItem>
        {comment.reportComments && renderComments(comment.reportComments)}
      </CommentList>
    ));
  };

  return (
    <Container {...props}>
      <Typography variant="subtitle1" gutterBottom>
        댓글
      </Typography>
      <TextField
        label="댓글을 입력하세요."
        size={'small'}
        value={text}
        onChange={(e) => setText(e.target.value)}
        fullWidth
        type="text"
        onKeyUp={(e) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            setIsSubmitting(true);
            handleAddComment(text);
          }
        }}
      />
      <CommentsContainer>
        {comments.length <= 0 ? (
          <Typography variant="body2" textAlign="center" color={'#999'}>
            등록된 댓글이 없습니다.
          </Typography>
        ) : (
          renderComments(comments)
        )}
      </CommentsContainer>
    </Container>
  );
};

export default ReportComments;

const Container = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  border: '1px solid ' + theme.palette.divider,
}));

const CommentsContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  paddingTop: theme.spacing(2),
}));

const CommentList = styled(List)(({ theme }) => ({
  padding: 0,
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignContent: 'flex-start',
  alignItems: 'flex-start',
  gap: theme.spacing(0.2),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
}));

const FlexContainer = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
}));

const BottomContainer = styled('div')(({ theme }) => ({
  minHeight: 30,
  display: 'flex',
  alignItems: 'center',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(0.5),
  minWidth: 40,
}));
