import { useStores } from 'src/models';

// API 요청을 위한 함수 타입 정의
type RequestFunction = () => Promise<void>;

type ReturnType = {
  callApiWithLoading: (callback: RequestFunction) => Promise<void>;
};

function useCallApiWithLoading(): ReturnType {
  const { loadingStore } = useStores();

  const doSomethingBeforeRethrow = () => {
    // 에러 발생 시 실행할 작업
  };

  async function callApiWithLoading(callback: RequestFunction) {
    try {
      loadingStore.setLoading(true);
      // callback 함수 실행
      await callback();
    } catch (error) {
      doSomethingBeforeRethrow();
      // 에러 객체를 그대로 던지기
      throw error;
    } finally {
      loadingStore.setLoading(false);
    }
  }

  return {
    callApiWithLoading,
  };
}

export default useCallApiWithLoading;
