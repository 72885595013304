import { Dispatch, RefObject, SetStateAction, useCallback } from 'react';
import { maxBy, uniqBy } from 'lodash';
import { AIResponse, ChatList } from 'src/@types/apiResponseTypes';

import { ConversationType } from '../utils/types';

interface SidebarRef {
  chatListState: {
    chatList: ChatList[];
    setChatList: Dispatch<SetStateAction<ChatList[]>>;
  };
}

interface SendChatMessageProps {
  userSid: number;
  currentChatRoomId: string;
  sidebarRef: RefObject<SidebarRef>;
  setCurrentChatRoomId: (id: string) => void;
  setConversations: Dispatch<SetStateAction<ConversationType[] | undefined>>;
  setAiResponse: Dispatch<SetStateAction<AIResponse>>;
  setAiResponseStatus: Dispatch<
    SetStateAction<{
      isAITyping: boolean;
      error: string | null;
    }>
  >;
  setIsStreaming: Dispatch<SetStateAction<boolean>>;
  getChatList: (params: { user_id: number }) => Promise<ChatList[]>;
  AI_RESPONSE_DEFAULT_VALUE: AIResponse;
}

export function useSendChatMessage({
  userSid,
  currentChatRoomId,
  sidebarRef,
  setCurrentChatRoomId,
  setConversations,
  setAiResponse,
  setAiResponseStatus,
  setIsStreaming,
  getChatList,
  AI_RESPONSE_DEFAULT_VALUE,
}: SendChatMessageProps) {
  const sendMessageToAI = useCallback(
    async (user_input: string) => {
      const userPayload =
        currentChatRoomId.length === 0
          ? { user_id: userSid, user_input }
          : { user_id: userSid, user_input, chat_id: currentChatRoomId };

      const newConversation: ConversationType = {
        userMessage: userPayload,
        aiResponse: {
          response: '',
          chat_id: '',
          user_id: 0,
          ord: 0,
          reg_dt: '',
          is_faq: false,
        },
      };

      setConversations((prev) => {
        if (prev && prev.length > 0) {
          return [...prev, newConversation];
        } else {
          return [newConversation];
        }
      });

      try {
        const url = `/v1/chatbot/chats/response`;

        setAiResponseStatus((prev) => ({ ...prev, isAITyping: true }));
        const response = await fetch(process.env.REACT_APP_CHATBOT_URL + url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(userPayload),
        });

        const reader = response.body?.getReader();
        const decoder = new TextDecoder();
        let done = false;

        if (reader) {
          while (!done) {
            const { value, done: doneReading } = await reader.read();
            done = doneReading;
            setAiResponseStatus((prev) => ({ ...prev, isAITyping: false }));
            setIsStreaming(true);

            if (value) {
              const chunk = decoder.decode(value, { stream: true });
              const lines = chunk.split('\n');
              for (const line of lines) {
                if (line.startsWith('data: ')) {
                  try {
                    const parsedChunk = JSON.parse(line.slice(5)) as AIResponse;
                    setAiResponse(parsedChunk);

                    if (parsedChunk.complete) {
                      const {
                        chat_id,
                        chat_title,
                        response,
                        complete,
                        user_id,
                        is_faq,
                        ord,
                        reg_dt,
                      } = parsedChunk;
                      if (userSid) {
                        if (sidebarRef.current) {
                          const newChatList = await getChatList({ user_id: userSid });
                          const newChat = maxBy(newChatList, (chat) => new Date(chat.reg_dt));
                          if (newChat) {
                            sidebarRef.current.chatListState.setChatList((prevChatList) => {
                              return uniqBy([newChat, ...prevChatList], 'chat_id');
                            });
                          }
                        }
                        setCurrentChatRoomId(parsedChunk.chat_id);

                        setConversations((prev) =>
                          prev?.map((conv, index: number) =>
                            index === prev.length - 1
                              ? {
                                  ...conv,
                                  aiResponse: {
                                    user_id,
                                    chat_title,
                                    response,
                                    chat_id,
                                    ord,
                                    reg_dt,
                                    complete,
                                    is_faq,
                                  },
                                }
                              : conv,
                          ),
                        );
                        setAiResponse(AI_RESPONSE_DEFAULT_VALUE);
                      }
                    }
                  } catch (error) {
                    console.error('JSON Parsing error - ', error);
                  }
                }
              }
            }
          }
        }
      } catch (error) {
        console.error('Error fetching stream data: ', error);
      } finally {
        setAiResponseStatus((prev) => ({ ...prev, isAITyping: false }));
        setIsStreaming(false);
      }
    },
    [
      currentChatRoomId,
      userSid,
      sidebarRef,
      setCurrentChatRoomId,
      setConversations,
      setAiResponse,
      setAiResponseStatus,
      setIsStreaming,
      getChatList,
      AI_RESPONSE_DEFAULT_VALUE,
    ],
  );

  return { sendMessageToAI };
}

export default useSendChatMessage;
