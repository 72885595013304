import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import EmailIcon from '@mui/icons-material/Email';
import { Box, Link, Stack, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';
import { departmentApi } from 'src/api';
import LogoImageSrc from 'src/assets/images/innerwave_logo.png';
import { IconButtonAnimate } from 'src/components/animate';
import { CustomAvatar } from 'src/components/custom-avatar';
import Iconify from 'src/components/iconify';
import ProfileViewModal, { ProfileViewModalProps } from 'src/components/ProfileViewModal';
import { useStores } from 'src/models';
import { PATH_PAGE } from 'src/routes/paths';
import getProfileImg from 'src/utils/getProfileImg';

import NotificationsPopover from '../header/NotificationsPopover';

function NavAccount({ onOpenMyInfoModal }: { onOpenMyInfoModal?: VoidFunction }) {
  const { authStore } = useStores();
  const { user } = authStore;
  const [dept, setDept] = useState('');
  const theme = useTheme();

  useEffect(() => {
    (async () => {
      const result = await departmentApi.getDepartments();
      if (result) {
        const res = result.data.find((dept) => dept.deptSid === user?.deptSid);
        setDept((res?.deptNm || '') + ' / ' + (user?.empJobrespCd?.value || ''));
      }
    })();
  }, [user]);

  const handleOpenMyInfoModal = () => {
    if (!authStore.isAuthenticated || !authStore.user) return;

    onOpenMyInfoModal && onOpenMyInfoModal();
  };

  const [profileViewModalInfo, setProfileViewModalInfo] = React.useState<ProfileViewModalProps>({
    open: false,
    profileUrl: getProfileImg(user?.profileImgUrl),
  });
  // 프로필 이미지 클릭 시 크게 보여주는 모달 열기
  const handleClickProfile = () => {
    setProfileViewModalInfo({
      open: true,
      profileUrl: getProfileImg(user?.profileImgUrl),
      onClose: () => setProfileViewModalInfo({ ...profileViewModalInfo, open: false }),
    });
  };

  return (
    <StyledRoot>
      <Link
        component={RouterLink}
        to={PATH_PAGE.management.report.root}
        sx={{ display: 'contents' }}
      >
        <LogoImage src={LogoImageSrc} />
      </Link>
      <Box sx={{ position: 'relative' }}>
        <CustomAvatar
          src={getProfileImg(user?.profileImgUrl)}
          alt={user?.userNm}
          name={user?.userNm}
          sx={{
            width: 150,
            height: 150,
            mt: 4,
            border: '1px solid #efefef',
          }}
          onClick={handleClickProfile}
        />
      </Box>
      <TypoContainer>
        <TypoName>{user?.userNm}</TypoName>
        <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
          {dept}
        </Typography>
        <FlexBox>
          <EmailIcon sx={{ width: '16px', color: '#999', mb: 0.25 }} />
          <TypoContent>
            <a
              href={`https://office.hiworks.com/innerwave.co.kr/mail/webmail`}
              target="_blank"
              rel="noreferrer"
            >
              {user?.loginId}
            </a>
          </TypoContent>
        </FlexBox>
        <Stack direction={'row'} justifyContent={'center'} gap={0.1}>
          <NotificationsPopover />
          <IconButtonAnimate
            sx={{ width: 40, height: 40 }}
            onClick={(e) => {
              e.stopPropagation();
              handleOpenMyInfoModal();
            }}
          >
            <Iconify icon="mdi:user" />
          </IconButtonAnimate>
          <IconButtonAnimate
            sx={{ width: 40, height: 40 }}
            onClick={(e) => {
              e.stopPropagation();
              authStore.logout();
            }}
          >
            <Iconify icon="material-symbols:logout" />
          </IconButtonAnimate>
        </Stack>
      </TypoContainer>
      <Box
        sx={{
          width: '100%',
          background: theme.palette.primary.main,
          py: 0.5,
        }}
      >
        <Typography variant="subtitle2" color={'#FFFFFF'}>
          (주) 이너웨이브
        </Typography>
      </Box>
      <ProfileViewModal {...profileViewModalInfo} />
    </StyledRoot>
  );
}

export default observer(NavAccount);

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  alignItems: 'center',
  margin: '20px',
  backgroundColor: '#FFFFFF',
  border: '1px solid #A0DEFF',
  borderColor: theme.palette.primary.main,
  borderRadius: 8,
  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.08)',
  overflow: 'hidden',
}));

const TypoContainer = styled(Box)(({ theme }) => ({
  margin: 4,
  paddingTop: '28px',
  paddingBottom: '8px',
  justifyContent: 'center',
  textAlign: 'center',
}));

const TypoName = styled(Box)(({ theme }) => ({
  padding: '3px',
  fontSize: theme.typography.h4.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  position: 'relative',
}));

const TypoContent = styled(Box)(({ theme }) => ({
  padding: '5px',
  fontSize: theme.typography.body2.fontSize,
  color: theme.palette.grey[600],
}));

const FlexBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
}));

const LogoImage = styled('img')(({ theme }) => ({
  margin: '0 auto',
  marginTop: 20,
  width: 140,
  height: 'auto',
}));
