import * as Yup from 'yup';

declare module 'yup' {
  interface StringSchema {
    passwordComplexity(message: string): StringSchema;
  }
}

// 비밀번호 검증을 위한 Yup 확장 메소드
Yup.addMethod(Yup.string, 'passwordComplexity', function (message: string) {
  return this.test('passwordComplexity', message, function (value) {
    const { path, createError } = this;

    const inputValue = value || '';

    // 비밀번호 조건 검사
    const hasDigit = /\d/.test(inputValue);
    const hasLetter = /[a-zA-Z]/.test(inputValue);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(inputValue);
    const validConditions = [hasDigit, hasLetter, hasSpecialChar].filter(Boolean).length;

    // 조건에 따른 길이 검증
    if (
      (validConditions >= 3 && inputValue.length >= 8) ||
      (validConditions >= 2 && inputValue.length >= 10)
    ) {
      return true;
    }

    return createError({ path, message });
  });
});
