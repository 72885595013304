import { AuthLv, ChatbotSettingSearchParams, DBData } from 'src/@types';
import {
  ChatbotSystemSettingsApiResponse,
  LoadStatus,
  WebCrawling,
  WebCrawlingList,
} from 'src/@types/apiResponseTypes';

import { chatbotStreamInstance } from '../axios';

const SUB_URL = '/v1/setting';

export const getWebCrawlings = async ({
  keyword,
  page = 1,
  size = 25,
}: ChatbotSettingSearchParams) => {
  try {
    const url = `${SUB_URL}/webs`;
    const response = await chatbotStreamInstance.get<{ webs: WebCrawlingList[] }>(url, {
      params: {
        keyword,
        page,
        size,
      },
    });

    if (response.status === 200 && response.data) {
      return response.data.webs;
    }
  } catch (error) {
    console.error(error);
  }
};

export const findWebCrawling = async (web_id: number) => {
  try {
    const url = `${SUB_URL}/web/${web_id}`;
    const response = await chatbotStreamInstance.get<WebCrawling & DBData>(url);

    if (response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
};

export const deleteWebCrawling = async (web_id: number) => {
  try {
    const url = `${SUB_URL}/web/${web_id}`;
    const response = await chatbotStreamInstance.delete<ChatbotSystemSettingsApiResponse>(url);

    if (response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
};

export const addWebCrawling = async (
  web: WebCrawling & {
    auth_lv: AuthLv;
    vdb: string;
    sync_timing: string;
    sync_start_time: string;
  },
) => {
  try {
    const url = `${SUB_URL}/web`;
    const response = await chatbotStreamInstance.post<ChatbotSystemSettingsApiResponse>(url, web);

    if (response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
};

export const loadWebCrawling = async (web_id: number) => {
  try {
    const url = `${SUB_URL}/web/load`;
    const response = await chatbotStreamInstance.post<{
      status: LoadStatus;
      message: string;
      detail: string;
    }>(url, null, { params: { web_id } });

    if (response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateWebCrawling = async (
  web: WebCrawling & {
    auth_lv: AuthLv;
    vdb: string;
    sync_timing: string;
    sync_start_time: string;
  },
) => {
  try {
    const url = `${SUB_URL}/web`;
    const response = await chatbotStreamInstance.put<ChatbotSystemSettingsApiResponse>(url, web);

    if (response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
};
