import { useEffect, useMemo, useState } from 'react';
import errorIcon from '@iconify/icons-ic/error-outline';
import successIcon from '@iconify/icons-ic/round-check-circle-outline';
import warningIcon from '@iconify/icons-ic/round-warning';
import { Icon, IconifyIcon } from '@iconify/react';
import { Box, Button, ButtonProps, Card, Stack, Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useStores } from 'src/models';

import { DialogAnimate } from './animate';

/**
 * ## CAlert 설명
 * - 기본 Alert 창으로 경고 및 정보 등 여러 상황에 알맞은 Alert를 제공합니다.
 * - alert state를 통해 Alert 정보를 설정할 수 있습니다.
 */

const CAlert = () => {
  const theme = useTheme();
  const { alertStore } = useStores();
  const { open, category, title, content, hasCancelButton, btnLabel, closeAlert } = alertStore;
  // Alert 창의 카테고리별 theme 색상
  const categoryColors = useMemo(() => {
    return {
      error: theme.palette.error.main,
      warning: theme.palette.warning.main,
      info: theme.palette.info.main,
      success: theme.palette.success.main,
    };
  }, []);
  // Alert 창의 카테고리별 아이콘 및 색상
  const [info, setInfo] = useState<{
    category: ButtonProps['color'];
    icon: IconifyIcon;
    color: string;
  }>({
    category: 'info',
    icon: errorIcon,
    color: categoryColors.info,
  });

  useEffect(() => {
    switch (category) {
      case 'success':
        setInfo({
          category: 'success',
          icon: successIcon,
          color: categoryColors.success,
        });
        break;
      case 'info':
        setInfo({
          category: 'info',
          icon: errorIcon,
          color: categoryColors.info,
        });
        break;
      case 'warning':
        setInfo({
          category: 'warning',
          icon: warningIcon,
          color: categoryColors.warning,
        });
        break;
      case 'error':
        setInfo({
          category: 'error',
          icon: errorIcon,
          color: categoryColors.error,
        });
        break;
    }
  }, [category]);

  // 모달 창 닫기
  const handleClose = () => {
    closeAlert();
  };

  // 확인 버튼 클릭 시
  const handleConfirm = () => {
    closeAlert();
    alertStore.invokeOnConfirm();
  };

  return (
    <DialogAnimate
      open={open}
      onClose={handleClose}
      maxWidth={'sm'}
      id="alert"
      sx={{
        maxHeight: '80%',
        '.MuiDialog-container > .MuiBox-root': { alignItems: 'center' },
      }}
    >
      <Card id="CAlert-CARD" sx={{ background: theme.palette.background.paper, px: 5 }}>
        <Stack direction="row" sx={{ justifyContent: 'center', margin: '10px' }}>
          {title && (
            <>
              <Box component={Icon} icon={info.icon} sx={{ fontSize: '4rem', color: info.color }} />
              <Stack direction="row" sx={{ justifyContent: 'center', margin: '0 5px' }}>
                <Typography variant="h4" sx={{ margin: 'auto', fontWeight: '600', opacity: 0.9 }}>
                  {title}
                </Typography>
              </Stack>
            </>
          )}
        </Stack>
        {content && (
          <Stack
            direction="row"
            sx={{
              flexDirection: 'column',
              justifyContent: 'center',
              margin: '5px auto',
              padding: '10px',
              overflow: 'auto',
              maxWidth: '350px',
            }}
          >
            {content.split('<br/>').map((value, index) => {
              return (
                <Typography
                  variant="h4"
                  key={index}
                  sx={{
                    fontSize: '1.25rem !important',
                    fontWeight: '400',
                    margin: 'auto',
                    opacity: 0.7,
                    textAlign: 'center',
                    wordBreak: 'keep-all',
                  }}
                >
                  {value}
                  <br />
                </Typography>
              );
            })}
          </Stack>
        )}
        {!hasCancelButton && (
          <Stack
            direction="row"
            sx={{ justifyContent: 'center', padding: '10px', marginBottom: '10px' }}
          >
            <Button
              variant="contained"
              color={info.category}
              onClick={handleConfirm}
              sx={{ color: '#fff' }}
            >
              {btnLabel || '확인'}
            </Button>
          </Stack>
        )}
        {hasCancelButton && (
          <Stack
            direction="row"
            sx={{ justifyContent: 'center', padding: '10px', marginBottom: '10px' }}
          >
            <Button variant="contained" color="inherit" onClick={handleClose} sx={{ mr: 1.5 }}>
              취소
            </Button>
            <Button
              variant="contained"
              color={info.category}
              onClick={handleConfirm}
              sx={{ color: '#fff' }}
            >
              확인
            </Button>
          </Stack>
        )}
      </Card>
    </DialogAnimate>
  );
};

export default observer(CAlert);
