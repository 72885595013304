import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { DialogContent, IconButton, styled, useTheme } from '@mui/material';
import DialogAnimate, { Props as DialogProps } from 'src/components/animate/DialogAnimate';

export interface ProfileViewModalProps extends DialogProps {
  profileUrl: string;
}

/**
 * ## ProfileViewModal 설명
 * - 프로필 이미지를 클릭했을 때 크게 보여주는 모달
 */
function ProfileViewModal({ profileUrl, open, onClose }: ProfileViewModalProps) {
  const theme = useTheme();

  return (
    <StyledDialog fullWidth maxWidth="md" open={open} onClose={onClose} scroll="paper">
      <StyledDialogContent>
        <CloseButton size="large" onClick={onClose}>
          <CloseRoundedIcon />
        </CloseButton>
        <TransformWrapper>
          <TransformComponent
            wrapperStyle={{
              width: '100%',
              height: '100%',
              backgroundColor: theme.palette.grey[800],
            }}
            contentStyle={{
              width: '100%',
              height: '100%',
            }}
          >
            <ProfileImage src={profileUrl} alt="profile" />
          </TransformComponent>
        </TransformWrapper>
      </StyledDialogContent>
    </StyledDialog>
  );
}

export default ProfileViewModal;

const StyledDialog = styled(DialogAnimate)({
  '& .MuiDialog-paper': {
    height: '100%',
    maxHeight: 600,
  },
});

const StyledDialogContent = styled(DialogContent)({
  padding: 0,
  position: 'relative',
});

const ProfileImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
});

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  color: theme.palette.common.white,
  zIndex: 1000,
}));
