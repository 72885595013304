import { endOfDay } from 'date-fns';

// 2024년부터 현재년도까지의 연도를 배열로 반환하는 함수
export const getYears = () => {
  const currentYear = new Date().getFullYear();
  return Array.from({ length: currentYear - 2024 + 1 }, (_, index) => 2024 + index);
};

export const getMonths = () => {
  return Array.from({ length: 12 }, (_, index) => index + 1);
};

export const getDays = (year: number, month: number) => {
  return Array.from({ length: new Date(year, month, 0).getDate() }, (_, index) => index + 1);
};

// 주어진 연도와 월에 대한 주차 리스트를 생성하는 함수
export const getWeeks = (year: number, month: number) => {
  const firstDayOfMonth = new Date(year, month - 1, 1);
  const lastDayOfMonth = new Date(year, month, 0);
  const weeks = [];

  // 월의 첫 월요일 찾기
  const startOfWeek = new Date(firstDayOfMonth);
  startOfWeek.setDate(startOfWeek.getDate() - ((startOfWeek.getDay() + 6) % 7));

  let weekNumber = 1;
  while (startOfWeek <= lastDayOfMonth) {
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(endOfWeek.getDate() + 6);

    const thursdayOfWeek = new Date(startOfWeek);
    thursdayOfWeek.setDate(thursdayOfWeek.getDate() + 3); // 목요일 계산

    // 목요일이 해당 월에 포함된 주만 포함
    if (thursdayOfWeek.getMonth() + 1 === month) {
      weeks.push({
        start: new Date(startOfWeek),
        end: endOfDay(Math.min(endOfWeek.getTime(), lastDayOfMonth.getTime())),
        value: weekNumber,
      });
      weekNumber++;
    }

    startOfWeek.setDate(startOfWeek.getDate() + 7);
  }

  // 마지막 주차가 다음 달까지 포함하도록 조정
  const lastWeek = weeks[weeks.length - 1];
  if (lastWeek) {
    const adjustedEndOfWeek = new Date(lastWeek.start);
    adjustedEndOfWeek.setDate(adjustedEndOfWeek.getDate() + 6); // 한 주의 끝을 계산
    lastWeek.end = endOfDay(adjustedEndOfWeek); // 다음 달까지 포함
  }

  return weeks;
};

// TODO: 복잡한 로직을 간단하게 만들자
// 주어진 값을 Date 객체로 변환하는 함수
const toDate = (date: number | Date): Date => {
  return typeof date === 'number' ? new Date(date) : date;
};

// 한국 표준 주차 계산법을 사용하는 주차 계산 함수
export const getWeek = (date: number | Date): { week: number; month: number } => {
  const currentDate = toDate(date);
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1; // getMonth()는 0부터 시작하므로 1을 더해줌
  const weeks = getWeeks(year, month);

  const currentWeek = weeks.find((week) => currentDate >= week.start && currentDate <= week.end);

  // 없으면 다음달의 첫 주차로 설정
  return {
    week: currentWeek?.value || 1,
    month:
      currentWeek && currentWeek.value === 1
        ? currentWeek.end.getMonth() + 1
        : (currentWeek?.start?.getMonth() ?? month - 1) + 1,
  };
};

// 보고서 검색 필터의 초기 선택값을 설정하는 함수
export const getReportTargetDate = () => {
  const today = new Date();
  const dayOfWeek = today.getDay(); // 0: 일요일, 1: 월요일, ..., 6: 토요일
  // 월-수요일까지는 저번주 기준으로 표시 이후는 현재 날짜를 표시
  const targetDate =
    dayOfWeek > 0 && dayOfWeek <= 3 ? new Date(today.setDate(today.getDate() - 7)) : today;

  return targetDate;
};
