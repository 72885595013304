import React from 'react';
import { Checkbox, styled } from '@mui/material';
import {
  TreeItem2Content,
  TreeItem2GroupTransition,
  TreeItem2IconContainer,
  TreeItem2Label,
  TreeItem2Root,
} from '@mui/x-tree-view/TreeItem2';
import { TreeItem2Icon } from '@mui/x-tree-view/TreeItem2Icon';
import {
  unstable_useTreeItem2 as useTreeItem2,
  UseTreeItem2Parameters,
} from '@mui/x-tree-view/useTreeItem2';

interface CheckboxTreeItemProps
  extends Omit<UseTreeItem2Parameters, 'rootRef'>,
    Omit<React.HTMLAttributes<HTMLLIElement>, 'onFocus'> {
  isSelect?: boolean;
}

const CheckboxTreeItem = React.forwardRef(function CheckboxTreeItem(
  props: CheckboxTreeItemProps,
  ref: React.Ref<HTMLLIElement>,
) {
  const { id, itemId, label, disabled, children, isSelect, ...other } = props;
  const noChildren = children?.toString() === '';

  const {
    getRootProps,
    getContentProps,
    getIconContainerProps,
    getLabelProps,
    getGroupTransitionProps,
    status,
  } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });

  return (
    <TreeItem2Root {...getRootProps(other)}>
      <CheckboxTreeItemContent {...getContentProps()}>
        <TreeItem2IconContainer {...getIconContainerProps()}>
          <TreeItem2Icon status={status} />
        </TreeItem2IconContainer>
        <ContentRight>
          {noChildren && <Checkbox checked={isSelect} />}
          <TreeItem2Label {...getLabelProps()} />
        </ContentRight>
      </CheckboxTreeItemContent>
      {children && <TreeItem2GroupTransition {...getGroupTransitionProps()} />}
    </TreeItem2Root>
  );
});

export default CheckboxTreeItem;

const CheckboxTreeItemContent = styled(TreeItem2Content)(({ theme }) => ({
  padding: theme.spacing(1),
}));

const ContentRight = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
  gap: theme.spacing(1),
}));
