import React, { forwardRef } from 'react';
import { Helmet } from 'react-helmet-async';
// material
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

interface PageProps extends BoxProps {
  title?: string;
  needPadding?: boolean;
}

export const Page = forwardRef<HTMLDivElement, PageProps>(
  ({ children, title = '', needPadding = true, sx, ...others }, ref) => (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Box
        {...others}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
          maxWidth: '100%',
          ...(needPadding && { p: 2 }),
          ...sx,
        }}
      >
        {children}
      </Box>
    </React.Fragment>
  ),
);
