import { forwardRef } from 'react';
import { Avatar, Badge } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { CustomAvatarProps } from './types';

const getCharAtName = (name: string) => name && name.charAt(0).toUpperCase();
const getColorByName = (name: string) => {
  if (['A', 'N', 'H', 'L', 'Q'].includes(getCharAtName(name))) return 'primary';
  if (['F', 'G', 'T', 'I', 'J'].includes(getCharAtName(name))) return 'info';
  if (['K', 'D', 'Y', 'B', 'O'].includes(getCharAtName(name))) return 'success';
  if (['P', 'E', 'R', 'S', 'U'].includes(getCharAtName(name))) return 'warning';
  if (['V', 'W', 'X', 'M', 'Z'].includes(getCharAtName(name))) return 'error';
  return 'primary';
};

const CustomAvatar = forwardRef<HTMLDivElement, CustomAvatarProps>(
  ({ color, name = '', BadgeProps, children, sx, ...other }, ref) => {
    const theme = useTheme();
    const charAtName = getCharAtName(name);
    const colorByName = getColorByName(name);
    const variant = color || colorByName;

    return (
      <>
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          {...BadgeProps}
        >
          <Avatar
            ref={ref}
            sx={{
              color: theme.palette[variant]?.contrastText,
              backgroundColor: theme.palette[variant]?.main,
              fontWeight: theme.typography.fontWeightMedium,
              ...sx,
            }}
            {...other}
          >
            {name && charAtName}
            {children}
          </Avatar>
        </Badge>
      </>
    );
  },
);

export default CustomAvatar;
