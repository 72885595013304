import { useState } from 'react';
import { ChatbotFeedbackFormModalProps } from 'src/screens/admin-management/chat-bot/feedback/ChatbotFeedbackFormModal';

const FORM_ID = 'chatbotFeedbackForm';

const INIT_CHATBOT_FEEDBACK_FORM_MODAL_INFO: ChatbotFeedbackFormModalProps = {
  open: false,
  formType: 'CREATE',
  formId: FORM_ID,
};

function useFeedbackForm() {
  const [feedbackFormModelInfo, setFeedbackFormModelInfo] = useState<ChatbotFeedbackFormModalProps>(
    INIT_CHATBOT_FEEDBACK_FORM_MODAL_INFO,
  );

  const onCloseFeedbackFormModal = () => {
    setFeedbackFormModelInfo(INIT_CHATBOT_FEEDBACK_FORM_MODAL_INFO);
  };

  const handleAddFeedback = () => {
    setFeedbackFormModelInfo({
      ...INIT_CHATBOT_FEEDBACK_FORM_MODAL_INFO,
      open: true,
      onClose: onCloseFeedbackFormModal,
      onComplete: () => {
        onCloseFeedbackFormModal();
      },
    });
  };

  return { feedbackFormModelInfo, handleAddFeedback };
}

export default useFeedbackForm;
