import { useNavigate } from 'react-router';
import { Button, DialogActions, DialogContent } from '@mui/material';
import { IReport } from 'src/@types/apiResponseTypes';
import DialogAnimate, { Props as DialogProps } from 'src/components/animate/DialogAnimate';
import DialogTitleWithCloseButton from 'src/components/dialog/DialogTitleWithCloseButton';
import ReportComments from 'src/components/report-comments/ReportComments';
import { useStores } from 'src/models';
import { PATH_PAGE } from 'src/routes/paths';
import { REPORT_TYPES } from 'src/static/constants';

import MeetingReportForm from './MeetingReportForm';
/**
 * ## MeetingReportViewModal 설명
 * - 회의록 상세보기 모달
 * - 본인 등록 회의록의 경우 수정버튼을 띄움
 */

export interface IMeetingReportViewModalProps extends DialogProps {
  report?: IReport;
}

function MeetingReportViewModal({ report, open, onClose }: IMeetingReportViewModalProps) {
  const { authStore } = useStores();
  const navigate = useNavigate();
  return (
    <DialogAnimate fullWidth maxWidth="xl" open={open} onClose={onClose} scroll="paper">
      <DialogTitleWithCloseButton title={report?.title || ''} onClose={onClose} />
      {/* Form */}
      <DialogContent dividers sx={{ padding: 3 }}>
        <MeetingReportForm report={report} formType="READONLY" formId="meeting-report-view" />
        {report?.reportSid && <ReportComments reportSid={report.reportSid} sx={{ marginTop: 3 }} />}
      </DialogContent>
      {/* Submit Button */}
      <DialogActions>
        {
          // 본인이 등록한 회의록의 경우 수정버튼을 띄움
          report && authStore.user?.userSid === report?.regUser.userSid && (
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                navigate(
                  `${PATH_PAGE.management.report.edit}/${report.reportSid}?reportType=${REPORT_TYPES.MEETING}`,
                );
                onClose && onClose();
              }}
            >
              {'수정하기'}
            </Button>
          )
        }
        <Button color="secondary" variant="contained" onClick={onClose}>
          {'닫기'}
        </Button>
      </DialogActions>
    </DialogAnimate>
  );
}

export default MeetingReportViewModal;
