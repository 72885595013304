import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { MessageInfo } from '../utils/types';

type InputTypes = Pick<MessageInfo, 'response'>;

interface MessageInputProps {
  isAiProcessing: boolean;
  onSendMessageToAI: (response: string) => void;
}

export function useMessageInput({ isAiProcessing, onSendMessageToAI }: MessageInputProps) {
  const MAX_CHARS = 300;
  // 채팅창에 한국어 문장 입력 후 엔터 누를 시, 채팅창에 한국어 문장에 이어서 한국어 문장의 마지막 글자까지 입력되는 버그 방지
  const [isComposing, setIsComposing] = useState<boolean>(false);

  const methods = useForm<InputTypes>({
    defaultValues: {
      response: '',
    },
  });
  const { reset, watch } = methods;

  const inputMessage = watch('response');

  const onHandleSubmit = async ({ response }: InputTypes) => {
    // 사용자가 채팅창에 실제 문자 없이 공백 문자만 입력할 경우, 메시지 전송 방지
    if (response.trim()) {
      /**
       * 사이드 채팅 메뉴바에 선택되지 않았을 때(앱 초기 렌더링 직후, 채팅 리스트 중 하나를 삭제하고 난 경우)
       * 새로운 채팅 생성
       */
      // 메시지를 AI가 있는 백엔드에 보내기
      onSendMessageToAI(response);
      // 채팅 인풋창 초기화
      reset({ response: '' });
    }
  };

  // 키보드 엔터 누를 때 메시지 전송하는 함수
  const handleEnterKeyPress = (event: React.KeyboardEvent, handleSubmit: any) => {
    // AI가 메시지를 생성 중일 때 엔터키 방지.
    if (!isAiProcessing) {
      /**
       * event.key === 'Enter' && !event.shiftKey 의미
       * 동시에 엔터키를 눌렀고 쉬프트 키를 안 눌렀으면 메시지 전송,
       * 동시에 엔터키를 눌렀고 쉬프트 키를 눌렀으면 줄바꿈 (TextField의 multiline 옵션).
       */
      const shouldSendMessage = event.key === 'Enter' && !event.shiftKey;
      if (!isComposing && shouldSendMessage) {
        event.preventDefault();
        handleSubmit(onHandleSubmit)();
      }
    }
  };

  return { methods, inputMessage, MAX_CHARS, setIsComposing, onHandleSubmit, handleEnterKeyPress };
}
