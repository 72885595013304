import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { DataGridProProps, GridPaginationModel, useGridApiRef } from '@mui/x-data-grid-pro';
import { TSearchParams } from 'src/@types';
import { IReportTemplate } from 'src/@types/apiResponseTypes';
import { reportApi } from 'src/api';
import CDataGridPro from 'src/components/CDataGridPro';
import { CGridFooter, CGridHeader } from 'src/components/custom-grid-data-components';
import useCallApiWithLoading from 'src/hooks/useCallApiWithLoading';
import useGetGridCol from 'src/hooks/useGetGridCol';
import { DEFAULT_PAGE_SIZE } from 'src/static/constants';

interface ReportTemplateTableProps extends Omit<DataGridProProps, 'columns'> {
  onClickAdd?: () => void;
  useYn?: boolean;
}

export interface ReportTemplateTableRef {
  getReportTemplates: () => void;
}

const ReportTemplateTable = forwardRef<ReportTemplateTableRef, ReportTemplateTableProps>(
  ({ onClickAdd, useYn, ...props }, ref) => {
    const { callApiWithLoading } = useCallApiWithLoading();

    const apiRef = useGridApiRef();
    const { getReportTmpltGridCol } = useGetGridCol();
    const columns = getReportTmpltGridCol();
    // reportTemplates
    const [reportTemplates, setReportTemplates] = useState<IReportTemplate[]>([]);
    // grid row count
    const [rowCount, setRowCount] = useState<number>(0);
    // search params
    const [searchParams, setSearchParams] = useState<TSearchParams>({
      page: 0,
      size: DEFAULT_PAGE_SIZE,
      text: '',
    });

    // searchParams 변경 시 목록 조회
    useEffect(() => {
      getReportTemplates();
    }, [searchParams.page, searchParams.size, searchParams.text, searchParams.searchText]);

    // 목록 조회
    const getReportTemplates = async () => {
      try {
        await callApiWithLoading(async () => {
          const result = await reportApi.getReportTemplates({
            text: searchParams.text,
            page: searchParams.page + 1,
            size: searchParams.size,
            searchText: searchParams.searchText,
            useYn,
          });

          if (result) {
            setReportTemplates(result.data);
            if (result.pagination) {
              setRowCount(result.pagination.totalElements);
              setSearchParams({
                ...searchParams,
                page: result.pagination.page - 1,
                size: result.pagination.size,
              });
            }
          }
        });
      } catch (error) {
        console.error(error);
      }
    };

    // 검색 시
    const handleSearch = (text: string) => {
      setSearchParams({
        ...searchParams,
        page: 0,
        text,
        searchText: text,
      });
    };

    // 페이지 모델 변경 시
    const handlePaginationModelChange = (paginationModel: GridPaginationModel) => {
      setSearchParams({
        ...searchParams,
        page: paginationModel.page,
        size: paginationModel.pageSize,
      });
    };

    useImperativeHandle(ref, () => ({
      getReportTemplates: getReportTemplates,
    }));

    return (
      <CDataGridPro
        apiRef={apiRef}
        columns={columns}
        rows={reportTemplates.map((reportTemplate) => ({
          id: reportTemplate.tmplSid,
          ...reportTemplate,
        }))}
        rowCount={rowCount}
        paginationModel={{
          page: searchParams.page,
          pageSize: searchParams.size,
        }}
        onPaginationModelChange={handlePaginationModelChange}
        checkboxSelection={false}
        slots={{
          footer: CGridFooter,
          toolbar: CGridHeader,
        }}
        slotProps={{
          toolbar: {
            onClickAdd: onClickAdd,
            onSearch: handleSearch,
          },
        }}
        {...props}
      />
    );
  },
);
export default ReportTemplateTable;
