import { useEffect, useState } from 'react';
import { styled, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { IEmployee, IEquipment } from 'src/@types/apiResponseTypes';
import { equipmentApi } from 'src/api';
import { useStores } from 'src/models';

interface Props {
  employee?: IEmployee;
}
// 사용자 기본 정보 컴포넌트
const UserEquipment = observer(({ employee }: Props) => {
  const { authStore } = useStores();
  const { user } = authStore;
  const [equipments, setEquipments] = useState<IEquipment[]>([]);

  const selectUser = employee ? employee : user;

  useEffect(() => {
    (async () => {
      if (selectUser) {
        const result = await equipmentApi.gets({
          text: '',
          page: 1,
          size: 1000,
        });
        if (result) {
          setEquipments(
            result.data
              .filter((r) => r.userSid === selectUser.userSid)
              .sort((a: any, b: any) => {
                return a.invenTypeCd.code - b.invenTypeCd.code;
              }),
          );
        }
      }
    })();
  }, []);

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <Cell>ID</Cell>
            <Cell>구분</Cell>
            <Cell>모델명</Cell>
            <Cell>모델S/N</Cell>
          </TableRow>
        </TableHead>
        <TableBody>
          {equipments &&
            equipments.length > 0 &&
            equipments.map((e: IEquipment, i: number) => {
              return (
                <TableRow key={'equip-row-' + i}>
                  <Cell>{e.inventoryId}</Cell>
                  <Cell>{e.invenTypeCd.value}</Cell>
                  <Cell sx={{ textOverflow: 'ellipsis' }}>{e.modelName}</Cell>
                  <Cell sx={{ textOverflow: 'ellipsis' }}>{e.modelSn}</Cell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </>
  );
});

export default UserEquipment;

const Cell = styled(TableCell)(({ theme }) => ({
  paddingTop: 8,
  paddingBottom: 8,
  color: '#666666',
}));
