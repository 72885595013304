import {
  Editor,
  EditorOptions,
  Extensions,
  getSchema,
  JSONContent,
  useEditor,
} from '@tiptap/react';
import { isEmpty } from 'lodash';
import { Node } from 'prosemirror-model';

import ExtensionKit from './extensions/extension-kit';

/**
 * Block Editor 커스텀 훅
 */

// editor content validation method
export const validateSchema = (doc: JSONContent | string, extensions?: Extensions): boolean => {
  try {
    const schema = getSchema(extensions || []);
    const contentNode = Node.fromJSON(schema, doc);
    contentNode.check();
    return true;
  } catch (e) {
    return false;
  }
};

// editor content parsing method
export const parseContent = (stringContent?: string, defaultContent?: string) => {
  try {
    const content = JSON.parse(stringContent || '');

    if (isEmpty(content) || (content.content && content.content.length <= 0)) {
      return defaultContent || '';
    }

    return content as JSONContent;
  } catch (error) {
    return defaultContent || '';
  }
};

// editor content stringify method
export const stringifyContent = (JSONContent?: JSONContent) => {
  try {
    JSONContent?.content?.forEach((node, index) => {
      if (node.type === 'imageUpload') {
        // content에 type이 imageUpload인 node가 있는 경우 제거
        JSONContent?.content?.splice(index, 1);
      }
    });
    return JSON.stringify(JSONContent || '');
  } catch (error) {
    return '';
  }
};

export const mergeStringContent = (content: string, newContent: string): string => {
  const parsedContent = parseContent(content);
  const parsedNewContent = parseContent(newContent);
  const initContent = {
    type: 'doc',
    content: [],
  };

  const mergedContent = {
    type: typeof parsedContent === 'string' ? initContent.type : parsedContent.type,
    content: [
      ...(typeof parsedContent === 'string' ? initContent.content : parsedContent.content ?? []),
      ...(typeof parsedNewContent === 'string'
        ? initContent.content
        : parsedNewContent.content ?? []),
    ],
  };

  return stringifyContent(mergedContent);
};

// filter empty text nodes
export const filterEmptyTextNodes = (content: JSONContent): JSONContent => {
  const newContent = { ...content };

  newContent.content = newContent.content?.filter((node) => {
    if (node.type === 'paragraph' && (!node.content || node.content?.length === 0)) {
      return false;
    }

    return true;
  });

  return newContent;
};

type ReturnType = {
  editor: Editor | null;
};

function useBlockEditor(options?: Partial<EditorOptions>): ReturnType {
  const editor = useEditor({
    autofocus: true,
    extensions: [...ExtensionKit()],
    editorProps: {
      attributes: {
        autocomplete: 'off',
        autocorrect: 'off',
        autocapitalize: 'off',
        class: 'min-h-full',
      },
    },
    ...options,
  });

  return {
    editor,
  };
}

export default useBlockEditor;
