import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, FormControlLabel, styled, Switch } from '@mui/material';
import { useSnackbar } from 'notistack';
import { TFormType } from 'src/@types';
import { ChatbotFeedback } from 'src/@types/apiResponseTypes';
import { chatbotApi } from 'src/api';
import { BlockEditor, useBlockEditor } from 'src/components/block-editor';
import { parseContent, stringifyContent } from 'src/components/block-editor/useBlockEditor';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/FormProvider';
import { useStores } from 'src/models';
import * as Yup from 'yup';

export interface ChatbotFeedbackFormProps {
  readonly?: boolean;
  formId: string;
  formType: TFormType;
  feedback?: ChatbotFeedback;
  onComplete?: () => void;
}

function ChatbotFeedbackForm({ formId, feedback, formType, onComplete }: ChatbotFeedbackFormProps) {
  const { enqueueSnackbar } = useSnackbar();

  const { authStore } = useStores();

  const user_sid = authStore.user?.userSid;

  const [applyYn, setAppylYn] = useState<boolean>(feedback ? feedback.apply_yn : false);

  const resolver = yupResolver(
    Yup.object().shape({
      content: Yup.string().required('답변을 입력해주세요'),
    }),
  );

  const methods = useForm<ChatbotFeedback>({
    defaultValues: feedback,
    resolver,
  });

  const { editor } = useBlockEditor({
    content: parseContent(feedback?.content),
  });

  const onSubmit = async (formValue: ChatbotFeedback) => {
    try {
      if (feedback?.feedback_id && formType === 'UPDATE') {
        const result = await chatbotApi.updateFeedback(formValue);
        if (result) {
          enqueueSnackbar('피드백이 수정되었습니다.', { variant: 'success' });
        } else {
          enqueueSnackbar('피드백 수정에 실패했습니다.', { variant: 'error' });
        }
      } else {
        const result = await chatbotApi.addFeedback(formValue);
        if (result) {
          enqueueSnackbar('피드백이 등록되었습니다.', { variant: 'success' });
        } else {
          enqueueSnackbar('피드백 등록에 실패했습니다.', { variant: 'error' });
        }
      }

      if (onComplete) {
        onComplete();
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <StyledFormProvider
      formId={formId}
      methods={methods}
      onSubmit={(event) => {
        event.preventDefault();
        const content = stringifyContent(editor?.getJSON());
        methods.setValue('apply_yn', applyYn);
        methods.setValue('content', content);
        if (user_sid) {
          methods.setValue('reg_user_id', user_sid);
        }
        onSubmit(methods.getValues());
      }}
    >
      <ColumnContainer>
        {formType === 'UPDATE' && (
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
            <FormControlLabel
              control={<Switch checked={applyYn} onChange={(e) => setAppylYn(e.target.checked)} />}
              label="피드백 처리 여부"
            />
          </Box>
        )}
        <RHFTextField name="title" value={undefined} defaultValue={feedback?.title} label="제목" />
        <StyledInnerFormContainer>
          <StyledLabel>피드백 내용</StyledLabel>
          <BlockEditor editor={editor} />
        </StyledInnerFormContainer>
      </ColumnContainer>
    </StyledFormProvider>
  );
}

const StyledFormProvider = styled(FormProvider)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  padding: theme.spacing(2),
}));

const ColumnContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  alignItems: 'center',
  '& > *': {
    flex: 1,
  },
}));

const StyledInnerFormContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  width: '100%',
}));

const StyledLabel = styled('span')(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 700,
  padding: theme.spacing(0, 1),
  color: theme.palette.text.secondary,
  position: 'absolute',
  top: '-12px',
  left: '20px',
  background: theme.palette.background.paper,
}));

export default ChatbotFeedbackForm;
