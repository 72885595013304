import { useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { Box, styled } from '@mui/material';
import { MyInfoModal } from 'src/components/my-info';
import { useSettingsContext } from 'src/components/settings';
import useResponsive from 'src/hooks/useResponsive';
import { HEADER } from 'src/static/constants';

import MainHeader, { HeaderInfo } from './header/MainHeader';
import NavMini from './nav/NavMini';
import NavVertical from './nav/NavVertical';
import UtilsDial from './nav/UtilsDial';
import Header from './header';
import Main from './Main';

export type DashboardLayoutContext = {
  setCustomHeader: React.Dispatch<React.SetStateAction<HeaderInfo | undefined>>;
  scrollRef: React.RefObject<HTMLDivElement>;
};

export default function DashboardLayout() {
  const scrollRef = useRef<HTMLDivElement>(null);
  const { themeLayout } = useSettingsContext();
  const isDesktop = useResponsive('up', 'lg');
  const isNavMini = themeLayout === 'mini';
  const [open, setOpen] = useState(false);
  const [customHeader, setCustomHeader] = useState<HeaderInfo | undefined>(undefined);
  const [myInfoModalIsOpen, setMyInfoModalIsOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenMyInfoModal = () => {
    setMyInfoModalIsOpen(true);
  };

  const handleCloseMyInfoModal = () => {
    setMyInfoModalIsOpen(false);
  };

  const fix = localStorage.getItem('utilDialFixed') ? true : false;
  const [fixed, setFixed] = useState(fix);

  return (
    <LayoutContainer>
      <Header onOpenNav={handleOpen} onOpenMyInfoModal={handleOpenMyInfoModal} />
      {isDesktop && !fixed && <UtilsDial setFixed={setFixed} />}
      <ContentsContainer>
        {isDesktop && isNavMini ? (
          <NavMini />
        ) : (
          <NavVertical
            openNav={open}
            onCloseNav={handleClose}
            onOpenMyInfoModal={handleOpenMyInfoModal}
          />
        )}

        <MainContainer>
          <MainHeader customHeader={customHeader} />
          <Main scrollRef={scrollRef}>
            <Outlet
              context={{
                setCustomHeader,
                scrollRef,
              }}
            />
          </Main>
        </MainContainer>
        {isDesktop && fixed && <UtilsDial fixed={fixed} setFixed={setFixed} />}
      </ContentsContainer>
      <MyInfoModal open={myInfoModalIsOpen} onClose={handleCloseMyInfoModal} />
    </LayoutContainer>
  );
}

const LayoutContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100vh',
  overflow: 'hidden',
}));

const ContentsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: `calc(100% - ${HEADER.H_DASHBOARD_TOP_HEADER_MOBILE}px)`,
  marginTop: `${HEADER.H_DASHBOARD_TOP_HEADER_MOBILE}px`,

  // desktop style
  [theme.breakpoints.up('lg')]: {
    height: `calc(100% - ${HEADER.H_DASHBOARD_TOP_HEADER_DESKTOP}px)`,
    marginTop: `${HEADER.H_DASHBOARD_TOP_HEADER_DESKTOP}px`,
  },
}));

const MainContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  flexGrow: 1,
  height: '100%',
  overflow: 'hidden',
}));
