import { TClientFormData, TSearchParams } from 'src/@types';
import { IClient } from 'src/@types/apiResponseTypes';

import { TApiResponseData } from '../apiTypes';
import instance from '../axios';

const SUB_URL = '/v1/customer';

/**
 * 고객사 목록 조회
 * @param params 검색 조건
 */
export const getClients = async (params?: TSearchParams) => {
  try {
    const url = SUB_URL;
    const response = await instance.get<TApiResponseData<IClient[]>>(url, {
      params,
    });

    // 성공 시 데이터 반환
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
        pagination: response.data.pagination,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 고객사 단건 조회
 * @param customerSid 고객 고유번호
 */
export const findClient = async (customerSid: number) => {
  try {
    const url = `${SUB_URL}/${customerSid}`;
    const response = await instance.get<TApiResponseData<IClient>>(url);

    // 성공 시 데이터 반환
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 고객사 등록
 * @param clientInfo 고객사 등록 정보: TClientFormData
 */
export const createClient = async (clientInfo: TClientFormData) => {
  try {
    const url = SUB_URL;
    const response = await instance.post<TApiResponseData<IClient>>(url, clientInfo);

    // 성공 시 데이터 반환
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 고객사 정보 수정
 * @param customerSid 고객 고유번호
 * @param clientInfo 고객사 등록 정보: TClientFormData
 */
export const updateClient = async (customerSid: number, clientInfo: TClientFormData) => {
  try {
    const url = `${SUB_URL}/${customerSid}`;
    const payload = clientInfo;
    const response = await instance.put<TApiResponseData<IClient>>(url, payload);

    // 성공 시 데이터 반환
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 고객사 삭제
 * @param customerSid 고객 고유번호
 */
export const deleteClient = async (customerSid: number) => {
  try {
    const url = `${SUB_URL}/${customerSid}`;
    const response = await instance.delete<TApiResponseData<boolean>>(url);

    // 성공 시 데이터 반환
    if (response.data.resultCode === 'S' && response.data.data) {
      // 삭제 성공 시 true 반환
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};
