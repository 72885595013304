import { ChatbotSettingSearchParams } from 'src/@types';
import {
  ChatbotSystemSettingsApiResponse,
  LoadStatus,
  RDB,
  RDBList,
} from 'src/@types/apiResponseTypes';

import { chatbotStreamInstance } from '../axios';

const SUB_URL = '/v1/setting';

export const getRdbs = async ({ keyword, page = 1, size = 25 }: ChatbotSettingSearchParams) => {
  try {
    const url = `${SUB_URL}/rdbs`;
    const response = await chatbotStreamInstance.get<{ rdbs: RDBList[] }>(url, {
      params: {
        keyword,
        page,
        size,
      },
    });

    if (response.status === 200 && response.data) {
      return response.data.rdbs;
    }
  } catch (error) {
    console.error(error);
  }
};

export const findRdb = async (rdb_id: number) => {
  try {
    const url = `${SUB_URL}/rdb/${rdb_id}`;
    const response = await chatbotStreamInstance.get<RDB>(url);

    if (response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
};

export const deleteRdb = async (rdb_id: number) => {
  try {
    const url = `${SUB_URL}/rdb/${rdb_id}`;
    const response = await chatbotStreamInstance.delete<ChatbotSystemSettingsApiResponse>(url);

    if (response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
};

export const addVdb = async (rdb: RDB) => {
  const { rdb_id, ...others } = rdb;
  try {
    const url = `${SUB_URL}/rdb`;
    const response = await chatbotStreamInstance.post<ChatbotSystemSettingsApiResponse>(
      url,
      others,
    );

    if (response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
};

export const loadRdb = async (rdb_id: number) => {
  try {
    const url = `${SUB_URL}/rdb/load`;
    const response = await chatbotStreamInstance.post<{
      status: LoadStatus;
      message: string;
      detail: string;
    }>(url, null, { params: { rdb_id } });

    if (response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateRdb = async (rdb: RDB) => {
  try {
    const url = `${SUB_URL}/rdb`;
    const response = await chatbotStreamInstance.put<ChatbotSystemSettingsApiResponse>(url, rdb);

    if (response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
};
