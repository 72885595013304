import { useEffect } from 'react';
import { AxiosError, InternalAxiosRequestConfig } from 'axios';
import { useSnackbar } from 'notistack';
import { useStores } from 'src/models';

import { TApiResponse } from './apiTypes';
import instance from './axios';
/**
 * # axios 인스턴스를 매개변수로 받는 useAxiosInterceptor 컴스텀 훅
 * - 요청과 응답 인터셉터를 설정하고 컴포넌트가 언마운트될 때 제거한다.
 */
const useAxiosInterceptor = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { authStore } = useStores();
  // 요청을 처리하는 로직
  const handleRequest = (config: InternalAxiosRequestConfig<any>) => {
    return config;
  };

  // 응답을 처리하는 로직
  const handleResponse = (response: TApiResponse<any>) => {
    // resultCode 가 'F'인 경우 에러 처리
    if (response.data.resultCode === 'F') {
      switch (response.data.errorCode) {
        case '401':
          // 인증 에러 처리
          enqueueSnackbar('인증 에러가 발생했습니다.', { variant: 'error' });
          authStore.initAuth();
          break;
      }
    }
    return response;
  };

  // 에러를 처리하는 로직
  const handleError = (error: AxiosError): Promise<AxiosError> => {
    return Promise.reject(error);
  };

  // 인터셉터를 설정
  const setupInterceptors = (): { requestInterceptor: number; responseInterceptor: number } => {
    const requestInterceptor = instance.interceptors.request.use(handleRequest, handleError);
    const responseInterceptor = instance.interceptors.response.use(handleResponse, handleError);

    return { requestInterceptor, responseInterceptor };
  };

  // 인터셉터를 제거
  const ejectInterceptors = (requestInterceptor: number, responseInterceptor: number): void => {
    instance.interceptors.request.eject(requestInterceptor);
    instance.interceptors.response.eject(responseInterceptor);
  };

  // 컴포넌트가 마운트될 때 인터셉터를 설정하고, 언마운트될 때 인터셉터를 제거한다.
  useEffect(() => {
    const { requestInterceptor, responseInterceptor } = setupInterceptors();

    return () => {
      ejectInterceptors(requestInterceptor, responseInterceptor);
    };
  }, []);
};

/**
 * @title AxiosInterceptor
 * @description Axios 인터셉터를 설정하는 컴포넌트
 */
export const AxiosInterceptor = () => {
  useAxiosInterceptor();
  return null;
};
