import { Navigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'src/models';
import { PATH_AUTH } from 'src/routes/paths';

type GuestGuardProps = {
  children: React.ReactNode;
};

function GuestGuard({ children }: GuestGuardProps) {
  const { authStore } = useStores();
  const { isAuthenticated } = authStore;

  if (isAuthenticated) {
    return <Navigate to={PATH_AUTH.root} />;
  }

  return <>{children}</>;
}

export default observer(GuestGuard);
