import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import { Link } from '@mui/material';
import { JSONContent } from '@tiptap/core';
import remarkGfm from 'remark-gfm';
import Image from 'src/components/image';

const CustomReactMarkdown = ({ text }: { text: string }) => {
  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      components={{
        code({ className, children, ...props }) {
          const match = /language-(\w+)/.exec(className || '');
          return match ? (
            <SyntaxHighlighter language={match[1]} PreTag="div" style={materialDark}>
              {String(children).replace(/\n$/, '')}
            </SyntaxHighlighter>
          ) : (
            <code {...props}>{children}</code>
          );
        },
        img: (image) => <Image src={image.src || ''} />,
        a(props) {
          const { href, children } = props;
          return (
            <Link
              href={href}
              target="_blank"
              sx={{
                color: '#3280CE',
                fontWeight: 'bold',
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              {children || href}
            </Link>
          );
        },
      }}
    >
      {text}
    </ReactMarkdown>
  );
};

export const parseAndConvertToMarkdown = (response: string) => {
  try {
    const jsonContent = JSON.parse(response);
    return convertToMarkdown(jsonContent);
  } catch (error) {
    // console.error('Failed to parse JSON:', error);
    return response;
  }
};

// JSON을 마크다운으로 변환하는 함수
function convertToMarkdown(json: JSONContent): string {
  if (!json.content) return '';
  return json.content.map((node) => processNode(node)).join('\n\n');
}

function processNode(node: JSONContent): string {
  switch (node.type) {
    case 'doc': {
      return node.content ? node.content.map((child) => processNode(child)).join('\n\n') : '';
    }

    case 'heading': {
      const level = node.attrs?.level || 1;
      const headingContent = node.content?.map((child) => processTextNode(child)).join('') || '';
      return `${'#'.repeat(level)} ${headingContent}`;
    }

    case 'paragraph': {
      if (!node.content) return '';

      let text = node.content.map((contentNode) => processTextNode(contentNode)).join('');
      if (node.attrs?.textAlign === 'start' && text.startsWith('     ')) {
        return text;
      }
      if (node.attrs?.textAlign === 'center') {
        text = `<div align="center">${text}</div>`;
      } else if (node.attrs?.textAlign === 'right') {
        text = `<div align="right">${text}</div>`;
      }
      return text;
    }

    case 'text': {
      return processTextNode(node);
    }

    case 'bulletList': {
      return (node.content || []).map((item) => `- ${processNode(item)}`).join('\n');
    }

    case 'orderedList': {
      return (node.content || [])
        .map((item, index) => `${index + 1}. ${processNode(item)}`)
        .join('\n');
    }

    case 'listItem': {
      return node.content
        ? node.content.map((contentNode) => processNode(contentNode)).join('')
        : '';
    }

    case 'imageBlock': {
      const src = node.attrs?.src || '';
      const alt = node.attrs?.alt || '';
      const title = node.attrs?.title || '';
      return title ? `![${alt}](${src} "${title}")` : `![${alt}](${src})`;
    }

    case 'blockquote': {
      const content = node.content
        ? node.content.map((child) => processNode(child)).join('\n')
        : '';
      return content
        .split('\n')
        .map((line) => `> ${line}`)
        .join('\n');
    }

    case 'codeBlock': {
      const language = node.attrs?.language || '';
      const code = node.content ? node.content.map((child) => processNode(child)).join('\n') : '';
      return `\`\`\`${language}\n${code}\n\`\`\``;
    }

    case 'hardBreak': {
      return '\n';
    }

    case 'horizontalRule': {
      return '---';
    }

    case 'table': {
      if (!node.content) return '';

      const rows = node.content.map((row) => processNode(row));
      if (rows.length < 2) return rows.join('\n');

      // 헤더 행과 구분자 행을 추가
      const headerRow = rows[0];
      const delimiterRow = headerRow
        .split('|')
        .map(() => '---')
        .join('|');

      return [headerRow, delimiterRow, ...rows.slice(1)].join('\n');
    }

    case 'tableRow': {
      return node.content ? `|${node.content.map((cell) => processNode(cell)).join('|')}|` : '';
    }

    case 'tableCell': {
      return node.content ? node.content.map((content) => processNode(content)).join(' ') : '';
    }

    case 'taskList': {
      return (node.content || []).map((item) => processNode(item)).join('\n');
    }

    case 'taskItem': {
      const checked = node.attrs?.checked || false;
      const content = node.content ? node.content.map((child) => processNode(child)).join('') : '';
      return `- [${checked ? 'x' : ' '}] ${content}`;
    }

    case 'bold': {
      return `**${processTextNode(node)}**`;
    }

    case 'italic': {
      return `*${processTextNode(node)}*`;
    }

    case 'strike': {
      return `~~${processTextNode(node)}~~`;
    }

    case 'link': {
      const href = node.attrs?.href || '';
      const content = node.content ? node.content.map((child) => processNode(child)).join('') : '';
      return `[${content}](${href})`;
    }

    default: {
      console.log('Unhandled node type:', node.type);
      return node.text || '';
    }
  }
}

function processTextNode(node: JSONContent): string {
  if (node.type !== 'text' || !node.text) return '';

  let text = node.text;

  if (node.marks) {
    for (const mark of node.marks) {
      switch (mark.type) {
        case 'bold':
          text = `**${text}**`;
          break;
        case 'italic':
          text = `*${text}*`;
          break;
        case 'link':
          text = `[${text}](${mark.attrs?.href || ''})`;
          break;
      }
    }
  }

  return text;
}

export default CustomReactMarkdown;
