import { useCallback } from 'react';
import * as Popover from '@radix-ui/react-popover';

import { DropdownButton } from '../../../ui/Dropdown';
import { Icon } from '../../../ui/Icon';
import { Surface } from '../../../ui/Surface';
import { Toolbar } from '../../../ui/Toolbar';

const FONT_SIZES = [
  { label: 'Smaller', value: '12px' },
  { label: 'Small', value: '14px' },
  { label: 'Medium', value: '' },
  { label: 'Large', value: '18px' },
  { label: 'Extra Large', value: '24px' },
];

export type FontSizePickerProps = {
  onChange: (value: string) => void; // eslint-disable-line no-unused-vars
  value: string;
};

export const FontSizePicker = ({ onChange, value }: FontSizePickerProps) => {
  const currentValue = FONT_SIZES.find((size) => size.value === value);
  const currentSizeLabel = currentValue?.label.split(' ')[0] || 'Medium';

  const selectSize = useCallback((size: string) => () => onChange(size), [onChange]);

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <Toolbar.Button active={!!currentValue?.value}>
          {currentSizeLabel}
          <Icon name="ChevronDown" className="w-2 h-2" />
        </Toolbar.Button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content asChild>
          <Surface className="flex flex-col gap-1 px-2 py-4">
            {FONT_SIZES.map((size) => (
              <DropdownButton
                isActive={value === size.value}
                onClick={selectSize(size.value)}
                key={`${size.label}_${size.value}`}
              >
                <span style={{ fontSize: size.value }}>{size.label}</span>
              </DropdownButton>
            ))}
          </Surface>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
