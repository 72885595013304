import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { TChangePasswordFormData } from 'src/@types';
import { authApi } from 'src/api';
import { PATH_AUTH } from 'src/routes/paths';

import { withRootStore } from '../extensions/withRootStore';
import { withSetPropAction } from '../extensions/withSetPropAction';
import { UserModel } from '../user/User';

/**
 * # AuthStore
 */
export const AuthStoreModel = types
  .model('AuthStore')
  .props({
    isAuthenticated: types.optional(types.boolean, false),
    isInitialized: types.optional(types.boolean, false),
    user: types.optional(types.maybeNull(UserModel), null),
  })
  .extend(withRootStore)
  .actions(withSetPropAction)
  .actions((self) => {
    /**
     * auth 정보 초기화
     */
    const initAuth = () => {
      // 인증 상태를 false로 변경한다.
      self.setProp('isAuthenticated', false);
      // 사용자 정보를 초기화한다.
      self.setProp('user', null);
    };

    return {
      initAuth,
    };
  })
  .actions((self) => {
    /**
     * 로그인
     * @param loginId 로그인 아이디
     * @param password 비밀번호
     */
    const login = async (userId: string, userPwd: string, rememberMe: boolean) => {
      try {
        const result = await authApi.login(userId, userPwd, rememberMe);

        // 로그인 성공 시
        if (result.data.success) {
          self.setProp('isAuthenticated', true);
          // 사용자 정보를 저장한다.
          getUserInfo();
        } else {
          // 로그인 실패 시
          self.rootStore.alertStore.setProps({
            open: true,
            category: 'error',
            title: '로그인 실패',
            content: result.data.message,
          });
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 로그아웃
     */
    const logout = async () => {
      try {
        await authApi.logout();
        // 인증 정보 초기화
        self.initAuth();
        window.location.href = PATH_AUTH.login;
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 비밀번호 변경
     * @param changePwdInfo 변경할 비밀번호 정보
     */
    const changePassword = async (changePwdInfo: TChangePasswordFormData) => {
      try {
        if (!self.user) return;
        const result = await authApi.changePassword(self.user.loginId, changePwdInfo);
        return result;
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 사용자 정보 조회
     */
    const getUserInfo = async () => {
      try {
        const result = await authApi.getUserInfo();
        if (result?.userInfo) {
          self.setProp('user', result.userInfo);
          // 인증 상태 변경
          if (!self.isAuthenticated) {
            self.setProp('isAuthenticated', true);
          }
        }

        // 초기화 상태 변경
        if (!self.isInitialized) {
          self.setProp('isInitialized', true);
        }
      } catch (error) {
        console.error(error);
      }
    };

    return {
      login,
      logout,
      changePassword,
      getUserInfo,
    };
  })
  .actions((self) => ({
    afterCreate() {
      // 사용자 정보 조회
      self.getUserInfo();
    },
  }));

type AuthStoreType = Instance<typeof AuthStoreModel>;
export interface AuthStore extends AuthStoreType {}
type AuthStoreSnapshotType = SnapshotOut<typeof AuthStoreModel>;
export interface AuthStoreSnapshot extends AuthStoreSnapshotType {}
