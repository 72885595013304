import { alpha, Button, IconButton, Stack, styled, Typography, useTheme } from '@mui/material';
import { IProject, IReport } from 'src/@types/apiResponseTypes';
import { reportApi } from 'src/api';
import { EditorView } from 'src/components/block-editor';
import Iconify from 'src/components/iconify';
import ReportComments from 'src/components/report-comments/ReportComments';
import { MultiFilePreview, TUploadFile } from 'src/components/upload';
import { useStores } from 'src/models';
import { fDate, fDateTime } from 'src/utils/formatTime';

/**
 * ## TableTypeWorkReportItem 설명
 * - 업무보고서 카드 컴포넌트
 * - 테이블 형식
 */
interface ITableTypeWorkReportItemProps {
  report: IReport;
  onCopyReport?: (reportSid: number) => void;
  onEditReport?: (reportSid: number) => void;
  handleWbs?: (project: IProject) => void;
  isMinimizeColumn: boolean;
  onExport?: (report: IReport) => void;
  viewProjectInfo?: (project: IProject) => void;
}
function TableTypeWorkReportItem({
  report,
  onCopyReport,
  onEditReport,
  handleWbs,
  isMinimizeColumn,
  onExport,
  viewProjectInfo,
}: ITableTypeWorkReportItemProps) {
  const theme = useTheme();
  const { authStore } = useStores();

  // 첨부파일 다운로드
  const handleDownloadFile = async (file: TUploadFile) => {
    try {
      if (typeof file === 'object' && 'fileSid' in file) {
        await reportApi.downloadFile(file);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container>
      <TopContainer>
        <Typography variant="h4" textAlign="center">
          {report.title}
          {/* 제출 전 보고서인 경우 표시 */}
          {report.regUser.userSid === authStore.user?.userSid && report.submitYn === false && (
            <BeforeSubmitTag>제출 전</BeforeSubmitTag>
          )}
        </Typography>
        <FlexRowContainer>
          <RegUserInfoContainer>
            <Stack>
              <Stack direction={'row'} alignItems={'baseline'} spacing={1}>
                <Typography variant="subtitle1" color={theme.palette.grey[900]}>
                  {report.regUser.userNm}
                </Typography>
                <Typography variant="caption" color={theme.palette.grey[800]}>
                  {fDateTime(report.regDt)}
                </Typography>
              </Stack>
              <Typography variant="caption" color={theme.palette.grey[800]}>
                {`comments: ${report.comments?.length || 0}`}
              </Typography>
            </Stack>
          </RegUserInfoContainer>
          <Stack direction={'row'} spacing={1}>
            {onCopyReport && (
              <Button
                size={'small'}
                color={'secondary'}
                variant={'outlined'}
                startIcon={<Iconify icon="mingcute:copy-fill" />}
                sx={{ ml: 1 }}
                onClick={() => onCopyReport(report.reportSid)}
              >
                복사
              </Button>
            )}
            {onExport && (
              <Button
                size={'small'}
                color={'success'}
                variant={'outlined'}
                startIcon={<Iconify icon="ri:file-excel-2-line" />}
                sx={{ ml: 1 }}
                onClick={() => onExport(report)}
              >
                저장
              </Button>
            )}
            {/* 작성자인 경우 수정버튼 노출 */}
            {onEditReport && report.regUser.userSid === authStore.user?.userSid && (
              <EditReportButton
                size={'small'}
                variant="outlined"
                onClick={() => onEditReport(report.reportSid)}
              >
                수정
              </EditReportButton>
            )}
          </Stack>
        </FlexRowContainer>
      </TopContainer>
      <TableContainer>
        <StyledTable>
          <TableHead>
            <TableHeadRow>
              <TableHeadCell>프로젝트명</TableHeadCell>
              {!isMinimizeColumn && (
                <>
                  <TableHeadCell>고객사</TableHeadCell>
                  <TableHeadCell>PM</TableHeadCell>
                  <TableHeadCell>시작일</TableHeadCell>
                  <TableHeadCell>종료일</TableHeadCell>
                </>
              )}
              <TableHeadCell>실적</TableHeadCell>
              <TableHeadCell>계획</TableHeadCell>
              <TableHeadCell>보고/이슈</TableHeadCell>
            </TableHeadRow>
          </TableHead>
          <TableBody>
            {report.contents?.map((content, index) => {
              const isEtc = !content.project;
              return (
                <TableBodyRow key={content.projectSid || index}>
                  <WidthFixedCell width={300} style={{ fontWeight: 'bold' }}>
                    {isEtc ? '기타' : content.project?.projectNm}
                    {handleWbs && !isEtc && (
                      <IconButton
                        onClick={() => {
                          handleWbs(content.project);
                        }}
                        sx={{ ml: 0 }}
                      >
                        <Iconify icon={'ion:calendar-outline'} />
                      </IconButton>
                    )}
                    {content.project?.projectSid && (
                      <IconButton
                        sx={{ width: 24, p: 0 }}
                        onClick={() => viewProjectInfo && viewProjectInfo(content.project)}
                      >
                        <Iconify icon="ic:outline-info" />
                      </IconButton>
                    )}
                  </WidthFixedCell>
                  {!isMinimizeColumn && (
                    <>
                      <WidthFixedCell style={{ paddingTop: '12px' }}>
                        {content.project?.customerCmpy?.company || '-'}
                      </WidthFixedCell>
                      <TableBodyCell style={{ paddingTop: '12px' }}>
                        {content.project?.pmList && content.project.pmList.length > 0
                          ? content.project?.pmList[0].empNm || '-'
                          : ''}
                      </TableBodyCell>
                      <TableBodyCell style={{ paddingTop: '12px' }}>
                        {fDate(content.project?.startDay) || '-'}
                      </TableBodyCell>
                      <TableBodyCell style={{ paddingTop: '12px' }}>
                        {fDate(content.project?.endDay) || '-'}
                      </TableBodyCell>
                    </>
                  )}
                  <EditorCell>
                    <EditorView content={content.content} />
                  </EditorCell>
                  <EditorCell>
                    <EditorView content={content.content2} />
                  </EditorCell>
                  <EditorCell>
                    <EditorView content={content.issueContent || ''} />
                  </EditorCell>
                </TableBodyRow>
              );
            })}
          </TableBody>
        </StyledTable>
      </TableContainer>
      {report.fileList.length > 0 && (
        <AttachmentFileListContainer>
          <StyledLabel>첨부된 파일 목록</StyledLabel>
          <MultiFilePreview files={report.fileList} onDownload={handleDownloadFile} />
        </AttachmentFileListContainer>
      )}
      <ReportComments
        reportSid={report.reportSid}
        sx={{ marginTop: 2 }}
        initComments={report.comments || undefined}
      />
    </Container>
  );
}

export default TableTypeWorkReportItem;

const StyledInnerContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  flex: 1,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  padding: `${theme.spacing(4)} ${theme.spacing(2)}`,
}));

const AttachmentFileListContainer = styled(StyledInnerContainer)(({ theme }) => ({
  padding: theme.spacing(2),
  marginTop: theme.spacing(3),
}));

const StyledLabel = styled('span')(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 700,
  padding: theme.spacing(0, 1),
  color: theme.palette.text.secondary,
  position: 'absolute',
  top: '-12px',
  left: '20px',
  background: theme.palette.background.paper,
}));

const Container = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  padding: theme.spacing(3, 2),
}));

const TopContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  borderBottom: `1px solid ${theme.palette.divider}`,
  position: 'relative',
}));

const EditReportButton = styled(Button)(({ theme }) => ({
  // right: theme.spacing(2),
  // top: theme.spacing(2),
}));

const TableContainer = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: '100%',
  overflowX: 'auto',
}));

const BeforeSubmitTag = styled('span')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  padding: theme.spacing(0.5, 1),
  fontSize: '0.75rem',
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.primary.main}`,
  marginLeft: theme.spacing(1),
}));

const StyledTable = styled('table')(({ theme }) => ({
  borderCollapse: 'collapse',
  width: '100%',
}));

const TableHead = styled('thead')(({ theme }) => ({
  backgroundColor: alpha(theme.palette.primary.main, 0.15),
}));

const TableHeadRow = styled('tr')(({ theme }) => ({}));

const TableHeadCell = styled('th')(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(1),
  borderLeft: `1px solid ${theme.palette.common.white}`,
  borderRight: `1px solid ${theme.palette.common.white}`,
  whiteSpace: 'nowrap' /* 텍스트 줄 바꿈 방지 */,
}));

const TableBody = styled('tbody')(({ theme }) => ({}));

const TableBodyRow = styled('tr')(({ theme }) => ({}));

const TableBodyCell = styled('td')(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'center',
  display: 'table-cell',
  verticalAlign: 'top',
  border: `1px solid ${theme.palette.divider}`,
  whiteSpace: 'nowrap' /* 텍스트 줄 바꿈 방지 */,
  minWidth: 100,
}));

const WidthFixedCell = styled(TableBodyCell, {
  shouldForwardProp: (prop) => prop !== 'width',
})<{ width?: number }>(({ theme, width }) => ({
  width: width || 200,
  minWidth: width || 200,
  overflowWrap: 'break-word',
  whiteSpace: 'normal' /* 줄 바꿈을 허용 */,
}));

const EditorCell = styled(TableBodyCell)(({ theme }) => ({
  minWidth: 250,
  maxWidth: 400,
  textAlign: 'left',
}));

const FlexRowContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const RegUserInfoContainer = styled('div')(({ theme }) => ({}));
