import React, { Dispatch, SetStateAction } from 'react';
import { TreeViewProps } from '@mui/lab';
import { styled } from '@mui/material';
import { TreeViewBaseItem } from '@mui/x-tree-view/models';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';

import CheckboxTreeItem from './CheckboxTreeItem';

interface CheckboxTreeProps extends TreeViewProps<true> {
  items: TreeViewBaseItem[];
  selectedItemIds: string[];
  setSelectedItemIds: Dispatch<SetStateAction<string[]>>;
}

const CheckboxTree = ({
  items,
  selectedItemIds,
  setSelectedItemIds,
  ...props
}: CheckboxTreeProps) => {
  // 선택된 아이템 토글 핸들러
  const handleItemSelectionToggle = (
    e: React.SyntheticEvent<Element, Event>,
    selected: string[] | string | null,
  ) => {
    if (typeof selected === 'string') {
      const isSelected = selectedItemIds.some((sid) => sid === selected);
      if (isSelected) {
        setSelectedItemIds(selectedItemIds.filter((sid) => sid !== selected));
      } else {
        setSelectedItemIds([...selectedItemIds, selected]);
      }
    }
  };

  return (
    <StyledTreeView
      items={items}
      selectedItems={selectedItemIds}
      onSelectedItemsChange={handleItemSelectionToggle}
      slots={{
        item: CheckboxTreeItem,
      }}
      slotProps={{
        item: (props) => {
          return {
            isSelect: selectedItemIds.includes(props.itemId),
          } as any;
        },
      }}
      {...props}
    />
  );
};

export default CheckboxTree;

const StyledTreeView = styled(RichTreeView)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(1),
}));
