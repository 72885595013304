import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface CyclingTypingAnimationProps {
  texts: string[];
  typingSpeed?: number;
  deletingSpeed?: number;
  delayBetweenTexts?: number;
  style?: React.CSSProperties;
}

const BlinkingCursor = styled('span')({
  borderRight: '2px solid',
  animation: 'blink 1s step-end infinite',
  '@keyframes blink': {
    'from, to': { borderColor: 'transparent' },
    '50%': { borderColor: 'inherit' },
  },
});

const CyclingTypingAnimation: React.FC<CyclingTypingAnimationProps> = ({
  texts,
  typingSpeed = 100,
  deletingSpeed = 50,
  delayBetweenTexts = 2000,
  style,
}) => {
  const [displayedText, setDisplayedText] = useState('');
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [isTyping, setIsTyping] = useState(true);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (isWaiting) {
      timeout = setTimeout(() => {
        setIsWaiting(false);
        setIsDeleting(true);
        setIsTyping(true);
      }, delayBetweenTexts);
      return () => clearTimeout(timeout);
    }

    if (isDeleting) {
      if (displayedText === '') {
        setIsDeleting(false);
        const nextIndex = (currentTextIndex + 1) % texts.length;
        setCurrentTextIndex(nextIndex);
        setIsTyping(true);
      } else {
        timeout = setTimeout(() => {
          setDisplayedText((prev) => prev.slice(0, -1));
        }, deletingSpeed);
      }
    } else {
      const currentFullText = texts[currentTextIndex];
      if (displayedText === currentFullText) {
        setIsTyping(false);
        setIsComplete(true);
        setIsWaiting(true);
      } else {
        setIsComplete(false);
        timeout = setTimeout(() => {
          setDisplayedText(currentFullText.slice(0, displayedText.length + 1));
        }, typingSpeed);
      }
    }

    return () => clearTimeout(timeout);
  }, [
    displayedText,
    currentTextIndex,
    isDeleting,
    isWaiting,
    texts,
    typingSpeed,
    deletingSpeed,
    delayBetweenTexts,
  ]);

  return (
    <Box
      sx={{
        display: 'inline-block',
        fontFamily: 'monospace',
        minHeight: '1.5em',
        ...style,
      }}
    >
      <Typography variant="h4" color="#333">
        {displayedText}
        {(!isComplete || isTyping) && <BlinkingCursor>|</BlinkingCursor>}
      </Typography>
    </Box>
  );
};

export default CyclingTypingAnimation;
