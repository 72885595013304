import { ChatbotSettingSearchParams } from 'src/@types';
import { ChatbotSystemSettingsApiResponse, VectorDB } from 'src/@types/apiResponseTypes';

import { chatbotStreamInstance } from '../axios';

const SUB_URL = '/v1/setting';

export const getVdbs = async ({ keyword, page = 1, size = 10 }: ChatbotSettingSearchParams) => {
  try {
    const url = `${SUB_URL}/vdbs`;
    const response = await chatbotStreamInstance.get<{ vdbs: VectorDB[] }>(url, {
      params: {
        keyword,
        page,
        size,
      },
    });

    if (response.status === 200 && response.data) {
      return response.data.vdbs;
    }
  } catch (error) {
    console.error(error);
  }
};

export const findVdb = async (vdb_id: number) => {
  try {
    const url = `${SUB_URL}/vdb/${vdb_id}`;
    const response = await chatbotStreamInstance.get<VectorDB>(url);

    if (response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
};

export const deleteVdb = async (vdb_id: number) => {
  try {
    const url = `${SUB_URL}/vdb/${vdb_id}`;
    const response = await chatbotStreamInstance.delete<ChatbotSystemSettingsApiResponse>(url);

    if (response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
};

export const addVdb = async (vdb: VectorDB) => {
  try {
    const url = `${SUB_URL}/vdb`;
    const response = await chatbotStreamInstance.post<ChatbotSystemSettingsApiResponse>(url, vdb);

    if (response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateVdb = async (vdb: VectorDB) => {
  try {
    const url = `${SUB_URL}/vdb`;
    const response = await chatbotStreamInstance.put<ChatbotSystemSettingsApiResponse>(url, vdb);

    if (response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
};

// 101 = 벡터 DB, 102 = RDB
type DBCode = 101 | 102;

export const getDbCode = async (db_code: DBCode) => {
  const url = `/v1/common/${db_code}`;
  try {
    const response = await chatbotStreamInstance.get<string[]>(url);

    if (response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
};
