import { useStores } from 'src/models';
import { DOCU_TYPE_CODE } from 'src/static/constants';

// default 업무보고 문서유형 코드
export const DEFAULT_WORK_DOCU_CODE = DOCU_TYPE_CODE.WEEKLY;

type ReturnType = {
  docuTypeOptions: { label: string; value: number }[];
};

/**
 * 문서유형 관련 커스텀 훅
 */
function useDocuType(): ReturnType {
  const { commonCodeStore } = useStores();

  // stored docuTypeCd
  // const [_, setStoredDocuTypeCode] = useLocalStorage(LOCAL_STORAGE_KEY.DOCU_TYPE_CODE, undefined);

  // 문서유형 코드
  const DOCU_TYPE_CD_LIST = commonCodeStore.getCCodeByName('DOCU_TYPE_CD');

  // 회의록을 제외한 문서유형 옵션
  const docuTypeOptions = DOCU_TYPE_CD_LIST.filter(
    ({ code }) => code !== DOCU_TYPE_CODE.MEETING,
  ).map((docuTypeCd) => ({
    label: docuTypeCd.value,
    value: docuTypeCd.code,
  }));

  return {
    docuTypeOptions,
  };
}

export default useDocuType;
