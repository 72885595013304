import { forwardRef, memo } from 'react';
// @mui
import { Box } from '@mui/material';

//
import { StyledRootScrollbar, StyledScrollbar } from './styles';
import { ScrollbarProps } from './types';

// ----------------------------------------------------------------------

function Scrollbar({ children, sx, ...other }: ScrollbarProps, ref: any) {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;

  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

  if (isMobile) {
    return <Box sx={{ overflowX: 'auto', height: '100%', ...sx }}>{children}</Box>;
  }

  return (
    <StyledRootScrollbar>
      <StyledScrollbar ref={ref} timeout={500} clickOnTrack={false} sx={sx} {...other}>
        {children}
      </StyledScrollbar>
    </StyledRootScrollbar>
  );
}

export default memo(forwardRef(Scrollbar));
