import { useState } from 'react';

export interface ToggleDrawerReturnType {
  openDrawer: boolean;
  handleDrawerOpen: () => void;
  handleDrawerClose: () => void;
}

const useToggleDrawer = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };
  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  return { openDrawer, handleDrawerOpen, handleDrawerClose };
};

export default useToggleDrawer;
