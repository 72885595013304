// @mui
import { Box, BoxProps, styled } from '@mui/material';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import { HEADER } from 'src/static/constants';

const Container = styled(Box)(({ theme }) => ({
  height: '100%',
  paddingTop: `${HEADER.H_DASHBOARD_SCREEN_HEADER_MOBILE}px`,

  // desktop style
  [theme.breakpoints.up('lg')]: {
    paddingTop: `${HEADER.H_DASHBOARD_SCREEN_HEADER_DESKTOP}px`,
  },
}));

const StyledScrollbar = styled(Scrollbar)(({ theme }) => ({
  height: '100%',

  '& .simplebar-content': {
    height: '100%',
  },
}));

interface MainProps extends BoxProps {
  scrollRef: React.RefObject<HTMLDivElement>;
}

const Main = ({ scrollRef, children, ...other }: MainProps) => {
  return (
    <Container component="main" {...other}>
      <StyledScrollbar scrollableNodeProps={{ ref: scrollRef }}>{children}</StyledScrollbar>
    </Container>
  );
};

export default Main;
