import {
  Button,
  Card,
  IconButton,
  Stack,
  styled,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { IProject, IReport } from 'src/@types/apiResponseTypes';
import { reportApi } from 'src/api';
import { EditorView } from 'src/components/block-editor';
import Iconify from 'src/components/iconify/Iconify';
import ReportComments from 'src/components/report-comments/ReportComments';
import { MultiFilePreview, TUploadFile } from 'src/components/upload';
import { useStores } from 'src/models';
import { fDateTime } from 'src/utils/formatTime';

/**
 * ## PostTypeWorkReportItem 설명
 * - 업무보고서 카드 컴포넌트
 * - 포스트 카드 형식
 */
interface IPostTypeWorkReportItemProps {
  report: IReport;
  onCopyReport?: (reportSid: number) => void;
  onEditReport?: (reportSid: number) => void;
  handleWbs?: (project: IProject) => void;
  onExport?: (report: IReport) => void;
  viewProjectInfo?: (project: IProject) => void;
}
function PostTypeWorkReportItem({
  report,
  onCopyReport,
  onEditReport,
  handleWbs,
  onExport,
  viewProjectInfo,
}: IPostTypeWorkReportItemProps) {
  const theme = useTheme();
  const { authStore } = useStores();
  // 이슈 내용이 있는지 확인
  const hasIssue = report.contents?.some((content) => content.issueContent);

  // 첨부파일 다운로드
  const handleDownloadFile = async (file: TUploadFile) => {
    try {
      if (typeof file === 'object' && 'fileSid' in file) {
        await reportApi.downloadFile(file);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <StyledCard>
      <StyledCardHeader>
        <ReportInfo>
          <Typography variant="h5" sx={{ display: 'flex', alignItems: 'center' }}>
            {report.title}
            {/* 제출 전 보고서인 경우 표시 */}
            {report.regUser.userSid === authStore.user?.userSid && report.submitYn === false && (
              <BeforeSubmitTag>제출 전</BeforeSubmitTag>
            )}
          </Typography>
          <Stack>
            <Stack direction={'row'} alignItems={'baseline'} spacing={1}>
              <Typography variant="subtitle1" color={theme.palette.grey[900]}>
                {report.regUser.userNm}
              </Typography>
              <Typography variant="caption" color={theme.palette.grey[800]}>
                {fDateTime(report.regDt)}
              </Typography>
            </Stack>
            <Typography variant="caption" color={theme.palette.grey[800]}>
              {`comments: ${report.comments?.length || 0}`}
            </Typography>
          </Stack>
        </ReportInfo>
        <Stack direction={'row'} spacing={1}>
          {onCopyReport && (
            <Button
              size={'small'}
              color={'secondary'}
              variant={'outlined'}
              startIcon={<Iconify icon="mingcute:copy-fill" />}
              sx={{ ml: 1, px: 1 }}
              onClick={() => onCopyReport(report.reportSid)}
            >
              복사
            </Button>
          )}
          {onExport && (
            <Button
              size={'small'}
              color={'success'}
              variant={'outlined'}
              startIcon={<Iconify icon="ri:file-excel-2-line" />}
              sx={{ ml: 1, px: 1 }}
              onClick={() => onExport(report)}
            >
              저장
            </Button>
          )}
          {onEditReport && report.regUser.userSid === authStore.user?.userSid && (
            <EditReportButton
              size={'small'}
              variant="outlined"
              onClick={() => onEditReport(report.reportSid)}
            >
              수정
            </EditReportButton>
          )}
        </Stack>
      </StyledCardHeader>
      <CardContent>
        <ReportContentListContainer>
          <StyledInnerContainer color={theme.palette.success.main}>
            <StyledLabel color={theme.palette.success.main}>실적</StyledLabel>
            <ReportContentContainer>
              {report.contents?.map((content, index) => {
                const isEtc = !content.project;
                return (
                  <ContentItem key={'report-content-' + index}>
                    <ProjectTitle>
                      {isEtc ? '기타' : content.project.projectNm ?? ''}
                      {handleWbs && !isEtc && (
                        <IconButton
                          onClick={() => {
                            handleWbs(content.project);
                          }}
                          sx={{ ml: 1, width: 24, p: 0 }}
                        >
                          <Iconify icon={'ion:calendar-outline'} />
                        </IconButton>
                      )}
                      {content.project?.projectSid && (
                        <>
                          <Tooltip arrow title={content.project.mngList?.map((m) => m.empNm + ',')}>
                            <IconButton sx={{ width: 24, p: 0 }}>
                              <Iconify icon="ic:outline-people" />
                            </IconButton>
                          </Tooltip>
                          {viewProjectInfo && (
                            <IconButton
                              sx={{ width: 24, p: 0 }}
                              onClick={() => viewProjectInfo(content.project)}
                            >
                              <Iconify icon="ic:outline-info" />
                            </IconButton>
                          )}
                        </>
                      )}
                    </ProjectTitle>
                    <EditorView content={content.content} />
                  </ContentItem>
                );
              })}
            </ReportContentContainer>
          </StyledInnerContainer>
          <StyledInnerContainer color={theme.palette.info.main}>
            <StyledLabel color={theme.palette.info.main}>계획</StyledLabel>
            {report.contents?.map((content, index) => {
              return (
                <ContentItem key={'report-content-other-' + index}>
                  <ProjectTitle>{content.project?.projectNm || '기타'}</ProjectTitle>
                  <EditorView content={content.content2} />
                </ContentItem>
              );
            })}
          </StyledInnerContainer>
        </ReportContentListContainer>
        {hasIssue && (
          <ReportContentListContainer>
            <StyledInnerContainer color={theme.palette.error.main}>
              <StyledLabel color={theme.palette.error.main}>보고/이슈</StyledLabel>
              <ReportContentContainer>
                {report.contents?.map((content, index) => {
                  if (content.issueContent) {
                    return (
                      <ContentItem key={'report-content-other-' + index}>
                        <ProjectTitle>{content.project?.projectNm || '기타'}</ProjectTitle>
                        <EditorView content={content.issueContent || ''} />
                      </ContentItem>
                    );
                  }
                })}
              </ReportContentContainer>
            </StyledInnerContainer>
          </ReportContentListContainer>
        )}
        {report.fileList.length > 0 && (
          <AttachmentFileListContainer>
            <StyledLabel>첨부된 파일 목록</StyledLabel>
            <MultiFilePreview files={report.fileList} onDownload={handleDownloadFile} />
          </AttachmentFileListContainer>
        )}
        <ReportComments reportSid={report.reportSid} initComments={report.comments || undefined} />
      </CardContent>
    </StyledCard>
  );
}

export default PostTypeWorkReportItem;

const StyledCard = styled(Card)(({ theme }) => ({}));

const StyledCardHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'top',
  padding: theme.spacing(2),
}));

const BeforeSubmitTag = styled('span')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  padding: theme.spacing(0.5, 1),
  fontSize: '0.75rem',
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.primary.main}`,
  marginLeft: theme.spacing(1),
}));

const ReportInfo = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const EditReportButton = styled(Button)(({ theme }) => ({}));

const CardContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2),
  gap: theme.spacing(3),
}));

const ReportContentListContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),

  // md down
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const ReportContentContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  flex: 1,
}));

const ContentItem = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const ProjectTitle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  fontSize: '1rem',
  fontWeight: 700,
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.grey[200],
  border: '1px solid ' + theme.palette.divider,
  whiteSpace: 'balance',
  alignItems: 'flex-start',
  // maxWidth: '70%',
}));

const StyledInnerContainer = styled('div')(({ theme, color }) => ({
  position: 'relative',
  flex: 1,
  border: `1px solid ${color || theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  padding: `${theme.spacing(4)} ${theme.spacing(2)}`,
}));

const AttachmentFileListContainer = styled(StyledInnerContainer)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const StyledLabel = styled('span')(({ theme, color }) => ({
  fontSize: '1rem',
  fontWeight: 700,
  padding: theme.spacing(0, 1),
  color: color || theme.palette.text.secondary,
  position: 'absolute',
  top: '-12px',
  left: '20px',
  background: theme.palette.background.paper,
}));
