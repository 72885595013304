import { useEffect, useRef } from 'react';
import { Box, Chip, List, ListItem, styled } from '@mui/material';
import { AIResponse, ChatbotFAQ } from 'src/@types/apiResponseTypes';
import { EditorView } from 'src/components/block-editor';

import LoadingDots from '../../utils/Animations/LoadingDots';
import { SmileMessageIcon } from '../../utils/Icons';
import { ConversationType, isChatbotFAQType, isConversationTypeArray } from '../../utils/types';
import MessageInputArea from '../message-input-area';
import InitialScreenInputArea from '../message-input-area/InitialScreenInputArea';
import NewChatButton from '../NewChatButton';

import CustomReactMarkdown from './CustomReactMarkdown';
import FaqTagRecommender from './FaqTagRecommender';
import InitialChatWindow from './InitialChatWindow';
import Skeleton from './Skeleton';

interface ChatWindowProps {
  isStreaming: boolean;
  aiResponse: AIResponse;
  aiResponseStatus: { isAITyping: boolean; error: string | null };
  chatFaqs: ChatbotFAQ[];
  chatRoom: Array<ConversationType> | ChatbotFAQ;
  currentChatRoomId: string;
  resizableChatWindowWidth: number;
  onAddNewChatRoom: () => void;
  onSendMessageToAI: (response: string) => Promise<void>;
  onClose: () => void;
}

function ChatWindow({
  isStreaming,
  aiResponse,
  aiResponseStatus,
  chatFaqs,
  chatRoom,
  currentChatRoomId,
  resizableChatWindowWidth,
  onAddNewChatRoom,
  onSendMessageToAI,
  onClose,
}: ChatWindowProps) {
  const { response: aiCurrentResponse } = aiResponse;
  const { isAITyping, error } = aiResponseStatus;

  const isAiProcessing = isAITyping || isStreaming;

  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: isStreaming ? 'auto' : 'smooth' });
  };

  const isConversationType = isConversationTypeArray(chatRoom);
  const isInnerwaveGuide = isChatbotFAQType(chatRoom);

  useEffect(() => {
    scrollToBottom();
  }, [chatRoom, currentChatRoomId, isAITyping, isStreaming]);

  if (isConversationType) {
    // 채팅방이 새로 추가 되었거나 삭제되었을 때 초기 화면을 보여주기
    if (chatRoom.length === 0) {
      return (
        <InitialChatWindow
          resizableChatWindowWidth={resizableChatWindowWidth}
          MessageInput={
            <InitialScreenInputArea
              resizableChatWindowWidth={resizableChatWindowWidth}
              isAiProcessing={isAiProcessing}
              onSendMessageToAI={onSendMessageToAI}
            />
          }
          FaqTagRecommender={
            <FaqTagRecommender chatFaqs={chatFaqs} onSendMessageToAI={onSendMessageToAI} />
          }
          onClose={onClose}
        />
      );
    }
    // 채팅 초기 화면이 아닐 경우
    return (
      <Skeleton
        resizableChatWindowWidth={resizableChatWindowWidth}
        bottomComponent={
          <MessageInputArea isAiProcessing={isAiProcessing} onSendMessageToAI={onSendMessageToAI} />
        }
        onClose={onClose}
      >
        <List>
          {error && <ListItem>{error}</ListItem>}
          {chatRoom.map((conv, index) => (
            <div key={index}>
              {conv.userMessage.user_input && (
                <StyledListItem sx={{ display: 'flex', columnGap: '7px', fontWeight: 'bold' }}>
                  <SmileMessageIcon /> {conv.userMessage.user_input}
                </StyledListItem>
              )}
              {conv.aiResponse.response && (
                <StyledListItem
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    borderBottom: 0,
                  }}
                >
                  {conv.aiResponse.is_faq ? (
                    <EditorView key={conv.aiResponse.chat_id} content={conv.aiResponse.response} />
                  ) : (
                    <CustomReactMarkdown text={conv.aiResponse.response} />
                  )}
                </StyledListItem>
              )}
            </div>
          ))}
          {!error && isStreaming && (
            <StyledListItem
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                borderBottom: 0,
              }}
            >
              {aiResponse.is_faq ? (
                <EditorView key={aiCurrentResponse} content={aiCurrentResponse} />
              ) : (
                <CustomReactMarkdown text={aiCurrentResponse} />
              )}
            </StyledListItem>
          )}
          {isAITyping && (
            <StyledListItem>
              잠시만요 알맞은 답변을 생각하고 있어요
              <LoadingDots />
            </StyledListItem>
          )}
        </List>
        {/* 스크롤을 밑으로 가게 해주는 엘리먼트 */}
        <div ref={messagesEndRef} />
      </Skeleton>
    );
  }

  if (isInnerwaveGuide) {
    const { tag, answer } = chatRoom;

    return (
      <Skeleton
        resizableChatWindowWidth={resizableChatWindowWidth}
        bottomComponent={
          <Box sx={{ padding: '16px 12px' }}>
            <NewChatButton disabled={isAiProcessing} onClick={onAddNewChatRoom} />
          </Box>
        }
        onClose={onClose}
      >
        <ListItem sx={{ padding: '8px 16px' }}>
          {/* key값을 주어 강제로 리렌더링을 시켜서 업데이트된 정보를 화면에 보이게 함. */}
          <EditorView key={answer} content={answer} />
        </ListItem>
        <ListItem sx={{ padding: '8px 16px', width: '100%', display: 'flex', flexWrap: 'wrap' }}>
          {tag
            ? tag.split(',').map((t) => (
                <Chip
                  key={t}
                  sx={{ margin: '3px' }}
                  size="medium"
                  label={`# ${t}`}
                  onClick={() => {
                    console.log('hihi');
                  }}
                />
              ))
            : null}
        </ListItem>
      </Skeleton>
    );
  }

  return (
    <Skeleton
      resizableChatWindowWidth={resizableChatWindowWidth}
      bottomComponent={
        <MessageInputArea isAiProcessing={isAiProcessing} onSendMessageToAI={onSendMessageToAI} />
      }
    >
      <InitialChatWindow resizableChatWindowWidth={resizableChatWindowWidth} onClose={onClose} />
    </Skeleton>
  );
}

const StyledListItem = styled(ListItem)(() => ({
  padding: '8px 16px',
  borderBottom: '1px solid #B9B9B9',
}));

export default ChatWindow;
