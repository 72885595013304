import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { useGridSelector } from '@mui/x-data-grid';
import {
  gridPageSizeSelector,
  gridRowCountSelector,
  GridSlotsComponentsProps,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import { ROWS_PER_PAGE_OPTIONS } from 'src/static/constants';

import CPagination from './CPagination';

/**
 * # CGridFooter 설명
 * - DataGridPro 컴포넌트의 커스텀 푸터 컴포넌트
 * - 전체 건수, 페이징 컴포넌트를, rowsPerPage select 컴포넌트를 포함한다.
 */
export default function CGridFooter({
  isPagination = true,
}: NonNullable<GridSlotsComponentsProps['footer']>) {
  const apiRef = useGridApiContext();
  const rowsCount = useGridSelector(apiRef, gridRowCountSelector);
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);

  // DataGridProApiRef가 없을 경우, 빈 컴포넌트 반환
  if (!apiRef.current) return <></>;

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const newPageSize = event.target.value as number;
    apiRef.current.setPageSize(newPageSize);
  };

  return (
    <StyledCGridFooterContainer>
      {/* 리스트 전체 건수 텍스트 */}
      <TotalText>{`전체 ${rowsCount.toLocaleString()}건`}</TotalText>
      <Stack
        flexDirection="row"
        alignItems="center"
        gap={1}
        flexWrap="wrap"
        justifyContent="flex-end"
      >
        {/* 페이지 사이즈 선택 */}
        {isPagination && (
          <>
            {/* 커스텀 페이지네이션 */}
            <CPagination />
            <FormControl>
              <InputLabel id="page-size">Page Size</InputLabel>
              <RowsPerPageSelect
                labelId="page-size"
                label="Page Size"
                value={pageSize}
                onChange={handleChange}
                size="small"
              >
                {ROWS_PER_PAGE_OPTIONS.map((option) => (
                  <MenuItem value={option.value} key={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </RowsPerPageSelect>
            </FormControl>
          </>
        )}
      </Stack>
    </StyledCGridFooterContainer>
  );
}

const StyledCGridFooterContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: theme.spacing(1),
  padding: theme.spacing(1, 0),
}));

const TotalText = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  minWidth: 120,
  color: theme.palette.text.primary,
}));

const RowsPerPageSelect = styled(Select)(({ theme }) => ({
  minWidth: 120,
}));
