import { Stack, styled, Typography, useTheme } from '@mui/material';
import Logo from 'src/components/logo/Logo';
import { Page } from 'src/components/Page';
import LoginLayout from 'src/layouts/login/LoginLayout';

import LoginForm from './LoginForm';

// ----------------------------------------------------------------------
const LoginContainer = styled(Stack)(({ theme }) => ({
  alignItems: 'center',

  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
  },
}));

// ----------------------------------------------------------------------
export default function Login() {
  const { palette } = useTheme();
  return (
    <Page title="Login Page" needPadding={false}>
      <LoginLayout>
        <LoginContainer sx={{ mb: 5 }}>
          <Logo />
        </LoginContainer>
        <Stack sx={{ mb: 5, position: 'relative' }}>
          <Typography variant="h4">Sign in to Works</Typography>
          <Typography variant="subtitle2" color={palette.grey[500]}>
            Innerwave Work Management System
          </Typography>
        </Stack>
        <LoginForm />
      </LoginLayout>
    </Page>
  );
}
