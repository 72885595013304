import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import { Box, Chip, IconButton, styled, Tooltip } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { TFormType, TMeetingReportFormData } from 'src/@types';
import { IAttachment, IReport } from 'src/@types/apiResponseTypes';
import { projectApi, reportApi } from 'src/api';
import { BlockEditor, useBlockEditor } from 'src/components/block-editor';
import { parseContent, stringifyContent } from 'src/components/block-editor/useBlockEditor';
import { RHFAutocomplete, RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/FormProvider';
import { RHFDateTimeRangePicker } from 'src/components/hook-form/RHFDatePicker';
import { RHFUpload } from 'src/components/hook-form/RHFUpload';
import RHFEmployeeSelect from 'src/components/RHFEmployeeSelect';
import { MultiFilePreview, TUploadFile } from 'src/components/upload';
import { contentImageStore } from 'src/models';
import meetingReportFormValidationSchema from 'src/static/yup/meetingReportFormValidationSchema';
import { getMeetingReportFormDefaultValues } from 'src/utils/getRHFDefaultValues';

import ApplyTemplateToEditorModal, {
  IApplyTemplateToEditorModalProps,
} from '../../sections/ApplyTemplateToEditorModal';

/**
 * ## MeetingReportForm 설명
 * - 회의록 등록/수정 폼
 * - useForm, yup, Block Editor, Upload 등 컴포넌트 사용
 */
export interface IMeetingReportFormProps {
  formType: TFormType;
  formId: string;
  report?: IReport;
  onSubmit?: (data: TMeetingReportFormData) => void;
  onRemoveFile?: (file: TUploadFile) => void;
  onDownloadFile?: (file: TUploadFile) => void;
  reportFiles?: IAttachment[];
}

function MeetingReportForm({
  formType,
  formId,
  report,
  onSubmit,
  reportFiles,
  onRemoveFile,
  onDownloadFile,
}: IMeetingReportFormProps) {
  const readonly = formType === 'READONLY';
  // yup resolver
  const resolver = yupResolver(meetingReportFormValidationSchema());
  // useForm methods
  const methods = useForm<TMeetingReportFormData>({
    defaultValues: getMeetingReportFormDefaultValues(report),
    resolver,
  });

  // Block Editor 커스텀 훅
  const { editor } = useBlockEditor({
    editable: readonly ? false : true,
    content: parseContent(report?.content),
  });

  const watchExtrnList = methods.watch('meeting.extrnList');

  // select template modal info state
  const [applyTemplateToEditorModalInfo, setApplyTemplateToEditorModalInfo] =
    useState<IApplyTemplateToEditorModalProps>({ open: false });
  // tagList options
  const [tagListOptions, setTagListOptions] = useState<string[]>([]);

  // get tagList options
  const getTagListOptions = useCallback(async () => {
    try {
      const result = await projectApi.getProjects();
      if (result) {
        setTagListOptions([
          '주간회의',
          '월간회의',
          ...result.data.map((project) => project.projectNm),
        ]);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    // contentImageStore 초기화
    contentImageStore.clear();

    // tagList options 조회
    getTagListOptions();
  }, []);

  // 현재 에디터에 포함된 이미지 src를 배열에 담고, 업로드한 이미지들 중 삭제된 이미지를 찾아 삭제함
  const handleDeleteImage = async () => {
    const uploadedImagePaths = contentImageStore.paths;

    // 에디터에 존재하는 이미지 src를 배열에 담는다.
    const editorImagePaths: string[] = [];
    editor?.state.doc.forEach((node) => {
      if (node.type.name === 'imageBlock') {
        editorImagePaths.push(node.attrs.src);
      }
    });

    // 업로드된 이미지들 중에서 현재 에디터에 없는 이미지 => 삭제된 이미지를 찾는다.
    const deletedImagePaths = uploadedImagePaths.filter(
      (path) => !editorImagePaths.some((pathString) => pathString.includes(path)),
    );

    if (deletedImagePaths.length > 0) {
      try {
        await reportApi.deleteContentImage(deletedImagePaths);
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <>
      <StyledFormProvider
        methods={methods}
        onSubmit={methods.handleSubmit(() => {
          // editor에 입력된 content를 string으로 변환하여 form data에 추가
          const content = stringifyContent(editor?.getJSON());
          methods.setValue('content', content);

          // 에디터에서 삭제된 이미지를 찾아 삭제 요청
          handleDeleteImage();

          // onSubmit 함수 호출
          onSubmit && onSubmit(methods.getValues());
        })}
        formId={formId}
      >
        <RHFTextField
          name="title"
          label="제목"
          placeholder="제목을 입력하세요."
          disabled={readonly}
        />
        {/* 회의시간 */}
        <StyledInnerFormContainer>
          <StyledLabel>회의시간</StyledLabel>
          <RHFDateTimeRangePicker name="meeting.meetingTime" disabled={readonly} />
        </StyledInnerFormContainer>
        <FlexContainer>
          {/* 참여자 */}
          <RHFEmployeeSelect name="meeting.members" label="참여자" disabled={readonly} />
          {/* 외부 참여자 */}
          <RHFAutocomplete
            name="meeting.extrnList"
            label="외부 참여자"
            freeSolo
            fullWidth
            options={[]}
            value={watchExtrnList.map((extrn) => extrn.extrnName)}
            onChange={(event, value) => {
              methods.setValue(
                'meeting.extrnList',
                value.map((extrnName) => ({ extrnName })),
              );
            }}
            renderTags={(value: readonly string[], getTagProps) =>
              value.map((option: string, index: number) => {
                const { key, ...tagProps } = getTagProps({ index });
                return <Chip variant="outlined" label={option} key={key} {...tagProps} />;
              })
            }
            multiple
            disabled={readonly}
          />
        </FlexContainer>
        {/* 구분 */}
        <RHFAutocomplete
          name="tagList"
          label="구분"
          freeSolo
          fullWidth
          options={tagListOptions}
          renderTags={(value: readonly string[], getTagProps) =>
            value.map((option: string, index: number) => {
              const { key, ...tagProps } = getTagProps({ index });
              return <Chip variant="outlined" label={option} key={key} {...tagProps} />;
            })
          }
          multiple
          disabled={readonly}
        />
        {/* Content */}
        <EditorContainer>
          {!readonly && (
            <Tooltip title="템플릿 적용">
              <SelectTemplateButton
                color="primary"
                disabled={readonly}
                onClick={() =>
                  setApplyTemplateToEditorModalInfo({
                    open: true,
                    editor,
                    onClose: () =>
                      setApplyTemplateToEditorModalInfo({
                        open: false,
                      }),
                  })
                }
              >
                <DashboardCustomizeIcon />
              </SelectTemplateButton>
            </Tooltip>
          )}
          <StyledLabel>내용</StyledLabel>
          <BlockEditor editor={editor} />
        </EditorContainer>
        {!readonly && (
          <StyledInnerFormContainer>
            <StyledLabel>첨부파일 업로드</StyledLabel>
            <RHFUpload
              name="files"
              multiple
              disabled={readonly}
              accept={{
                'image/*': [],
                'text/*': [],
                'application/pdf': [],
                'application/msword': [],
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
              }}
            />
          </StyledInnerFormContainer>
        )}
        {/* 수정 시 업로드된 첨부파일 리스트 */}
        {reportFiles && reportFiles.length > 0 && (
          <StyledInnerFormContainer>
            <StyledLabel>첨부된 파일 목록</StyledLabel>
            <MultiFilePreview
              files={reportFiles}
              onRemove={onRemoveFile}
              onDownload={onDownloadFile}
            />
          </StyledInnerFormContainer>
        )}
      </StyledFormProvider>
      {/* Select template modal */}
      <ApplyTemplateToEditorModal {...applyTemplateToEditorModalInfo} />
    </>
  );
}

export default observer(MeetingReportForm);

const StyledFormProvider = styled(FormProvider)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

const StyledInnerFormContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
}));

const EditorContainer = styled(StyledInnerFormContainer)(({ theme }) => ({
  padding: 0,
}));

const FlexContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
}));

const StyledLabel = styled('span')(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 700,
  padding: theme.spacing(0, 1),
  color: theme.palette.text.secondary,
  position: 'absolute',
  top: '-12px',
  left: '20px',
  background: theme.palette.background.paper,
}));

const SelectTemplateButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '12px',
  right: '20px',
  zIndex: 100,
}));
