import { useEffect, useState } from 'react';
import * as Popover from '@radix-ui/react-popover';
import { Editor } from '@tiptap/react';
import DragHandle from '@tiptap-pro/extension-drag-handle-react';

import { DropdownButton } from '../../ui/Dropdown';
import { Icon } from '../../ui/Icon';
import { Surface } from '../../ui/Surface';
import { Toolbar } from '../../ui/Toolbar';

import useContentItemActions from './hooks/useContentItemActions';
import { useData } from './hooks/useData';

export type ContentItemMenuProps = {
  editor: Editor;
};

export const ContentItemMenu = ({ editor }: ContentItemMenuProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const data = useData();
  const actions = useContentItemActions(editor, data.currentNode, data.currentNodePos);

  useEffect(() => {
    if (menuOpen) {
      editor.commands.setMeta('lockDragHandle', true);
    } else {
      editor.commands.setMeta('lockDragHandle', false);
    }
  }, [editor, menuOpen]);

  if (!editor.isEditable) return null;

  return (
    <DragHandle
      pluginKey="ContentItemMenu"
      editor={editor}
      onNodeChange={data.handleNodeChange}
      tippyOptions={{
        offset: [-2, 16],
        zIndex: 99,
      }}
    >
      <div className="flex items-center gap-0.5">
        <Toolbar.Button onClick={actions.handleAdd}>
          <Icon name="Plus" />
        </Toolbar.Button>
        <Popover.Root open={menuOpen} onOpenChange={setMenuOpen}>
          <Popover.Trigger asChild>
            <Toolbar.Button>
              <Icon name="GripVertical" />
            </Toolbar.Button>
          </Popover.Trigger>
          <Popover.Portal>
            <Popover.Content side="bottom" align="start" sideOffset={8}>
              <Surface className="p-2 flex flex-col min-w-[16rem]">
                <DropdownButton
                  onClick={() => {
                    actions.resetTextFormatting();
                    // close menu
                    setMenuOpen(false);
                  }}
                >
                  <Icon name="RemoveFormatting" />
                  Clear formatting
                </DropdownButton>
                <DropdownButton
                  onClick={() => {
                    actions.copyNodeToClipboard();
                    // close menu
                    setMenuOpen(false);
                  }}
                >
                  <Icon name="Clipboard" />
                  Copy to clipboard
                </DropdownButton>
                <DropdownButton
                  onClick={() => {
                    actions.duplicateNode();
                    // close menu
                    setMenuOpen(false);
                  }}
                >
                  <Icon name="Copy" />
                  Duplicate
                </DropdownButton>
                <Toolbar.Divider horizontal />
                <DropdownButton
                  onClick={() => {
                    actions.deleteNode();
                    // close menu
                    setMenuOpen(false);
                  }}
                  className="text-red-500 bg-red-500 dark:text-red-500 hover:bg-red-500 dark:hover:text-red-500 dark:hover:bg-red-500 bg-opacity-10 hover:bg-opacity-20 dark:hover:bg-opacity-20"
                >
                  <Icon name="Trash2" />
                  Delete
                </DropdownButton>
              </Surface>
            </Popover.Content>
          </Popover.Portal>
        </Popover.Root>
      </div>
    </DragHandle>
  );
};
