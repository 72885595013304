import { styled } from '@mui/material';
import { BlockEditor, useBlockEditor } from 'src/components/block-editor';
import { parseContent } from 'src/components/block-editor/useBlockEditor';

interface EditorViewProps {
  content: string;
  defaultContent?: string;
}

/**
 * ## EditorView 설명
 * - 에디터 뷰 컴포넌트
 */
function EditorView({ content, defaultContent }: EditorViewProps) {
  const parsedContent = parseContent(content, defaultContent);
  // content editor
  const { editor } = useBlockEditor({
    editable: false,
    content: parsedContent,
  });
  return <StyledBlockEditor editor={editor} />;
}

export default EditorView;

const StyledBlockEditor = styled(BlockEditor)(({ theme }) => ({
  '& .ProseMirror': {
    padding: theme.spacing(1),
  },
}));
