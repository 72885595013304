import { INotification, IOrganizationChart } from 'src/@types/apiResponseTypes';
import { ICodeGroupModel } from 'src/models';

import { TApiResponseData } from '../apiTypes';
import instance from '../axios';

/**
 * loginId 중복 체크
 * @param loginId 중복 체크할 loginId
 * @returns boolean | undefined
 */
export const checkLoginIdDuplicate = async (loginId: string) => {
  const url = `/v1/user/loginId/check?loginId=${loginId}`;
  const response = await instance.get<TApiResponseData<boolean>>(url);

  if (response.data.resultCode === 'S') {
    return response.data.data;
  }
};

/**
 * 비밀번호 초기화
 * @param loginId 중복 체크할 loginId
 */
export const resetPassword = async (loginId: string) => {
  const url = `/v1/tempPassword`;
  const response = await instance.post<
    TApiResponseData<{
      loginResult: 'SUCCESS' | 'FAIL';
      errorMessage: string | null;
    }>
  >(url, {
    loginId,
  });

  if (response.data.resultCode === 'S') {
    return response.data.data;
  }
};

/**
 * 공통코드 조회
 */
export const getCommonCodes = async () => {
  try {
    const url = `/v1/code`;
    const response = await instance.get<TApiResponseData<ICodeGroupModel[]>>(url);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return response.data.data;
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

/**
 * 조직도 데이터 조회
 */
export const getOrganizationData = async () => {
  try {
    const url = `/v1/organization/chart`;
    const response = await instance.get<TApiResponseData<IOrganizationChart>>(url);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return response.data.data;
    }
  } catch (error) {
    console.error(error);
  }
};

export const getDynamicCd = async (dynamicCd?: number) => {
  try {
    const url = `/v1/dynamic/code/item?dynamicCd=${dynamicCd}`;
    const params = {};
    const response = await instance.get(url, params);
    if (response.data.resultCode === 'S' && response.data.data) {
      return response.data.data;
    }
  } catch (e) {
    console.error(e);
  }
};

/**
 * 알림 목록 조회
 */
export const getNotifications = async () => {
  try {
    const url = `/v1/alarm`;
    const response = await instance.get<TApiResponseData<INotification>>(url);

    if (response.data.resultCode === 'S' && response.data.data) {
      return response.data.data;
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 알림 읽음 여부 처리
 */

export const checkNotificationRead = async () => {
  try {
    const url = `/v1/alarm/check`;
    const response = await instance.get(url);

    if (response.data.resultCode === 'S') {
      return response.data.data;
    }
  } catch (error) {
    console.error(error);
  }
};
