import { format, formatDistanceToNow, getTime } from 'date-fns';
import { ko } from 'date-fns/locale';

// ----------------------------------------------------------------------

type InputValue = Date | string | number | null;

export const dateTime_format = 'yyyy-MM-dd HH:mm:ss';

export function fDate(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'yyyy-MM-dd';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'yyyy-MM-dd HH:mm:ss';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date: InputValue) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date: InputValue) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}

export function fDateToNow(date: InputValue) {
  if (!date) return '';

  const d = new Date(date);
  const now = Date.now();
  const diff = (now - d.getTime()) / 1000; // 현재 시간과의 차이(초)
  if (diff < 60 * 1) {
    // 1분 미만일땐 방금 전 표기
    return '방금 전';
  }
  if (diff < 60 * 60 * 24 * 3) {
    // 3일 미만일땐 시간차이 출력(몇시간 전, 몇일 전)
    return formatDistanceToNow(d, { addSuffix: true, locale: ko });
  }

  return format(d, 'PPP EEE p', { locale: ko }); // 날짜 포맷
}
