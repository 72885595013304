import { useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
// @mui
import { Checkbox, FormControlLabel, IconButton, InputAdornment, Link, Stack } from '@mui/material';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/FormProvider';
import Iconify from 'src/components/iconify';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { useStores } from 'src/models';
import { PATH_AUTH } from 'src/routes/paths';
import { LOCAL_STORAGE_KEY } from 'src/static/constants';
import * as Yup from 'yup';

type FormValuesProps = {
  email: string;
  password: string;
};

const LoginSchema = Yup.object().shape({
  email: Yup.string().required('Email is required').email('Email must be a valid email address'),
  password: Yup.string().required('Password is required'),
});

const defaultValues = {
  email: '',
  password: '',
};

export default function LoginForm() {
  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });
  const rootStore = useStores();
  const { authStore } = rootStore;
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // remember me check
  const [rememberMe, setRememberMe] = useLocalStorage(LOCAL_STORAGE_KEY.REMEMBER_ME, false);

  const onSubmit = async (data: FormValuesProps) => {
    // login button loading start
    setIsLoading(true);
    try {
      await authStore.login(data.email, data.password, rememberMe);
    } catch (error) {
      console.error(error);
      methods.reset();
    } finally {
      // login button loading end
      setIsLoading(false);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {/* Email Input */}
        <RHFTextField name="email" label="Email address" onKeyDown={undefined} />
        {/* Password Input */}
        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          onKeyDown={undefined}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        gap={1}
        sx={{ my: 2 }}
      >
        {/* Remember me check */}
        <FormControlLabel
          control={
            <Checkbox checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} />
          }
          label="로그인 상태 유지"
        />
        {/* Forgot Password Link */}
        <Link
          component={RouterLink}
          to={PATH_AUTH.resetPassword}
          variant="body2"
          color="inherit"
          underline="always"
        >
          Forgot password?
        </Link>
      </Stack>
      {/* Login Button */}
      <LoadingButton
        fullWidth
        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        loading={isLoading}
        sx={{
          bgcolor: 'text.primary',
          color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
          '&:hover': {
            bgcolor: 'text.primary',
            color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
          },
        }}
      >
        Login
      </LoadingButton>
    </FormProvider>
  );
}
