import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import { withSetPropAction } from '../extensions/withSetPropAction';

/**
 * [open]: Alert 창 표시 여부
 * [category]: Alert 창 카테고리
 * [title]: Alert 창 제목
 * [content]: Alert 창 내용
 * [hasCancelButton]: 취소 버튼 표시 여부
 * [btnLabel]: 버튼 라벨
 * [onConfirm]: 확인 버튼 클릭 시 실행할 함수
 */
export const AlertStore = types
  .model('AlertStore')
  .props({
    open: types.optional(types.boolean, false),
    category: types.optional(
      types.enumeration('Category', ['secondary', 'error', 'info', 'success', 'warning']),
      'info',
    ),
    title: types.optional(types.string, ''),
    content: types.optional(types.string, ''),
    hasCancelButton: types.optional(types.boolean, false),
    btnLabel: types.optional(types.string, '확인'),
  })
  .volatile(() => ({
    onConfirm: () => {
      // do nothing
    }, // 초기값 설정
  }))
  .actions(withSetPropAction)
  .actions((self) => ({
    setOnConfirm(func: VoidFunction) {
      self.onConfirm = func;
    },
  }))
  .actions((self) => ({
    closeAlert() {
      self.setProp('open', false);
    },
    invokeOnConfirm() {
      if (self.onConfirm) {
        self.onConfirm();
        // 초기화
        self.setOnConfirm(() => {
          // do nothing
        });
      }
    },
  }));

type TAlertStore = Instance<typeof AlertStore>;
type TAlertStoreSnapshot = SnapshotOut<typeof AlertStore>;
export interface IAlertStore extends TAlertStore {}
export type TAlertStoreKeys = keyof TAlertStoreSnapshot & string;
export interface IAlertStoreSnapshot extends TAlertStoreSnapshot {}
