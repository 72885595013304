import { Typography } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';

interface NewChatButtonProps extends ButtonProps {}

function NewChatButton({ ...buttonProps }: NewChatButtonProps) {
  return (
    <Button
      sx={{
        height: '40px',
        border: '2px solid #FFF',
        borderRadius: '20px',
      }}
      component="label"
      variant="contained"
      fullWidth
      {...buttonProps}
    >
      <Typography variant="h6">+ New Chat</Typography>
    </Button>
  );
}

export default NewChatButton;
